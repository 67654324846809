.block--immunology-news-node-page {
	span.file {
		position: absolute;
		bottom:1em;
		img,span {
			display: none;
		}
		a {
			color: $white;
		}
		@include icon("\f054");

	}
	.views-row {
		@include clearfix;
		color: $white;
		padding:0.5em;
		position: relative;
	}
	.field-name-body {
		padding-bottom: 2em;
	}
	.field-name-field-image {
		float: right;
		width:100%;
		img {
			width:100%;
			height:auto;
		}
	}

	//attachment styles
	.view-display-id-attachment_2 {
	  	margin-bottom: $base-spacing;
		margin-top: $base-spacing / 2;
		a {
			color: $dark-blue;
		}
		img {
			display: block;
			height: auto;
		}
		p {
			color: $dark-gray;
		}

		.view-header {
			font-size: modular-scale(5);
			font-weight: $fw--semi-bold;
		}
		.views-row {
			padding:0;
			position: relative;
			margin-bottom: $base-spacing * 2;
			padding-top: $base-spacing / 2;
			border-top: .25em solid $orange;
		}
		fieldset {
			position: relative;
		}
		.views-field, .field {
			display: block;
			color: $dark-gray;
		}

		.field-name-field-image {
			float: left;
			margin-right: $base-spacing;
			margin-bottom: 1em;
		}

		.title {
			text-transform: uppercase;
			color: $black;
		}

		.field-name-body {
			padding-bottom: 1.5em;
		}

		.link {
			position: absolute;
		  	bottom: 0;
		  	left:0;

		  	a:after {
		  		content:"\f054";
					font-family: fontAwesome;
					margin-left: 0.5em;
		  	}
		}
	}
}



//background colours
@each $colour, $hex in $colours {
	.block--immunology-news-node-page .views-row.#{$colour} {
		background-color:  $hex;

	}
}

@include susy-breakpoint($med-mobile) {
	.block--immunology-news-node-page {
		.field-name-field-image {
			width:40%;
		}
		.view-display-id-attachment_2 .field-name-field-image {
			width:30%;
		}
	}
}
@include susy-breakpoint($tablet) {
	.block--immunology-news-node-page {
		.field-name-field-image {
			width:20%;
		}
		.view-display-id-attachment_2 .field-name-field-image {
			width:30%;
		}
	}
}
@include susy-breakpoint($desktop) {
	.one-sidebar .view-display-id-attachment_2 .views-row {
		@include gallery(4.5 of 9 after);
	}
	.two-sidebars .view-display-id-attachment_2 .views-row {
		@include gallery(3 of 6 after);
	}
}