.webform-progressbar {
  display: none;
}

.form-item.webform-component.webform-component-textfield.webform-component--civicrm-1-contact-1-cg9-custom-107 {
  padding: 2em 0 2em 0;
  border-bottom: 5px solid #2ca48b;
  margin-bottom: 2em;
}

.block--direct-debit {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 150px;
}

.path-direct-debit-membership-form #main-content {
  position: relative;
}

.path-node-1054-done #main-content {
    position: relative;
}

.direct-debit-logo {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 200px;
}

.direct-debit-details {
  position: relative;
  margin: 4em 0 4em 0em;
  padding: 0 1em 0 1em;
  a {
    text-decoration: underline;
  }
}

.direct-debit-guarantee {
  background-color: #daf5ef;
  padding: 0 1em 1.5em 1em;
}

.direct-debit-checks-container {
  background-color: #daf5ef;
  font-weight: 400;
  border-top: 5px solid #2ca48b;
  border-bottom: 5px solid #2ca48b;
}

.direct-debit-checks {
 padding: 1em 4em 1em 4em;
}

.direct-debit-checks input[type="checkbox"] {
  margin-left: -1.5em;
}

.multiple-person {

  padding-top: 1.5em;
  padding-bottom: 1.5em;
  font-weight: 600;
}

.direct-debit-next {
  padding-top: 1.5em;
  padding-bottom: 1.5em;

  input.webform-previous.form-submit {
    display: none;
  }
}

.account-holder-confirm {
  padding: 1.5em 0 1.5em 0;
  font-weight: 600;
}

.direct-debit-next input[type="submit"] {
 border-radius: 5px;
 background-color: #2ca48b;
}

.direct-debit-confirm input[type="submit"] {
 border-radius: 5px;
 background-color: #999999;
 margin-bottom:1em;
}

.direct-debit-confirm {

  input {
    margin-right: 1em;
  }

  input:after {
    content:'>'
  }

  input.webform-next.button-primary.form-submit {
    background-color: #2ca48b;
  }

   input.webform-submit.button-primary.form-submit {
    background-color: #2ca48b;
  }
}
.direct-debit-check-success {
  display:none;
  background-color: #9AE570;
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 6px;
}

.direct-debit-check-failed {
  display:none;
  background-color: #FF9494;
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 6px;
}

//Form page 3

.form-item.webform-component.webform-component-markup.webform-component--check-your-details {
  border-bottom: 5px solid #2ca48b;
  margin-bottom: 2em;
}

.form-item.webform-component.webform-component-markup.webform-component--thank-you {
  padding: 2em 0 1em 0;
  border-bottom: 5px solid #2ca48b;
  margin-bottom: 2em;
}

.check-details-title {
  font-weight: 600;
  margin-bottom: 0.8em;
}

.check-details-value {
  color: grey;
  padding-left: 1em;
  margin-bottom: 1em;
  font-weight: 600;
  letter-spacing: 2px;
}

.check-details-container {
  margin-bottom: 3em;
}

p.bank-statement {
    margin-top: 2em;
}

.form-item.webform-component.webform-component-markup.webform-component--thank-you-message {
  margin-bottom: 3em;
}



.direct-debit-complete {
  margin-bottom: 2em;

  h2 {
    padding: 2em 0 1em 0;
    border-bottom: 5px solid #2ca48b;
    margin-bottom: 2em;
  }
}

