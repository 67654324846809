// _view-latest-articles.scss

.block--latest-articles {
  clear: both;

  .block-title {
    text-transform: uppercase;
  }

  .views-row {
    position: relative;
    padding: 1.5em;
    padding-bottom: 4em;
    color: $white;

    a {
      color: white;
    }
  }

  .views-field-title {
    margin-bottom: 1em;
  }

  .views-more-link {
    position: absolute;
    bottom: 1.5em;
    display: block;

    &:after {
      content: "\f054";
      margin-left: 0.5em;
      font-family: "FontAwesome";
    }
  }

  .views-row-1 {
    background-color: $blue;
  }

  .views-row-2 {
    background-color: $pink;
  }

  .views-row-3 {
    background-color: $purple;
  }
}

@include susy-breakpoint($mobile-only) {
  .block--latest-articles .views-row {
    margin-bottom: 1.5em;
  }
}

@include susy-breakpoint($tablet) {
  .block--latest-articles .views-row {
    @include gallery(1 of 3 after (1/16));
  }
}
