// _drupal-overrides.scss

// Tabs
ul.tabs.primary {
  @include clearfix;
  @include clean-list;

  border: 0;
  padding-left: 0 !important;
  margin: 1em 0;

  li {
    display: inline-block;
  }

  li a {
    border: 0;
    font-size: 1em;
    display: block;
    float: left;
    padding: .3em 1em;
    background-color: #4C565C;
    color: $white;
    margin-right: 1em;
    transition: background-color $base-duration $base-timing, color $base-duration $base-timing;

    &.active,
    &:hover {
      background-color: lighten(#4C565C, 50);
      color: #4C565C;
    }
  }
}