// _navigation.scss

.block--navigation {
  font-size: 1.2em;

  .burger-box {
    position: absolute;
    top: calc(50% - 17px);
    right: 1.5rem;
    display: none; // Hide by default

    .burger {
      position: relative;
      top: 50%;

      // Putting this here to satisfy property ordering
      @include burger(45px, 3px, 10px, darken($pastel-blue, 20%), 0, 0.2s);
    }

    &.active .burger {
      @include burger-to-cross;
    }
  }

  ul.menu {
    @include clean-list;

    background-color: $white;

    li {
      display: block;
    }

    li a {
      display: block;
    }
  }
}

@include susy-breakpoint($mobile-only) {

  .block--navigation {

    .burger-box {
      display: block;
    }

    ul.menu {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      display: none; // initial state
      background-color: $white;

      li {
        margin-bottom: .25em;
        font-size: .9em;
      }

      li a {
        padding: .75em;
        background-color: rgba($light-pastel-blue, 0.4);
      }

    } // End ul.menu

  } // End block--navigation

}


@include susy-breakpoint($tablet) {
  .block--navigation {
    position: absolute;
    right: 0;
    bottom: 0.5em;

    .is-sticky & {
      bottom: 0.5em;
    }

    ul.menu {
      @include floated-list;
      text-align: right;
      font-size: 1em;
      font-weight: $fw--light;
      text-transform: lowercase; // Just 'cos
      background-color: transparent;

      li {
        border-left: 1px solid $light-gray;

        &.first {
          border-left: 0;
        }
      }

      a {
        position: relative;
        display: block;
        padding-left: .3em;
        padding-right: .3em;
        color: $dark-gray;
      }

      a:hover {
        color: lighten($dark-gray, 20%);
      }
    }
    ///////Lovely active link background////////
    ul.menu li.active {
      border-left: none;
      & + li {
        border-left: none;
      }
      a {
        color: $white;
        &:hover {
          color:$white;
        }
        &:after {
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height:110px;
          content: "";
          background-color: $green;
          
          ///colours////
          .path-events & { 
            //background-color: $pink;
          }          
          .path-publications & { 
            background-color: $blue;
          }          
          .path-news & { 
            background-color: $green;
          }          
          .path-careers & { 
            background-color: $dark-blue;
          }          
          .path-about-us & { 
            background-color: $green;
          }          
          .path-public-information & { 
            background-color: $green;
          }



          //$blue: #1e97ad;
          // $light-blue: #66ccff;
          // $pastel-blue: #b4c8da;
          // $light-pastel-blue: #e6ecf2;
        }
      }
    }
  }
}


@include susy-breakpoint($desktop) {
  .block--navigation {
    bottom: 1em;
    ul.menu {
      font-size: 1.2em;

      li ul {
        position: absolute;
        display: none;
        font-size: 13px;
      }

      ul li {
        position: relative;
        text-align: left;
        clear: both;
        width: 100%;
      }

      li:hover ul {
        display: block;
      }
      a {
        padding-left: .5em;
        padding-right: .5em;
      }
    }
  }
}
