.view-display-id-previous_immunology_news {
		img{
		width:100%;
		height: auto;
	}
	.views-row {
		@include clearfix;
		position: relative;
		margin-bottom: $base-spacing * 2;
		>div:not(.views-field-title):not(.field-name-body) {
			padding: 0.5em;
			color: white;
			a {
				color: white;
			}
		}
	}
	.views-field-title {
		padding-top: 0.1em;
		padding-bottom: 1em;
		text-transform: uppercase;
		font-size: 1.5em;
		font-weight: 300;
		background-color: #ffffff;
		color: #53575d;
	}
	.views-field-field-image {
		float: right;
		width:100%;
	}
	.field-name-body {
		padding-left: 0.5em;
		padding-right: 0.5em;
		padding-top: 0.5em;
		padding-bottom: $base-spacing * 1.5;
		color: white;
		a {
			color: white;
		}
	}
	.views-field-view-node {
		position: absolute;
		bottom: 0;
		padding: 0.5em;
		a {
			@include icon("\f054");
		}
	}
}
//background
@each $colour, $hex in $colours {
	 .view-display-id-previous_immunology_news .views-row.#{$colour} {
		background-color:  $hex;
		border-top: 0.5em solid $hex;
	}
}
//breakpoints 

@include susy-breakpoint($med-mobile) {
	.view-display-id-previous_immunology_news {
		.views-field-field-image {
			width:30%;
		}
	}
}



@include susy-breakpoint($tablet) {
	.view-display-id-previous_immunology_news {
		.views-field-field-image {
			width:30%;
		}
	}
}
@include susy-breakpoint($desktop) {
	.view-display-id-previous_immunology_news {
		.views-field-field-image {
			width:20%;
		}
	}
}