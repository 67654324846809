// _block--bsi-member.scss

.block--bsi-member {
  @include span(4 of 4);
  .block-title {
    margin-bottom: 3em;
    text-transform: uppercase;
  }

  img {
    max-height: 3.5em;
    width:auto;
    height: auto;
  }

  p {
    margin-top: .5em;
    padding-top: .5em;
    border-top: 1px solid $light-gray;
    font-size: .8em;
  }
  .views-row {
    @include gallery(1 of 2 after);
    margin-bottom: 3em;
  }

}


@include susy-breakpoint($tablet) {
  .block--bsi-member {
@include span(12 of 12);

    .block-content {

      .views-row {
        @include gallery(1 of 6 after);
      }
    }
  }
}
