// _region--pre-header.scss

.region--pre-header {
  border-bottom: $light-gray 1px solid;
  
  // Settings font sizes globally here rather than
  // in the individual blocks.
  .region-inner {
    font-size: .7em;
  }
}


@include susy-breakpoint($tablet) {
  .region--pre-header {

    .region-inner {
      font-size: .5em;
    }
  }
}

@include susy-breakpoint($desktop) {
  .region--pre-header {

    .region-inner {
      font-size: .7em;
    }
  }
}