// _mixins.scss

@mixin clean-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li:before {
    display: none;
  }
}

@mixin inline-list {
  li {
    display: inline-block;
  }
}

@mixin floated-list($direction: left) {
  @include clearfix;

  li {
    float: $direction;
  }

  li:before {
    display: none;
  }
}

@mixin icon($icon) {
  &:after {
    font-family: fontAwesome;
    content: $icon;
    margin-left: 10px;
  }
}
