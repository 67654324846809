// _block--homepage-intro.scss

.block--homepage-intro {
  padding-bottom: 3em;
  font-size: 1.5em;
  font-weight: $fw--light;
  text-align: center;

  p {
    margin-bottom: 0;
  }
}