// _block--search-link.scss

.block--search-link {
  float: right;
  line-height: 4em;

  .block-content {
    border-left: 1px solid $light-gray;
    border-right: 1px solid $light-gray;
    font-size: 1.75em;
  }

  .block-content a {
    display: block;
    padding-left: .75em;
    padding-right: .75em;
    color: $dark-gray;
    transition: color $base-duration $base-timing;

    &:hover {
      color: lighten($dark-gray, 20%);
    }
  }
}