.block--immunology-in-60-days-top-block {
	margin-bottom: $base-spacing * 2;
	img {
		width:65%;
		margin: 0 auto;
		display: block;
	}
}	
@include susy-breakpoint($tablet) {
	.block--immunology-in-60-days-top-block {
		img {
			width:20%;
			float: right;
		}
		.block-content >p:not(:first-child) {
			width:60%;
		}
	}
}