.view-immunologist-details {
	span {
		font-weight: $fw--bold;
	}
	.field-name-field-address {
		p {
			margin:0;
		}
	}
	.field {
		margin-bottom: 1em;
	}
	.field-name-field-image {
		border: 1px solid $dark-gray;
		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}
}