// _image-and-text.scss

.paragraphs-item-image-and-text {
	position: relative;
	margin-bottom: $base-spacing * 2;
	padding-top: $base-spacing / 2;
	border-top: .25em solid $orange;

	a {
		color: $dark-blue;
	}

	img {
		display: block;
		height: auto;
	}

	.fieldset-wrapper {
		position: relative;
	}

	.field-name-field-image {
		float: left;
		margin-right: $base-spacing;
		margin-bottom: 1em;
	}

	.field-name-field-paragraph-title {
		text-transform: uppercase;
		color: $black;
	}

	.field-name-field-paragraph-body {
		padding-bottom: 1.5em;
	}

	.field-name-field-paragraph-link {
		position: absolute;
	  	bottom: 0;

	  	a:after {
	  		content:"\f054";
				font-family: fontAwesome;
				margin-left: 0.5em;
	  	}
	}
}


@include susy-breakpoint($mobile-only) {
	.paragraphs-item-image-and-text {

		.field-name-field-image {
			width: 30%;
		}
	}
}


// Common layout
@include susy-breakpoint($desktop) {
	.one-sidebar .field-type-paragraphs > .field-items > .paragraphs-item-image-and-text {
		@include gallery(4.5 of 9 after);
	}
	.two-sidebars .field-type-paragraphs > .field-items > .paragraphs-item-image-and-text {
		@include gallery(3 of 6 after);
	}
}
