// _page.scss

.node-type-page {

	.paragraphs-items {
		margin-top: $base-spacing;
	}
}
.content-container {
	margin-bottom: $base-spacing;
}
