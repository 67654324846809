// _fonts.scss

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);

// Family
$open-sans: 'Open Sans', Helvetica, Arial, sans-serif;

// Weights
$fw--light: 300;
$fw--normal: 400;
$fw--semi-bold: 600;
$fw--bold: 700;