.view-search {

	.views-row {
		padding-bottom: 0.5em;
		padding-top: 0.5em;
		border-bottom: 1px solid $dark-gray;
	}

	.views-row:last-child {
		border-bottom: none;
		padding-bottom: 0;
	}
}
