// _block--bitesize-categories.scss

.block--bitesize-categories {
  @include clearfix;
  margin-top: $base-spacing * 3; // Extra spacing needed
  margin-bottom: $base-spacing * 3; // Extra spacing needed
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: $lightest-gray;

  .view-content a {
    text-align: center;
    color: $black;

    .image img {
      height:auto;
      transition: opacity $base-timing $base-duration;
    }

    &:hover {
      color: $dark-gray;

      img {
        opacity: .8;
      }
    }
  }
  .views-row { 
      @include clearfix;
  }

  span {
    display: block;
    font-size: .9em;
    font-weight: $fw--semi-bold;
  }
}

@include susy-breakpoint($small-mobile) { 
  .block--bitesize-categories { 
    .views-row {
      width:50%;
      margin:0 auto;
      margin-bottom: 2em;
    }
  }
}

@include susy-breakpoint($med-mobile) {
  .block--bitesize-categories {

    .views-row {
      @include gallery(1 of 2);
      margin-bottom: 2em;
    }

    // Quick fix here for when there are five
    // of these links in the block
    .view-content a.last {
      display: block;
      float: none;
      clear: both;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
    }
  }
}

@include susy-breakpoint($tablet) {
  .block--bitesize-categories {
    .views-row {
      @include span(1 of 5 (1/1.1)); // Matching the design here
      margin-bottom: 1em;
    }
    .view-content >div:nth-child(5n+6) { 
      clear: both;
    }
  }
}

// *** In case they want to go for a 10 icon view instead of 5 icons and 5 thumbnails with text. 