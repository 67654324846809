@charset "UTF-8";
/* stylelint-disable */
/* stylelint-enable */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);
button, input[type="button"], input[type="reset"], input[type="submit"], .button {
  appearance: none;
  background-color: #1e97ad;
  border: 0;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  button:hover, button:focus, input[type="button"]:hover, input[type="button"]:focus, input[type="reset"]:hover, input[type="reset"]:focus, input[type="submit"]:hover, input[type="submit"]:focus, .button:hover, .button:focus {
    background-color: #18798a;
    color: #fff; }
  button:disabled, input[type="button"]:disabled, input[type="reset"]:disabled, input[type="submit"]:disabled, .button:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    button:disabled:hover, input[type="button"]:disabled:hover, input[type="reset"]:disabled:hover, input[type="submit"]:disabled:hover, .button:disabled:hover {
      background-color: #1e97ad; }

.button.white {
  background-color: #ffffff; }
  .button.white:hover, .button.white:focus {
    background-color: #cccccc; }

.button.black {
  background-color: #000000; }
  .button.black:hover, .button.black:focus {
    background-color: black; }

.button.dark-blue {
  background-color: #084983; }
  .button.dark-blue:hover, .button.dark-blue:focus {
    background-color: #063a69; }

.button.blue {
  background-color: #1e97ad; }
  .button.blue:hover, .button.blue:focus {
    background-color: #18798a; }

.button.light-blue {
  background-color: #66ccff; }
  .button.light-blue:hover, .button.light-blue:focus {
    background-color: #52a3cc; }

.button.light-purple {
  background-color: #5F65DF; }
  .button.light-purple:hover, .button.light-purple:focus {
    background-color: #4c51b2; }

.button.pastel-blue {
  background-color: #b4c8da; }
  .button.pastel-blue:hover, .button.pastel-blue:focus {
    background-color: #90a0ae; }

.button.light-pastel-blue {
  background-color: #e6ecf2; }
  .button.light-pastel-blue:hover, .button.light-pastel-blue:focus {
    background-color: #b8bdc2; }

.button.darkest-gray {
  background-color: #333333; }
  .button.darkest-gray:hover, .button.darkest-gray:focus {
    background-color: #292929; }

.button.dark-gray {
  background-color: #53575d; }
  .button.dark-gray:hover, .button.dark-gray:focus {
    background-color: #42464a; }

.button.medium-gray {
  background-color: #666666; }
  .button.medium-gray:hover, .button.medium-gray:focus {
    background-color: #525252; }

.button.light-gray {
  background-color: #cccccc; }
  .button.light-gray:hover, .button.light-gray:focus {
    background-color: #a3a3a3; }

.button.off-white {
  background-color: #f2f2f2; }
  .button.off-white:hover, .button.off-white:focus {
    background-color: #c2c2c2; }

.button.pink {
  background-color: #ac1675; }
  .button.pink:hover, .button.pink:focus {
    background-color: #8a125e; }

.button.purple {
  background-color: #630685; }
  .button.purple:hover, .button.purple:focus {
    background-color: #4f056a; }

.button.orange {
  background-color: #f17f00; }
  .button.orange:hover, .button.orange:focus {
    background-color: #c16600; }

.button.lightest-gray {
  background-color: #f2f2f2; }
  .button.lightest-gray:hover, .button.lightest-gray:focus {
    background-color: #c2c2c2; }

.button.green {
  background-color: #0a9178; }
  .button.green:hover, .button.green:focus {
    background-color: #087460; }

.button.chevron-right:after {
  font-family: fontAwesome;
  content: "";
  margin-left: 10px; }

button.white {
  background-color: #ffffff; }
  button.white:hover, button.white:focus {
    background-color: #cccccc; }

button.black {
  background-color: #000000; }
  button.black:hover, button.black:focus {
    background-color: black; }

button.dark-blue {
  background-color: #084983; }
  button.dark-blue:hover, button.dark-blue:focus {
    background-color: #063a69; }

button.blue {
  background-color: #1e97ad; }
  button.blue:hover, button.blue:focus {
    background-color: #18798a; }

button.light-blue {
  background-color: #66ccff; }
  button.light-blue:hover, button.light-blue:focus {
    background-color: #52a3cc; }

button.light-purple {
  background-color: #5F65DF; }
  button.light-purple:hover, button.light-purple:focus {
    background-color: #4c51b2; }

button.pastel-blue {
  background-color: #b4c8da; }
  button.pastel-blue:hover, button.pastel-blue:focus {
    background-color: #90a0ae; }

button.light-pastel-blue {
  background-color: #e6ecf2; }
  button.light-pastel-blue:hover, button.light-pastel-blue:focus {
    background-color: #b8bdc2; }

button.darkest-gray {
  background-color: #333333; }
  button.darkest-gray:hover, button.darkest-gray:focus {
    background-color: #292929; }

button.dark-gray {
  background-color: #53575d; }
  button.dark-gray:hover, button.dark-gray:focus {
    background-color: #42464a; }

button.medium-gray {
  background-color: #666666; }
  button.medium-gray:hover, button.medium-gray:focus {
    background-color: #525252; }

button.light-gray {
  background-color: #cccccc; }
  button.light-gray:hover, button.light-gray:focus {
    background-color: #a3a3a3; }

button.off-white {
  background-color: #f2f2f2; }
  button.off-white:hover, button.off-white:focus {
    background-color: #c2c2c2; }

button.pink {
  background-color: #ac1675; }
  button.pink:hover, button.pink:focus {
    background-color: #8a125e; }

button.purple {
  background-color: #630685; }
  button.purple:hover, button.purple:focus {
    background-color: #4f056a; }

button.orange {
  background-color: #f17f00; }
  button.orange:hover, button.orange:focus {
    background-color: #c16600; }

button.lightest-gray {
  background-color: #f2f2f2; }
  button.lightest-gray:hover, button.lightest-gray:focus {
    background-color: #c2c2c2; }

button.green {
  background-color: #0a9178; }
  button.green:hover, button.green:focus {
    background-color: #087460; }

button.chevron-right:after {
  font-family: fontAwesome;
  content: "";
  margin-left: 10px; }

fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

legend {
  font-weight: 600;
  margin-bottom: 0.375em;
  padding: 0; }

label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.375em; }

input,
select,
textarea {
  display: block;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1em; }

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="time"], input[type="url"], input[type="week"], input:not([type]), textarea,
select[multiple] {
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 150ms ease;
  width: 100%; }
  input[type="color"]:hover, input[type="date"]:hover, input[type="datetime"]:hover, input[type="datetime-local"]:hover, input[type="email"]:hover, input[type="month"]:hover, input[type="number"]:hover, input[type="password"]:hover, input[type="search"]:hover, input[type="tel"]:hover, input[type="text"]:hover, input[type="time"]:hover, input[type="url"]:hover, input[type="week"]:hover, input:not([type]):hover, textarea:hover,
  select[multiple]:hover {
    border-color: #a3a3a3; }
  input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="time"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus,
  select[multiple]:focus {
    border-color: #1e97ad;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(26, 132, 151, 0.7);
    outline: none; }
  input[type="color"]:disabled, input[type="date"]:disabled, input[type="datetime"]:disabled, input[type="datetime-local"]:disabled, input[type="email"]:disabled, input[type="month"]:disabled, input[type="number"]:disabled, input[type="password"]:disabled, input[type="search"]:disabled, input[type="tel"]:disabled, input[type="text"]:disabled, input[type="time"]:disabled, input[type="url"]:disabled, input[type="week"]:disabled, input:not([type]):disabled, textarea:disabled,
  select[multiple]:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    input[type="color"]:disabled:hover, input[type="date"]:disabled:hover, input[type="datetime"]:disabled:hover, input[type="datetime-local"]:disabled:hover, input[type="email"]:disabled:hover, input[type="month"]:disabled:hover, input[type="number"]:disabled:hover, input[type="password"]:disabled:hover, input[type="search"]:disabled:hover, input[type="tel"]:disabled:hover, input[type="text"]:disabled:hover, input[type="time"]:disabled:hover, input[type="url"]:disabled:hover, input[type="week"]:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover,
    select[multiple]:disabled:hover {
      border: 1px solid #cccccc; }
  input[type="color"]::placeholder, input[type="date"]::placeholder, input[type="datetime"]::placeholder, input[type="datetime-local"]::placeholder, input[type="email"]::placeholder, input[type="month"]::placeholder, input[type="number"]::placeholder, input[type="password"]::placeholder, input[type="search"]::placeholder, input[type="tel"]::placeholder, input[type="text"]::placeholder, input[type="time"]::placeholder, input[type="url"]::placeholder, input[type="week"]::placeholder, input:not([type])::placeholder, textarea::placeholder,
  select[multiple]::placeholder {
    color: #666666; }

textarea {
  resize: vertical; }

[type="search"] {
  appearance: none; }

[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

select {
  appearance: none;
  margin-bottom: 0.75em;
  max-width: 100%;
  width: auto;
  padding: .5em;
  padding-right: 2.8em;
  border: 1px solid #cccccc;
  border-radius: 0;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  background-image: url("../images/icons/select-arrow.png");
  background-position: right 0.5em center;
  background-repeat: no-repeat;
  background-size: 1.5em;
  transition: border-color 150ms ease; }
  select:hover {
    border-color: #a3a3a3; }
  select:focus {
    border-color: #1e97ad;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(26, 132, 151, 0.7);
    outline: none; }
  select:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    select:disabled:hover {
      border: 1px solid #cccccc; }

html.js fieldset.collapsed {
  height: auto; }

.webform-component-fieldset .fieldset-title {
  padding-right: 1.5em;
  background-image: url("../images/icons/chevron-right.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 1em; }

html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container {
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto; }
  .container:after {
    content: " ";
    display: block;
    clear: both; }

.clearfix::after {
  clear: both;
  content: "";
  display: table; }

@media (min-width: 0) and (max-width: 759px) {
  [class^="span-"] {
    width: 91.6666666667%;
    float: left;
    margin-left: 4.1666666667%;
    margin-right: 4.1666666667%; }
  .m-span-1 {
    width: 16.6666666667%;
    float: left;
    margin-left: 4.1666666667%;
    margin-right: 4.1666666667%; }
  .m-span-2 {
    width: 41.6666666667%;
    float: left;
    margin-left: 4.1666666667%;
    margin-right: 4.1666666667%; }
  .m-span-3 {
    width: 66.6666666667%;
    float: left;
    margin-left: 4.1666666667%;
    margin-right: 4.1666666667%; }
  .m-span-4 {
    width: 91.6666666667%;
    float: left;
    margin-left: 4.1666666667%;
    margin-right: 4.1666666667%; } }

@media (min-width: 760px) {
  .d-span-1 {
    width: 5.5555555556%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-2 {
    width: 13.8888888889%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-3 {
    width: 22.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-4 {
    width: 30.5555555556%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-5 {
    width: 38.8888888889%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-6 {
    width: 47.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-7 {
    width: 55.5555555556%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-8 {
    width: 63.8888888889%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-9 {
    width: 72.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-10 {
    width: 80.5555555556%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-11 {
    width: 88.8888888889%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-12 {
    width: 97.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-1 {
    width: 5.5555555556%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-2 {
    width: 13.8888888889%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-3 {
    width: 22.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-4 {
    width: 30.5555555556%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-5 {
    width: 38.8888888889%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-6 {
    width: 47.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-7 {
    width: 55.5555555556%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-8 {
    width: 63.8888888889%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-9 {
    width: 72.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-10 {
    width: 80.5555555556%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-11 {
    width: 88.8888888889%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .span-12 {
    width: 97.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .d-span-n-1 {
    width: 5.7142857143%;
    float: left;
    margin-right: 2.8571428571%; }
  .d-span-n-2 {
    width: 14.2857142857%;
    float: left;
    margin-right: 2.8571428571%; }
  .d-span-n-3 {
    width: 22.8571428571%;
    float: left;
    margin-right: 2.8571428571%; }
  .d-span-n-4 {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%; }
  .d-span-n-5 {
    width: 40%;
    float: left;
    margin-right: 2.8571428571%; }
  .d-span-n-6 {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%; }
  .d-span-n-7 {
    width: 57.1428571429%;
    float: left;
    margin-right: 2.8571428571%; }
  .d-span-n-8 {
    width: 65.7142857143%;
    float: left;
    margin-right: 2.8571428571%; }
  .d-span-n-9 {
    width: 74.2857142857%;
    float: left;
    margin-right: 2.8571428571%; }
  .d-span-n-10 {
    width: 82.8571428571%;
    float: left;
    margin-right: 2.8571428571%; }
  .d-span-n-11 {
    width: 91.4285714286%;
    float: left;
    margin-right: 2.8571428571%; }
  .d-span-n-12 {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .n-span-1 {
    width: 5.7142857143%;
    float: left;
    margin-right: 2.8571428571%; }
  .n-span-2 {
    width: 14.2857142857%;
    float: left;
    margin-right: 2.8571428571%; }
  .n-span-3 {
    width: 22.8571428571%;
    float: left;
    margin-right: 2.8571428571%; }
  .n-span-4 {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%; }
  .n-span-5 {
    width: 40%;
    float: left;
    margin-right: 2.8571428571%; }
  .n-span-6 {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%; }
  .n-span-7 {
    width: 57.1428571429%;
    float: left;
    margin-right: 2.8571428571%; }
  .n-span-8 {
    width: 65.7142857143%;
    float: left;
    margin-right: 2.8571428571%; }
  .n-span-9 {
    width: 74.2857142857%;
    float: left;
    margin-right: 2.8571428571%; }
  .n-span-10 {
    width: 82.8571428571%;
    float: left;
    margin-right: 2.8571428571%; }
  .n-span-11 {
    width: 91.4285714286%;
    float: left;
    margin-right: 2.8571428571%; }
  .n-span-12 {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .span-last {
    margin-right: 0; }
  .span-clear {
    clear: left; } }

@media (min-width: 0) and (max-width: 759px) {
  .content-container,
  .region--sidebar-first,
  .region--sidebar-second {
    width: 91.6666666667%;
    float: left;
    margin-left: 4.1666666667%;
    margin-right: 4.1666666667%; } }

@media (min-width: 760px) {
  .content-container {
    width: 97.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .region--sidebar-first,
  .region--sidebar-second {
    width: 20.1388888889%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .one-sidebar .content-container {
    width: 72.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .one-sidebar .region--sidebar-second {
    margin-left: 3.4722222222%; } }

.clean-list {
  list-style-type: none;
  margin: 0;
  padding: 0; }
  .clean-list li:before {
    display: none; }

.double-spaced-list li {
  margin-bottom: 0.75em; }

figure {
  margin: 0; }

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto; }

@media (min-width: 760px) {
  img.img--left {
    float: left;
    max-width: 500px;
    margin-right: 1em;
    margin-bottom: 1em; }
  img.img--right {
    float: right;
    max-width: 500px;
    margin-left: 1em;
    margin-bottom: 1em; } }

.intro-image {
  margin-bottom: 1em; }

.content p img {
  margin-bottom: 1em;
  margin-top: 1em; }

.content p img[style*="float:left"] {
  margin-right: 1em;
  margin-bottom: 1em;
  margin-top: 1em; }

.content p img[style*="float:right"] {
  margin-left: 1em;
  margin-bottom: 1em;
  margin-top: 1em; }

.content figure[style*="float:left"] {
  margin-right: 1em;
  margin-bottom: 1em;
  margin-top: 1em; }

.content figure[style*="float:right"] {
  margin-left: 1em;
  margin-bottom: 1em;
  margin-top: 1em; }

.content figure[style*="display:inline-block"] {
  margin-bottom: 1em;
  margin-top: 1em; }
  .content figure[style*="display:inline-block"] figcaption {
    display: inherit;
    margin-top: 0; }

figcaption {
  display: table-caption;
  caption-side: bottom;
  text-align: center;
  font-size: small;
  font-style: italic;
  margin-top: -2em; }

figure {
  display: table; }

table {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: auto;
  width: 100%; }

th {
  border-bottom: 1px solid #999999;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

tr,
td,
th {
  vertical-align: middle;
  padding: 0.5em;
  border: 1px solid #cccccc; }

td {
  border-bottom: 1px solid #cccccc;
  vertical-align: top; }

body {
  color: #53575d;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1.5; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.125em;
  font-weight: 600;
  line-height: 1.2;
  margin: 0 0 0.75em; }

h1 {
  font-size: 1.8020324707em; }

h2 {
  font-size: 1.423828125em;
  padding-top: 1.5em; }
  h2.block-title {
    font-weight: 300; }
  .views-row h2 {
    padding-top: 0; }

h4 {
  font-size: 1em; }

p {
  margin: 0 0 0.75em; }

a {
  color: #1e97ad;
  text-decoration: none;
  transition: color 150ms ease; }
  a:active, a:focus, a:hover {
    color: #177182; }

hr {
  border-bottom: 1px solid #cccccc;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

blockquote {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 1em;
  padding-top: 3em;
  padding-left: 5em; }
  blockquote:before, blockquote:after {
    display: block;
    font-family: "FontAwesome";
    font-size: 3em; }
  blockquote:before {
    content: "\f10d";
    position: absolute;
    top: 0;
    left: 0; }
  blockquote p:first-of-type:after {
    content: "\f10e";
    font-family: "FontAwesome";
    float: right;
    font-size: 3em;
    margin-top: 0.2em; }

.drupal-messages {
  margin-top: 1em;
  margin-bottom: 2em; }

.messages {
  padding: 1em;
  border-left: .5em solid; }
  .messages.status {
    background-color: rgba(141, 198, 63, 0.2);
    border-color: #72a230;
    color: #567b24; }
  .messages.warning {
    background-color: rgba(252, 245, 0, 0.2);
    border-color: #c9c300;
    color: #969200; }
  .messages.error {
    background-color: rgba(255, 0, 0, 0.2);
    border-color: #cc0000;
    color: #990000; }

ul.tabs.primary {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 0;
  padding-left: 0 !important;
  margin: 1em 0; }
  ul.tabs.primary::after {
    clear: both;
    content: "";
    display: table; }
  ul.tabs.primary li:before {
    display: none; }
  ul.tabs.primary li {
    display: inline-block; }
  ul.tabs.primary li a {
    border: 0;
    font-size: 1em;
    display: block;
    float: left;
    padding: .3em 1em;
    background-color: #4C565C;
    color: #ffffff;
    margin-right: 1em;
    transition: background-color 150ms ease, color 150ms ease; }
    ul.tabs.primary li a.active, ul.tabs.primary li a:hover {
      background-color: #cfd5d8;
      color: #4C565C; }

.main-content {
  padding-top: 3em;
  padding-bottom: 3em; }

.front .main-content {
  padding-bottom: 0; }

body.hide-page-title h1.main-page-title {
  display: none; }

header {
  position: relative;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 50;
  transition: all 150ms ease; }
  header .container {
    position: relative; }
  header .header--logo img {
    height: auto;
    transition: height 150ms ease; }
  .is-sticky header {
    padding-top: .5em;
    padding-bottom: .5em; }
    .is-sticky header .header--logo img {
      height: 3em; }

footer {
  background-color: #333333; }

.node::after {
  clear: both;
  content: "";
  display: table; }

.item-list-pager {
  width: 100%;
  text-align: center; }
  .item-list-pager ul {
    padding-left: 0;
    display: inline-block; }
  .item-list-pager li {
    float: left;
    display: block;
    margin: 0.8em;
    color: #0a9178; }
    .item-list-pager li a {
      color: #000000; }
  .item-list-pager .next a,
  .item-list-pager .last a,
  .item-list-pager .previous a,
  .item-list-pager .first a {
    margin: 0.3em;
    padding: 0.9em;
    padding-top: 0.3em;
    padding-bottom: 0.5em;
    background-color: #0a9178;
    color: #ffffff; }

@media (min-width: 0) and (max-width: 449px) {
  .item-list-pager li {
    display: none; }
  .item-list-pager li.previous,
  .item-list-pager li.next,
  .item-list-pager li.current {
    display: block; }
    .item-list-pager li.previous a,
    .item-list-pager li.next a,
    .item-list-pager li.current a {
      margin: 0.6em;
      padding: 1.8em;
      padding-top: 0.6em;
      padding-bottom: 1em; } }

.block--cta-block .view-header p {
  margin-bottom: 0; }

.block--navigation {
  font-size: 1.2em; }
  .block--navigation .burger-box {
    position: absolute;
    top: calc(50% - 17px);
    right: 1.5rem;
    display: none; }
    .block--navigation .burger-box .burger {
      position: relative;
      top: 50%;
      position: relative;
      margin-top: 13px;
      margin-bottom: 13px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      .block--navigation .burger-box .burger, .block--navigation .burger-box .burger::before, .block--navigation .burger-box .burger::after {
        display: block;
        width: 45px;
        height: 3px;
        background-color: #7096b8;
        -webkit-transition-property: background-color, -webkit-transform;
        -moz-transition-property: background-color, -moz-transform;
        -o-transition-property: background-color, -o-transform;
        transition-property: background-color, transform;
        -webkit-transition-duration: 0.2s;
        -moz-transition-duration: 0.2s;
        -o-transition-duration: 0.2s;
        transition-duration: 0.2s; }
      .block--navigation .burger-box .burger::before, .block--navigation .burger-box .burger::after {
        position: absolute;
        content: ""; }
      .block--navigation .burger-box .burger::before {
        top: -13px; }
      .block--navigation .burger-box .burger::after {
        top: 13px; }
    .block--navigation .burger-box.active .burger {
      background-color: transparent; }
    .block--navigation .burger-box.active .burger::before {
      -webkit-transform: translateY(13px) rotate(45deg);
      -moz-transform: translateY(13px) rotate(45deg);
      -ms-transform: translateY(13px) rotate(45deg);
      -o-transform: translateY(13px) rotate(45deg);
      transform: translateY(13px) rotate(45deg); }
    .block--navigation .burger-box.active .burger::after {
      -webkit-transform: translateY(-13px) rotate(-45deg);
      -moz-transform: translateY(-13px) rotate(-45deg);
      -ms-transform: translateY(-13px) rotate(-45deg);
      -o-transform: translateY(-13px) rotate(-45deg);
      transform: translateY(-13px) rotate(-45deg); }
  .block--navigation ul.menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    background-color: #ffffff; }
    .block--navigation ul.menu li:before {
      display: none; }
    .block--navigation ul.menu li {
      display: block; }
    .block--navigation ul.menu li a {
      display: block; }

@media (min-width: 0) and (max-width: 759px) {
  .block--navigation .burger-box {
    display: block; }
  .block--navigation ul.menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    display: none;
    background-color: #ffffff; }
    .block--navigation ul.menu li {
      margin-bottom: .25em;
      font-size: .9em; }
    .block--navigation ul.menu li a {
      padding: .75em;
      background-color: rgba(230, 236, 242, 0.4); } }

@media (min-width: 760px) {
  .block--navigation {
    position: absolute;
    right: 0;
    bottom: 0.5em; }
    .is-sticky .block--navigation {
      bottom: 0.5em; }
    .block--navigation ul.menu {
      text-align: right;
      font-size: 1em;
      font-weight: 300;
      text-transform: lowercase;
      background-color: transparent; }
      .block--navigation ul.menu::after {
        clear: both;
        content: "";
        display: table; }
      .block--navigation ul.menu li {
        float: left; }
      .block--navigation ul.menu li:before {
        display: none; }
      .block--navigation ul.menu li {
        border-left: 1px solid #cccccc; }
        .block--navigation ul.menu li.first {
          border-left: 0; }
      .block--navigation ul.menu a {
        position: relative;
        display: block;
        padding-left: .3em;
        padding-right: .3em;
        color: #53575d; }
      .block--navigation ul.menu a:hover {
        color: #848a92; }
    .block--navigation ul.menu li.active {
      border-left: none; }
      .block--navigation ul.menu li.active + li {
        border-left: none; }
      .block--navigation ul.menu li.active a {
        color: #ffffff; }
        .block--navigation ul.menu li.active a:hover {
          color: #ffffff; }
        .block--navigation ul.menu li.active a:after {
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          width: 100%;
          height: 110px;
          content: "";
          background-color: #0a9178; }
          .path-publications .block--navigation ul.menu li.active a:after {
            background-color: #1e97ad; }
          .path-news .block--navigation ul.menu li.active a:after {
            background-color: #0a9178; }
          .path-careers .block--navigation ul.menu li.active a:after {
            background-color: #084983; }
          .path-about-us .block--navigation ul.menu li.active a:after {
            background-color: #0a9178; }
          .path-public-information .block--navigation ul.menu li.active a:after {
            background-color: #0a9178; } }

@media (min-width: 980px) {
  .block--navigation {
    bottom: 1em; }
    .block--navigation ul.menu {
      font-size: 1.2em; }
      .block--navigation ul.menu li ul {
        position: absolute;
        display: none;
        font-size: 13px; }
      .block--navigation ul.menu ul li {
        position: relative;
        text-align: left;
        clear: both;
        width: 100%; }
      .block--navigation ul.menu li:hover ul {
        display: block; }
      .block--navigation ul.menu a {
        padding-left: .5em;
        padding-right: .5em; } }

.block .block-title {
  padding-top: 0; }

.block--pre-navigation {
  float: left; }
  .block--pre-navigation .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 4em; }
    .block--pre-navigation .menu li:before {
      display: none; }
    .block--pre-navigation .menu::after {
      clear: both;
      content: "";
      display: table; }
    .block--pre-navigation .menu li {
      float: left; }
    .block--pre-navigation .menu li:before {
      display: none; }
    .block--pre-navigation .menu li span {
      display: none; }
    .block--pre-navigation .menu a {
      display: inline-block;
      padding-left: 1em;
      padding-right: 1em;
      color: #333333; }
      .block--pre-navigation .menu a:hover {
        color: gray; }
      .block--pre-navigation .menu a.active-trail {
        position: relative;
        background-color: #a1f9e8; }
        .block--pre-navigation .menu a.active-trail:after {
          position: absolute;
          bottom: 0;
          left: calc(50% - 6px);
          content: "";
          height: 0;
          width: 0;
          border-bottom: 6px solid #53575d;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent; }
    .block--pre-navigation .menu li {
      vertical-align: top; }
    .block--pre-navigation .menu .first {
      border-left: 1px solid #cccccc;
      border-right: 1px solid #cccccc;
      font-size: 1.75em; }
    .block--pre-navigation .menu .first a {
      padding-left: .75em;
      padding-right: .75em;
      background-color: #e6ecf2;
      transition: background-color ease 150ms color 150ms ease; }
      .block--pre-navigation .menu .first a:hover {
        background-color: #b4c8da;
        color: #ffffff; }

@media (min-width: 0) and (max-width: 759px) {
  .block--pre-navigation .menu li:not(.first) {
    display: none; } }

.block--post-navigation {
  display: block; }
  .block--post-navigation ul.menu {
    position: relative; }
  .block--post-navigation ul.menu li.first {
    margin-top: 1rem; }

@media (min-width: 760px) {
  .block--post-navigation {
    display: none; } }

.block--user--logged-out {
  float: right; }
  .block--user--logged-out .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    line-height: 4em;
    font-weight: 600;
    text-transform: uppercase; }
    .block--user--logged-out .menu li:before {
      display: none; }
    .block--user--logged-out .menu::after {
      clear: both;
      content: "";
      display: table; }
    .block--user--logged-out .menu li {
      float: left; }
    .block--user--logged-out .menu li:before {
      display: none; }
    .block--user--logged-out .menu a {
      display: inline-block;
      width: 100%;
      padding-left: 1em;
      padding-right: 1em;
      text-align: center;
      color: #333333; }
      .block--user--logged-out .menu a:hover {
        color: gray; }
    .block--user--logged-out .menu li {
      width: 8em;
      vertical-align: top; }
      .block--user--logged-out .menu li a {
        background-color: #b4c8da; }
      .block--user--logged-out .menu li.first {
        border-right: 1px solid #b3b3b3; }
      .block--user--logged-out .menu li.first a {
        background-color: #e6ecf2; }

.block--user--logged-in {
  float: right; }
  .block--user--logged-in .menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    line-height: 4em;
    font-weight: 600;
    text-transform: uppercase; }
    .block--user--logged-in .menu li:before {
      display: none; }
    .block--user--logged-in .menu::after {
      clear: both;
      content: "";
      display: table; }
    .block--user--logged-in .menu li {
      float: left; }
    .block--user--logged-in .menu li:before {
      display: none; }
    .block--user--logged-in .menu a {
      display: inline-block;
      width: 100%;
      padding-left: 1em;
      padding-right: 1em;
      text-align: center;
      color: #333333; }
      .block--user--logged-in .menu a:hover {
        color: gray; }
    .block--user--logged-in .menu li {
      width: 8em;
      vertical-align: top; }
      .block--user--logged-in .menu li a {
        background-color: #b4c8da; }
      .block--user--logged-in .menu li.first {
        border-right: 1px solid #b3b3b3;
        width: 10em; }
      .block--user--logged-in .menu li.first a {
        background-color: #e6ecf2; }

.block--search-link {
  float: right;
  line-height: 4em; }
  .block--search-link .block-content {
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    font-size: 1.75em; }
  .block--search-link .block-content a {
    display: block;
    padding-left: .75em;
    padding-right: .75em;
    color: #53575d;
    transition: color 150ms ease; }
    .block--search-link .block-content a:hover {
      color: #848a92; }

.block--hero-banner {
  position: relative; }
  .block--hero-banner::after {
    clear: both;
    content: "";
    display: table; }
  .block--hero-banner img {
    display: block; }
  .block--hero-banner ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0; }
  .block--hero-banner .detail {
    font-size: 1.2em;
    color: #ffffff;
    text-align: center; }
    .block--hero-banner .detail p {
      padding: .75em;
      background-color: #000000;
      margin-bottom: 0; }
  .block--hero-banner .flex-direction-nav {
    display: none; }

@media (min-width: 760px) {
  .block--hero-banner {
    position: relative; }
    .block--hero-banner .container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0; }
    .block--hero-banner .detail {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: table;
      width: 15em;
      height: 100%; }
      .block--hero-banner .detail > p {
        background-color: rgba(0, 0, 0, 0.8); }
    .block--hero-banner .flex-direction-nav {
      display: block;
      background-color: red; }
      .block--hero-banner .flex-direction-nav li {
        position: absolute;
        z-index: 100;
        width: 30px;
        height: 30px;
        background-size: cover;
        top: 50%;
        margin-top: -15px; }
        .block--hero-banner .flex-direction-nav li a {
          display: block;
          text-indent: -100px;
          overflow: hidden; }
      .block--hero-banner .flex-direction-nav .flex-nav-next {
        right: 100px;
        background-image: url("../images/icons/banner_controlls/chevron-right.svg"); }
      .block--hero-banner .flex-direction-nav .flex-nav-prev {
        left: 100px;
        background-image: url("../images/icons/banner_controlls/chevron-left.svg"); }
    .block--hero-banner p {
      display: table-cell;
      vertical-align: middle;
      margin-bottom: 0;
      font-size: 1em; }
    .block--hero-banner strong {
      display: block;
      font-size: 6em;
      font-weight: 400;
      line-height: 1em; }
    .block--hero-banner span {
      display: block; } }

@media (min-width: 980px) {
  .block--hero-banner p {
    font-size: 1.3em; } }

.block--homepage-intro {
  padding-bottom: 3em;
  font-size: 1.5em;
  font-weight: 300;
  text-align: center; }
  .block--homepage-intro p {
    margin-bottom: 0; }

.block--cta-block {
  margin-bottom: 3em;
  padding-top: .5em;
  border-top: 0.5em solid #000000; }
  .block--cta-block .block-title {
    margin-bottom: 2em;
    color: #53575d;
    text-transform: uppercase;
    font-weight: 300; }
  .block--cta-block .block-content {
    padding: .75em; }
    .block--cta-block .block-content::after {
      clear: both;
      content: "";
      display: table; }
    .block--cta-block .block-content a:after {
      margin-left: 0.5em;
      content: "\f054";
      font-family: fontAwesome; }
    .block--cta-block .block-content ul {
      list-style-type: none;
      margin: 0;
      padding: 0; }
    .block--cta-block .block-content li {
      margin-bottom: .75em; }
  .block--cta-block.white:not(.ligh-backgorund) {
    border-top-color: #ffffff; }
    .block--cta-block.white:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #ffffff; }
      .block--cta-block.white:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.white.light-background .block-content {
    color: #000000;
    background-color: white; }
    .block--cta-block.white.light-background .block-content a {
      color: #000000; }
  .block--cta-block.black:not(.ligh-backgorund) {
    border-top-color: #000000; }
    .block--cta-block.black:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #000000; }
      .block--cta-block.black:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.black.light-background .block-content {
    color: #000000;
    background-color: gray; }
    .block--cta-block.black.light-background .block-content a {
      color: #000000; }
  .block--cta-block.dark-blue:not(.ligh-backgorund) {
    border-top-color: #084983; }
    .block--cta-block.dark-blue:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #084983; }
      .block--cta-block.dark-blue:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.dark-blue.light-background .block-content {
    color: #000000;
    background-color: #92c8f8; }
    .block--cta-block.dark-blue.light-background .block-content a {
      color: #000000; }
  .block--cta-block.blue:not(.ligh-backgorund) {
    border-top-color: #1e97ad; }
    .block--cta-block.blue:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #1e97ad; }
      .block--cta-block.blue:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.blue.light-background .block-content {
    color: #000000;
    background-color: #d3f2f7; }
    .block--cta-block.blue.light-background .block-content a {
      color: #000000; }
  .block--cta-block.light-blue:not(.ligh-backgorund) {
    border-top-color: #66ccff; }
    .block--cta-block.light-blue:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #66ccff; }
      .block--cta-block.light-blue:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.light-blue.light-background .block-content {
    color: #000000;
    background-color: white; }
    .block--cta-block.light-blue.light-background .block-content a {
      color: #000000; }
  .block--cta-block.light-purple:not(.ligh-backgorund) {
    border-top-color: #5F65DF; }
    .block--cta-block.light-purple:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #5F65DF; }
      .block--cta-block.light-purple:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.light-purple.light-background .block-content {
    color: #000000;
    background-color: white; }
    .block--cta-block.light-purple.light-background .block-content a {
      color: #000000; }
  .block--cta-block.pastel-blue:not(.ligh-backgorund) {
    border-top-color: #b4c8da; }
    .block--cta-block.pastel-blue:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #b4c8da; }
      .block--cta-block.pastel-blue:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.pastel-blue.light-background .block-content {
    color: #000000;
    background-color: white; }
    .block--cta-block.pastel-blue.light-background .block-content a {
      color: #000000; }
  .block--cta-block.light-pastel-blue:not(.ligh-backgorund) {
    border-top-color: #e6ecf2; }
    .block--cta-block.light-pastel-blue:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #e6ecf2; }
      .block--cta-block.light-pastel-blue:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.light-pastel-blue.light-background .block-content {
    color: #000000;
    background-color: white; }
    .block--cta-block.light-pastel-blue.light-background .block-content a {
      color: #000000; }
  .block--cta-block.darkest-gray:not(.ligh-backgorund) {
    border-top-color: #333333; }
    .block--cta-block.darkest-gray:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #333333; }
      .block--cta-block.darkest-gray:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.darkest-gray.light-background .block-content {
    color: #000000;
    background-color: #b3b3b3; }
    .block--cta-block.darkest-gray.light-background .block-content a {
      color: #000000; }
  .block--cta-block.dark-gray:not(.ligh-backgorund) {
    border-top-color: #53575d; }
    .block--cta-block.dark-gray:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #53575d; }
      .block--cta-block.dark-gray:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.dark-gray.light-background .block-content {
    color: #000000;
    background-color: #d5d7da; }
    .block--cta-block.dark-gray.light-background .block-content a {
      color: #000000; }
  .block--cta-block.medium-gray:not(.ligh-backgorund) {
    border-top-color: #666666; }
    .block--cta-block.medium-gray:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #666666; }
      .block--cta-block.medium-gray:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.medium-gray.light-background .block-content {
    color: #000000;
    background-color: #e6e6e6; }
    .block--cta-block.medium-gray.light-background .block-content a {
      color: #000000; }
  .block--cta-block.light-gray:not(.ligh-backgorund) {
    border-top-color: #cccccc; }
    .block--cta-block.light-gray:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #cccccc; }
      .block--cta-block.light-gray:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.light-gray.light-background .block-content {
    color: #000000;
    background-color: white; }
    .block--cta-block.light-gray.light-background .block-content a {
      color: #000000; }
  .block--cta-block.off-white:not(.ligh-backgorund) {
    border-top-color: #f2f2f2; }
    .block--cta-block.off-white:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #f2f2f2; }
      .block--cta-block.off-white:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.off-white.light-background .block-content {
    color: #000000;
    background-color: white; }
    .block--cta-block.off-white.light-background .block-content a {
      color: #000000; }
  .block--cta-block.pink:not(.ligh-backgorund) {
    border-top-color: #ac1675; }
    .block--cta-block.pink:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #ac1675; }
      .block--cta-block.pink:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.pink.light-background .block-content {
    color: #000000;
    background-color: #f8c9e7; }
    .block--cta-block.pink.light-background .block-content a {
      color: #000000; }
  .block--cta-block.purple:not(.ligh-backgorund) {
    border-top-color: #630685; }
    .block--cta-block.purple:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #630685; }
      .block--cta-block.purple:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.purple.light-background .block-content {
    color: #000000;
    background-color: #de90fa; }
    .block--cta-block.purple.light-background .block-content a {
      color: #000000; }
  .block--cta-block.orange:not(.ligh-backgorund) {
    border-top-color: #f17f00; }
    .block--cta-block.orange:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #f17f00; }
      .block--cta-block.orange:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.orange.light-background .block-content {
    color: #000000;
    background-color: #fff8f1; }
    .block--cta-block.orange.light-background .block-content a {
      color: #000000; }
  .block--cta-block.lightest-gray:not(.ligh-backgorund) {
    border-top-color: #f2f2f2; }
    .block--cta-block.lightest-gray:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #f2f2f2; }
      .block--cta-block.lightest-gray:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.lightest-gray.light-background .block-content {
    color: #000000;
    background-color: white; }
    .block--cta-block.lightest-gray.light-background .block-content a {
      color: #000000; }
  .block--cta-block.green:not(.ligh-backgorund) {
    border-top-color: #0a9178; }
    .block--cta-block.green:not(.ligh-backgorund):not(.white-content) .block-content {
      color: #ffffff;
      background-color: #0a9178; }
      .block--cta-block.green:not(.ligh-backgorund):not(.white-content) .block-content a {
        color: #ffffff; }
  .block--cta-block.green.light-background .block-content {
    color: #000000;
    background-color: #a1f9e8; }
    .block--cta-block.green.light-background .block-content a {
      color: #000000; }
  .block--cta-block.with-image img {
    float: right;
    width: 40%;
    margin-right: .5em; }
  .block--cta-block.title-white .block-title {
    color: #ffffff; }
  .block--cta-block.white-content .block-content {
    padding: 0;
    color: #53575d;
    background-color: #ffffff; }
    .block--cta-block.white-content .block-content a {
      color: #53575d; }

@media (min-width: 0) and (max-width: 759px) {
  .block--cta-block.with-image img {
    display: none; } }

.block--homepage-news {
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #084983;
  background-image: url("../images/patterns/news-pattern.png");
  background-size: cover; }
  .block--homepage-news .block-content {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto; }
    .block--homepage-news .block-content:after {
      content: " ";
      display: block;
      clear: both; }
  .block--homepage-news .views-row {
    padding-top: .75em;
    border-top: 0.5em solid #66ccff;
    text-transform: uppercase; }
    .block--homepage-news .views-row h2 {
      min-height: 2em;
      line-height: 0.75em; }
    .block--homepage-news .views-row h2 a {
      font-size: 0.75em;
      font-weight: 400;
      color: #ffffff; }

@media (min-width: 0) and (max-width: 759px) {
  .block--homepage-news .views-row {
    width: 91.6666666667%;
    float: left;
    margin-left: 4.1666666667%;
    margin-right: 4.1666666667%;
    max-height: 18em;
    margin-bottom: 2em;
    overflow: hidden; }
    .block--homepage-news .views-row.views-row-4, .block--homepage-news .views-row.views-row-5, .block--homepage-news .views-row.views-row-6, .block--homepage-news .views-row.views-row-7 {
      width: 41.6666666667%;
      float: left;
      margin-left: 4.1666666667%;
      margin-right: 4.1666666667%; } }

@media (min-width: 760px) {
  .block--homepage-news .view-homepage-news {
    position: relative; }
  .block--homepage-news .views-row-1 {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%; }
  .block--homepage-news .views-row-2 {
    width: 22.8571428571%;
    float: left;
    margin-right: 2.8571428571%; }
  .block--homepage-news .views-row-3 {
    width: 40%;
    float: right;
    margin-right: 0; }
  .block--homepage-news .views-row-4,
  .block--homepage-news .views-row-5,
  .block--homepage-news .views-row-6,
  .block--homepage-news .views-row-7 {
    width: 14.2857142857%;
    float: left;
    margin-right: 2.8571428571%;
    position: absolute;
    bottom: 0;
    right: 0; }
  .block--homepage-news .views-row-4 {
    right: 48.5714285714%; }
  .block--homepage-news .views-row-5 {
    right: 31.4285714286%; }
  .block--homepage-news .views-row-6 {
    right: 14.2857142857%; }
  .block--homepage-news .views-row-7 {
    margin-right: 0; } }

.block--bsi-member {
  width: 91.6666666667%;
  float: left;
  margin-left: 4.1666666667%;
  margin-right: 4.1666666667%; }
  .block--bsi-member .block-title {
    margin-bottom: 3em;
    text-transform: uppercase; }
  .block--bsi-member img {
    max-height: 3.5em;
    width: auto;
    height: auto; }
  .block--bsi-member p {
    margin-top: .5em;
    padding-top: .5em;
    border-top: 1px solid #cccccc;
    font-size: .8em; }
  .block--bsi-member .views-row {
    width: 40%;
    float: left;
    margin-bottom: 3em; }
    .block--bsi-member .views-row:nth-child(2n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .block--bsi-member .views-row:nth-child(2n + 2) {
      margin-left: 60%;
      margin-right: -100%;
      clear: none; }

@media (min-width: 760px) {
  .block--bsi-member {
    width: 97.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
    .block--bsi-member .block-content .views-row {
      width: 11.7647058824%;
      float: left; }
      .block--bsi-member .block-content .views-row:nth-child(6n + 1) {
        margin-left: 0;
        margin-right: -100%;
        clear: both;
        margin-left: 0; }
      .block--bsi-member .block-content .views-row:nth-child(6n + 2) {
        margin-left: 17.6470588235%;
        margin-right: -100%;
        clear: none; }
      .block--bsi-member .block-content .views-row:nth-child(6n + 3) {
        margin-left: 35.2941176471%;
        margin-right: -100%;
        clear: none; }
      .block--bsi-member .block-content .views-row:nth-child(6n + 4) {
        margin-left: 52.9411764706%;
        margin-right: -100%;
        clear: none; }
      .block--bsi-member .block-content .views-row:nth-child(6n + 5) {
        margin-left: 70.5882352941%;
        margin-right: -100%;
        clear: none; }
      .block--bsi-member .block-content .views-row:nth-child(6n + 6) {
        margin-left: 88.2352941176%;
        margin-right: -100%;
        clear: none; } }

.block--footer-menu {
  margin-bottom: 2em;
  width: 91.6666666667%;
  float: left;
  margin-left: 4.1666666667%;
  margin-right: 4.1666666667%;
  text-align: center; }
  .block--footer-menu h2.block-title {
    color: #666666;
    font-weight: 700; }
  .block--footer-menu .menu {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .block--footer-menu .menu li:before {
      display: none; }
    .block--footer-menu .menu li {
      margin-bottom: 0.375em;
      font-size: .9em; }
    .block--footer-menu .menu a {
      color: #ffffff; }

@media (min-width: 760px) {
  .block--footer-menu {
    text-align: left; }
    .block--footer-menu .menu li {
      font-size: 1em; } }

@media (min-width: 760px) and (max-width: 979px) {
  .block--footer-menu {
    width: 47.2222222222%;
    float: left; }
    .block--footer-menu:nth-child(2n + 1) {
      margin-left: 1.3888888889%;
      margin-right: -100%;
      clear: both; }
    .block--footer-menu:nth-child(2n + 2) {
      margin-left: 51.3888888889%;
      margin-right: -100%;
      clear: none; } }

@media (min-width: 980px) {
  .block--footer-menu {
    width: 22.2222222222%;
    float: left; }
    .block--footer-menu:nth-child(4n + 1) {
      margin-left: 1.3888888889%;
      margin-right: -100%;
      clear: both; }
    .block--footer-menu:nth-child(4n + 2) {
      margin-left: 26.3888888889%;
      margin-right: -100%;
      clear: none; }
    .block--footer-menu:nth-child(4n + 3) {
      margin-left: 51.3888888889%;
      margin-right: -100%;
      clear: none; }
    .block--footer-menu:nth-child(4n + 4) {
      margin-left: 76.3888888889%;
      margin-right: -100%;
      clear: none; } }

.block--side-block {
  margin-bottom: 3em;
  padding-top: .5em;
  border-top: 0.5em solid #0a9178; }
  .block--side-block .block-title {
    margin-bottom: 1em;
    color: #53575d;
    text-transform: uppercase; }
  .block--side-block .block-content .menu {
    padding-left: 0;
    list-style: none; }
  .block--side-block .block-content .views-row, .block--side-block .block-content li {
    margin-bottom: 0.375em; }
  .block--side-block .block-content li ul.menu {
    padding-left: 1em; }
  .block--side-block .block-content a:not(.button) {
    position: relative;
    display: block;
    color: #53575d; }
  .block--side-block .block-content a:hover,
  .block--side-block .block-content a.active-trail {
    background-color: #a1f9e8; }
  body[class*="path-events"] .block--side-block {
    background-image: none; }
  body[class*="path-publications"] .block--side-block {
    border-top: 0.5em solid #1e97ad;
    background-image: none; }
  body[class*="path-news"] .block--side-block {
    border-top: 0.5em solid #0a9178;
    background-image: none; }
  body[class*="path-careers"] .block--side-block {
    border-top: 0.5em solid #084983;
    background-image: none; }
  body[class*="path-about-us"] .block--side-block {
    border-top: 0.5em solid #0a9178;
    background-image: none; }
  body[class*="path-public-information"] .block--side-block {
    border-top: 0.5em solid #0a9178;
    background-image: none; }

.block--bitesized-quick-links {
  margin-bottom: 3em;
  background-color: #0a9178; }
  .block--bitesized-quick-links h2.block-title {
    padding-top: 1em;
    text-align: center;
    font-size: 1em;
    color: #ffffff; }
  .block--bitesized-quick-links .menu {
    padding-left: 0;
    display: inline-block;
    width: 100%;
    text-align: center;
    list-style: none; }
  .block--bitesized-quick-links .block-inner {
    background-image: url("../images/patterns/news-pattern.png");
    background-size: 95%; }
  .block--bitesized-quick-links .block-content {
    padding: 1em; }
  .block--bitesized-quick-links .menu {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    .block--bitesized-quick-links .menu li:before {
      display: none; }
    .block--bitesized-quick-links .menu::after {
      clear: both;
      content: "";
      display: table; }
  .block--bitesized-quick-links .menu li {
    text-align: center; }
    .block--bitesized-quick-links .menu li a::after {
      content: "\f054";
      font-family: fontAwesome;
      margin-left: 10px; }
  .block--bitesized-quick-links a {
    display: block;
    padding: .75em 0;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.2);
    transition: background-color ease 150ms; }
    .block--bitesized-quick-links a:hover {
      background-color: rgba(0, 0, 0, 0.2); }

@media (min-width: 0) and (max-width: 759px) {
  .block--bitesized-quick-links .menu li {
    margin-bottom: 1em; } }

@media (min-width: 760px) {
  .block--bitesized-quick-links .menu li {
    width: 29.6296296296%;
    float: left;
    margin-left: 1.8518518519%;
    margin-right: 1.8518518519%;
    font-size: .8em; } }

@media (min-width: 980px) {
  .block--bitesized-quick-links .menu li {
    font-size: 1em; } }

.block--bitesize-categories {
  margin-top: 4.5em;
  margin-bottom: 4.5em;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: #f2f2f2; }
  .block--bitesize-categories::after {
    clear: both;
    content: "";
    display: table; }
  .block--bitesize-categories .view-content a {
    text-align: center;
    color: #000000; }
    .block--bitesize-categories .view-content a .image img {
      height: auto;
      transition: opacity ease 150ms; }
    .block--bitesize-categories .view-content a:hover {
      color: #53575d; }
      .block--bitesize-categories .view-content a:hover img {
        opacity: .8; }
  .block--bitesize-categories .views-row::after {
    clear: both;
    content: "";
    display: table; }
  .block--bitesize-categories span {
    display: block;
    font-size: .9em;
    font-weight: 600; }

@media (min-width: 0) and (max-width: 449px) {
  .block--bitesize-categories .views-row {
    width: 50%;
    margin: 0 auto;
    margin-bottom: 2em; } }

@media (min-width: 450px) and (max-width: 759px) {
  .block--bitesize-categories .views-row {
    width: 33.3333333333%;
    float: left;
    margin-bottom: 2em; }
    .block--bitesize-categories .views-row:nth-child(2n + 1) {
      margin-left: 8.3333333333%;
      margin-right: -100%;
      clear: both; }
    .block--bitesize-categories .views-row:nth-child(2n + 2) {
      margin-left: 58.3333333333%;
      margin-right: -100%;
      clear: none; }
  .block--bitesize-categories .view-content a.last {
    display: block;
    float: none;
    clear: both;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0; } }

@media (min-width: 760px) {
  .block--bitesize-categories .views-row {
    width: 10.4761904762%;
    float: left;
    margin-left: 4.7619047619%;
    margin-right: 4.7619047619%;
    margin-bottom: 1em; }
  .block--bitesize-categories .view-content > div:nth-child(5n+6) {
    clear: both; } }

@media (min-width: 980px) {
  .block-bsi-region-map {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%; } }

.block--latest-news {
  position: relative;
  margin-bottom: 3em; }
  .block--latest-news::after {
    clear: both;
    content: "";
    display: table; }
  .block--latest-news img {
    width: 100%;
    height: auto;
    display: block; }
  .block--latest-news .block-title {
    margin-bottom: 2em;
    text-transform: uppercase; }
  .block--latest-news .views-field-title a {
    color: #000000; }
  .block--latest-news .field-name-field-date {
    margin-bottom: 1em; }
  .block--latest-news.group {
    padding-top: .7em; }
    .block--latest-news.group .view-content {
      position: relative;
      display: inline-block;
      padding-bottom: 4em; }
      .block--latest-news.group .view-content > div:first-child .field-type-image {
        display: block; }
      .block--latest-news.group .view-content > div:first-child .field-name-body {
        display: block; }
    .block--latest-news.group .view-footer {
      position: absolute;
      bottom: 1em; }
      .block--latest-news.group .view-footer p {
        margin: 0; }
    .block--latest-news.group .views-row-first {
      padding-top: .75em; }
      .block--latest-news.group .views-row-first .field-type-image {
        display: block; }
    .block--latest-news.group .views-row, .block--latest-news.group .view-footer {
      width: calc(100% - 30%); }
    .block--latest-news.group .field-type-image {
      width: 30%;
      position: absolute;
      top: .75em;
      height: calc(100% - 1.5em);
      overflow: hidden;
      display: none; }
    .block--latest-news.group .field-name-body {
      display: none; }
    .block--latest-news.group.left .field-type-image {
      left: .75em; }
    .block--latest-news.group.left .views-row, .block--latest-news.group.left .view-footer {
      float: right;
      padding-left: .75em;
      padding-right: .75em;
      padding-left: 1.5em; }
    .block--latest-news.group.left .view-footer {
      right: 0; }
    .block--latest-news.group.right .field-type-image {
      right: .75em; }
    .block--latest-news.group.right .views-row, .block--latest-news.group.right .view-footer {
      float: left;
      padding-left: .75em;
      padding-right: .75em;
      padding-right: 1.5em; }
    .block--latest-news.group.right .view-footer {
      left: 0; }
  .block--latest-news.separate .views-row {
    padding-top: .7em;
    width: 29.4117647059%;
    float: left;
    margin-right: 5.8823529412%; }
  .block--latest-news.separate .views-row-first {
    width: 29.4117647059%;
    float: left;
    margin-right: 5.8823529412%; }
  .block--latest-news.separate .views-row-last {
    width: 29.4117647059%;
    float: right;
    margin-right: 0; }
  .block--latest-news.separate .view-content {
    background-color: transparent; }

.block--latest-news.group.white {
  border-top: 0.5em solid #ffffff; }
  .block--latest-news.group.white.background .block-content {
    background-color: white; }

.block--latest-news.group.black {
  border-top: 0.5em solid #000000; }
  .block--latest-news.group.black.background .block-content {
    background-color: gray; }

.block--latest-news.group.dark-blue {
  border-top: 0.5em solid #084983; }
  .block--latest-news.group.dark-blue.background .block-content {
    background-color: #92c8f8; }

.block--latest-news.group.blue {
  border-top: 0.5em solid #1e97ad; }
  .block--latest-news.group.blue.background .block-content {
    background-color: #d3f2f7; }

.block--latest-news.group.light-blue {
  border-top: 0.5em solid #66ccff; }
  .block--latest-news.group.light-blue.background .block-content {
    background-color: white; }

.block--latest-news.group.light-purple {
  border-top: 0.5em solid #5F65DF; }
  .block--latest-news.group.light-purple.background .block-content {
    background-color: white; }

.block--latest-news.group.pastel-blue {
  border-top: 0.5em solid #b4c8da; }
  .block--latest-news.group.pastel-blue.background .block-content {
    background-color: white; }

.block--latest-news.group.light-pastel-blue {
  border-top: 0.5em solid #e6ecf2; }
  .block--latest-news.group.light-pastel-blue.background .block-content {
    background-color: white; }

.block--latest-news.group.darkest-gray {
  border-top: 0.5em solid #333333; }
  .block--latest-news.group.darkest-gray.background .block-content {
    background-color: #b3b3b3; }

.block--latest-news.group.dark-gray {
  border-top: 0.5em solid #53575d; }
  .block--latest-news.group.dark-gray.background .block-content {
    background-color: #d5d7da; }

.block--latest-news.group.medium-gray {
  border-top: 0.5em solid #666666; }
  .block--latest-news.group.medium-gray.background .block-content {
    background-color: #e6e6e6; }

.block--latest-news.group.light-gray {
  border-top: 0.5em solid #cccccc; }
  .block--latest-news.group.light-gray.background .block-content {
    background-color: white; }

.block--latest-news.group.off-white {
  border-top: 0.5em solid #f2f2f2; }
  .block--latest-news.group.off-white.background .block-content {
    background-color: white; }

.block--latest-news.group.pink {
  border-top: 0.5em solid #ac1675; }
  .block--latest-news.group.pink.background .block-content {
    background-color: #f8c9e7; }

.block--latest-news.group.purple {
  border-top: 0.5em solid #630685; }
  .block--latest-news.group.purple.background .block-content {
    background-color: #de90fa; }

.block--latest-news.group.orange {
  border-top: 0.5em solid #f17f00; }
  .block--latest-news.group.orange.background .block-content {
    background-color: #fff8f1; }

.block--latest-news.group.lightest-gray {
  border-top: 0.5em solid #f2f2f2; }
  .block--latest-news.group.lightest-gray.background .block-content {
    background-color: white; }

.block--latest-news.group.green {
  border-top: 0.5em solid #0a9178; }
  .block--latest-news.group.green.background .block-content {
    background-color: #a1f9e8; }

.block--latest-news.separate.white .views-row {
  border-top: 0.5em solid #ffffff; }

.block--latest-news.separate.black .views-row {
  border-top: 0.5em solid #000000; }

.block--latest-news.separate.dark-blue .views-row {
  border-top: 0.5em solid #084983; }

.block--latest-news.separate.blue .views-row {
  border-top: 0.5em solid #1e97ad; }

.block--latest-news.separate.light-blue .views-row {
  border-top: 0.5em solid #66ccff; }

.block--latest-news.separate.light-purple .views-row {
  border-top: 0.5em solid #5F65DF; }

.block--latest-news.separate.pastel-blue .views-row {
  border-top: 0.5em solid #b4c8da; }

.block--latest-news.separate.light-pastel-blue .views-row {
  border-top: 0.5em solid #e6ecf2; }

.block--latest-news.separate.darkest-gray .views-row {
  border-top: 0.5em solid #333333; }

.block--latest-news.separate.dark-gray .views-row {
  border-top: 0.5em solid #53575d; }

.block--latest-news.separate.medium-gray .views-row {
  border-top: 0.5em solid #666666; }

.block--latest-news.separate.light-gray .views-row {
  border-top: 0.5em solid #cccccc; }

.block--latest-news.separate.off-white .views-row {
  border-top: 0.5em solid #f2f2f2; }

.block--latest-news.separate.pink .views-row {
  border-top: 0.5em solid #ac1675; }

.block--latest-news.separate.purple .views-row {
  border-top: 0.5em solid #630685; }

.block--latest-news.separate.orange .views-row {
  border-top: 0.5em solid #f17f00; }

.block--latest-news.separate.lightest-gray .views-row {
  border-top: 0.5em solid #f2f2f2; }

.block--latest-news.separate.green .views-row {
  border-top: 0.5em solid #0a9178; }

@media (min-width: 0) and (max-width: 759px) {
  .block--latest-news.separate .views-row,
  .block--latest-news.separate .views-row-last,
  .block--latest-news.separate .views-row-last {
    width: 100%;
    margin-bottom: 3em; }
    .block--latest-news.separate .views-row .field-name-field-image,
    .block--latest-news.separate .views-row-last .field-name-field-image,
    .block--latest-news.separate .views-row-last .field-name-field-image {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0;
      background-color: red; }
  .block--latest-news.group .view-content > div:first-child .field-name-field-image {
    display: none; }
  .block--latest-news.group .views-row {
    width: 100%;
    padding-left: .75em;
    padding-right: .75em; }
  .block--latest-news.group .field-name-field-image {
    display: none; } }

.block--page-banner img {
  width: 100%; }

.block--page-text {
  margin-bottom: 1.5em; }

.block--latest-articles {
  clear: both; }
  .block--latest-articles .block-title {
    text-transform: uppercase; }
  .block--latest-articles .views-row {
    position: relative;
    padding: 1.5em;
    padding-bottom: 4em;
    color: #ffffff; }
    .block--latest-articles .views-row a {
      color: white; }
  .block--latest-articles .views-field-title {
    margin-bottom: 1em; }
  .block--latest-articles .views-more-link {
    position: absolute;
    bottom: 1.5em;
    display: block; }
    .block--latest-articles .views-more-link:after {
      content: "\f054";
      margin-left: 0.5em;
      font-family: "FontAwesome"; }
  .block--latest-articles .views-row-1 {
    background-color: #1e97ad; }
  .block--latest-articles .views-row-2 {
    background-color: #ac1675; }
  .block--latest-articles .views-row-3 {
    background-color: #630685; }

@media (min-width: 0) and (max-width: 759px) {
  .block--latest-articles .views-row {
    margin-bottom: 1.5em; } }

@media (min-width: 760px) {
  .block--latest-articles .views-row {
    width: 32%;
    float: left; }
    .block--latest-articles .views-row:nth-child(3n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .block--latest-articles .views-row:nth-child(3n + 2) {
      margin-left: 34%;
      margin-right: -100%;
      clear: none; }
    .block--latest-articles .views-row:nth-child(3n + 3) {
      margin-left: 68%;
      margin-right: -100%;
      clear: none; } }

.block--immunology-news-landing img {
  width: 100%;
  height: auto; }

.block--immunology-news-landing .views-row {
  position: relative;
  margin-bottom: 3em; }
  .block--immunology-news-landing .views-row::after {
    clear: both;
    content: "";
    display: table; }
  .block--immunology-news-landing .views-row > div:not(.views-field-title):not(.field-name-body) {
    padding: 0.5em;
    color: white; }
    .block--immunology-news-landing .views-row > div:not(.views-field-title):not(.field-name-body) a {
      color: white; }

.block--immunology-news-landing .views-field-title {
  padding-top: 0.1em;
  padding-bottom: 1em;
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 300;
  background-color: #ffffff;
  color: #53575d; }

.block--immunology-news-landing .field-name-field-image {
  float: right;
  width: 100%; }

.block--immunology-news-landing .field-name-body {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 2.25em;
  color: white; }
  .block--immunology-news-landing .field-name-body a {
    color: white; }

.block--immunology-news-landing .views-field-view-node {
  position: absolute;
  bottom: 0;
  padding: 0.5em; }
  .block--immunology-news-landing .views-field-view-node a:after {
    font-family: fontAwesome;
    content: "";
    margin-left: 10px; }

.block--immunology-news-landing .attachment-after .view-header {
  font-size: 1.8020324707em;
  font-weight: 600;
  margin-bottom: 0.75em; }

.block--immunology-news-landing .views-row.white {
  background-color: #ffffff;
  border-top: 0.5em solid #ffffff; }

.block--immunology-news-landing .views-row.black {
  background-color: #000000;
  border-top: 0.5em solid #000000; }

.block--immunology-news-landing .views-row.dark-blue {
  background-color: #084983;
  border-top: 0.5em solid #084983; }

.block--immunology-news-landing .views-row.blue {
  background-color: #1e97ad;
  border-top: 0.5em solid #1e97ad; }

.block--immunology-news-landing .views-row.light-blue {
  background-color: #66ccff;
  border-top: 0.5em solid #66ccff; }

.block--immunology-news-landing .views-row.light-purple {
  background-color: #5F65DF;
  border-top: 0.5em solid #5F65DF; }

.block--immunology-news-landing .views-row.pastel-blue {
  background-color: #b4c8da;
  border-top: 0.5em solid #b4c8da; }

.block--immunology-news-landing .views-row.light-pastel-blue {
  background-color: #e6ecf2;
  border-top: 0.5em solid #e6ecf2; }

.block--immunology-news-landing .views-row.darkest-gray {
  background-color: #333333;
  border-top: 0.5em solid #333333; }

.block--immunology-news-landing .views-row.dark-gray {
  background-color: #53575d;
  border-top: 0.5em solid #53575d; }

.block--immunology-news-landing .views-row.medium-gray {
  background-color: #666666;
  border-top: 0.5em solid #666666; }

.block--immunology-news-landing .views-row.light-gray {
  background-color: #cccccc;
  border-top: 0.5em solid #cccccc; }

.block--immunology-news-landing .views-row.off-white {
  background-color: #f2f2f2;
  border-top: 0.5em solid #f2f2f2; }

.block--immunology-news-landing .views-row.pink {
  background-color: #ac1675;
  border-top: 0.5em solid #ac1675; }

.block--immunology-news-landing .views-row.purple {
  background-color: #630685;
  border-top: 0.5em solid #630685; }

.block--immunology-news-landing .views-row.orange {
  background-color: #f17f00;
  border-top: 0.5em solid #f17f00; }

.block--immunology-news-landing .views-row.lightest-gray {
  background-color: #f2f2f2;
  border-top: 0.5em solid #f2f2f2; }

.block--immunology-news-landing .views-row.green {
  background-color: #0a9178;
  border-top: 0.5em solid #0a9178; }

@media (min-width: 450px) and (max-width: 759px) {
  .block--immunology-news-landing .field-name-field-image {
    width: 30%; }
  .block--immunology-news-landing .attachment-after .field-name-field-image {
    width: 100%; }
  .block--immunology-news-landing .attachment-after .views-row-odd {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%; }
  .block--immunology-news-landing .attachment-after .views-row-even {
    width: 48.5714285714%;
    float: right;
    margin-right: 0; } }

@media (min-width: 760px) {
  .block--immunology-news-landing .field-name-field-image {
    width: 30%; }
  .block--immunology-news-landing .attachment-after .views-row .field-name-field-image {
    width: 40%; }
  .block--immunology-news-landing .attachment-after .views-row-odd {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%; }
  .block--immunology-news-landing .attachment-after .views-row-even {
    width: 48.5714285714%;
    float: right;
    margin-right: 0; } }

@media (min-width: 980px) {
  .block--immunology-news-landing .field-name-field-image {
    width: 20%; } }

.block--immunology-news-node-page span.file {
  position: absolute;
  bottom: 1em; }
  .block--immunology-news-node-page span.file img, .block--immunology-news-node-page span.file span {
    display: none; }
  .block--immunology-news-node-page span.file a {
    color: #ffffff; }
  .block--immunology-news-node-page span.file:after {
    font-family: fontAwesome;
    content: "";
    margin-left: 10px; }

.block--immunology-news-node-page .views-row {
  color: #ffffff;
  padding: 0.5em;
  position: relative; }
  .block--immunology-news-node-page .views-row::after {
    clear: both;
    content: "";
    display: table; }

.block--immunology-news-node-page .field-name-body {
  padding-bottom: 2em; }

.block--immunology-news-node-page .field-name-field-image {
  float: right;
  width: 100%; }
  .block--immunology-news-node-page .field-name-field-image img {
    width: 100%;
    height: auto; }

.block--immunology-news-node-page .view-display-id-attachment_2 {
  margin-bottom: 1.5em;
  margin-top: 0.75em; }
  .block--immunology-news-node-page .view-display-id-attachment_2 a {
    color: #084983; }
  .block--immunology-news-node-page .view-display-id-attachment_2 img {
    display: block;
    height: auto; }
  .block--immunology-news-node-page .view-display-id-attachment_2 p {
    color: #53575d; }
  .block--immunology-news-node-page .view-display-id-attachment_2 .view-header {
    font-size: 1.8020324707em;
    font-weight: 600; }
  .block--immunology-news-node-page .view-display-id-attachment_2 .views-row {
    padding: 0;
    position: relative;
    margin-bottom: 3em;
    padding-top: 0.75em;
    border-top: 0.25em solid #f17f00; }
  .block--immunology-news-node-page .view-display-id-attachment_2 fieldset {
    position: relative; }
  .block--immunology-news-node-page .view-display-id-attachment_2 .views-field, .block--immunology-news-node-page .view-display-id-attachment_2 .field {
    display: block;
    color: #53575d; }
  .block--immunology-news-node-page .view-display-id-attachment_2 .field-name-field-image {
    float: left;
    margin-right: 1.5em;
    margin-bottom: 1em; }
  .block--immunology-news-node-page .view-display-id-attachment_2 .title {
    text-transform: uppercase;
    color: #000000; }
  .block--immunology-news-node-page .view-display-id-attachment_2 .field-name-body {
    padding-bottom: 1.5em; }
  .block--immunology-news-node-page .view-display-id-attachment_2 .link {
    position: absolute;
    bottom: 0;
    left: 0; }
    .block--immunology-news-node-page .view-display-id-attachment_2 .link a:after {
      content: "\f054";
      font-family: fontAwesome;
      margin-left: 0.5em; }

.block--immunology-news-node-page .views-row.white {
  background-color: #ffffff; }

.block--immunology-news-node-page .views-row.black {
  background-color: #000000; }

.block--immunology-news-node-page .views-row.dark-blue {
  background-color: #084983; }

.block--immunology-news-node-page .views-row.blue {
  background-color: #1e97ad; }

.block--immunology-news-node-page .views-row.light-blue {
  background-color: #66ccff; }

.block--immunology-news-node-page .views-row.light-purple {
  background-color: #5F65DF; }

.block--immunology-news-node-page .views-row.pastel-blue {
  background-color: #b4c8da; }

.block--immunology-news-node-page .views-row.light-pastel-blue {
  background-color: #e6ecf2; }

.block--immunology-news-node-page .views-row.darkest-gray {
  background-color: #333333; }

.block--immunology-news-node-page .views-row.dark-gray {
  background-color: #53575d; }

.block--immunology-news-node-page .views-row.medium-gray {
  background-color: #666666; }

.block--immunology-news-node-page .views-row.light-gray {
  background-color: #cccccc; }

.block--immunology-news-node-page .views-row.off-white {
  background-color: #f2f2f2; }

.block--immunology-news-node-page .views-row.pink {
  background-color: #ac1675; }

.block--immunology-news-node-page .views-row.purple {
  background-color: #630685; }

.block--immunology-news-node-page .views-row.orange {
  background-color: #f17f00; }

.block--immunology-news-node-page .views-row.lightest-gray {
  background-color: #f2f2f2; }

.block--immunology-news-node-page .views-row.green {
  background-color: #0a9178; }

@media (min-width: 450px) and (max-width: 759px) {
  .block--immunology-news-node-page .field-name-field-image {
    width: 40%; }
  .block--immunology-news-node-page .view-display-id-attachment_2 .field-name-field-image {
    width: 30%; } }

@media (min-width: 760px) {
  .block--immunology-news-node-page .field-name-field-image {
    width: 20%; }
  .block--immunology-news-node-page .view-display-id-attachment_2 .field-name-field-image {
    width: 30%; } }

@media (min-width: 980px) {
  .one-sidebar .view-display-id-attachment_2 .views-row {
    width: 48.0769230769%;
    float: left; }
    .one-sidebar .view-display-id-attachment_2 .views-row:nth-child(2n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .one-sidebar .view-display-id-attachment_2 .views-row:nth-child(2n + 2) {
      margin-left: 51.9230769231%;
      margin-right: -100%;
      clear: none; }
  .two-sidebars .view-display-id-attachment_2 .views-row {
    width: 47.0588235294%;
    float: left; }
    .two-sidebars .view-display-id-attachment_2 .views-row:nth-child(2n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .two-sidebars .view-display-id-attachment_2 .views-row:nth-child(2n + 2) {
      margin-left: 52.9411764706%;
      margin-right: -100%;
      clear: none; } }

.block--user-page-tiles {
  clear: both;
  color: white; }
  .block--user-page-tiles a {
    display: block; }
  .block--user-page-tiles img {
    display: block;
    width: 100%;
    height: auto; }
  .block--user-page-tiles .views-row {
    padding: 0.75em;
    padding-bottom: 4em;
    position: relative;
    margin-bottom: 2em; }
    .block--user-page-tiles .views-row::after {
      clear: both;
      content: "";
      display: table; }
  .block--user-page-tiles .field-name-field-image {
    float: right;
    width: 100%;
    padding-bottom: 1em; }
  .block--user-page-tiles .views-field-title {
    text-transform: uppercase;
    padding-bottom: 1em;
    display: block;
    font-size: 1.4em; }
  .block--user-page-tiles .field-name-body {
    padding-right: 1em; }
  .block--user-page-tiles .views-field-field-external-link-1 {
    position: absolute;
    bottom: 0.75em;
    right: 0.75em; }

.block--user-page-tiles {
  clear: both; }
  .block--user-page-tiles .views-row.white {
    background-color: #ffffff; }
  .block--user-page-tiles .views-row.black {
    background-color: #000000; }
  .block--user-page-tiles .views-row.dark-blue {
    background-color: #084983; }
  .block--user-page-tiles .views-row.blue {
    background-color: #1e97ad; }
  .block--user-page-tiles .views-row.light-blue {
    background-color: #66ccff; }
  .block--user-page-tiles .views-row.light-purple {
    background-color: #5F65DF; }
  .block--user-page-tiles .views-row.pastel-blue {
    background-color: #b4c8da; }
  .block--user-page-tiles .views-row.light-pastel-blue {
    background-color: #e6ecf2; }
  .block--user-page-tiles .views-row.darkest-gray {
    background-color: #333333; }
  .block--user-page-tiles .views-row.dark-gray {
    background-color: #53575d; }
  .block--user-page-tiles .views-row.medium-gray {
    background-color: #666666; }
  .block--user-page-tiles .views-row.light-gray {
    background-color: #cccccc; }
  .block--user-page-tiles .views-row.off-white {
    background-color: #f2f2f2; }
  .block--user-page-tiles .views-row.pink {
    background-color: #ac1675; }
  .block--user-page-tiles .views-row.purple {
    background-color: #630685; }
  .block--user-page-tiles .views-row.orange {
    background-color: #f17f00; }
  .block--user-page-tiles .views-row.lightest-gray {
    background-color: #f2f2f2; }
  .block--user-page-tiles .views-row.green {
    background-color: #0a9178; }

@media (min-width: 450px) and (max-width: 759px) {
  .block--user-page-tiles .field-name-field-image {
    width: 40%;
    padding-left: 1em;
    padding-bottom: 0; }
  .block--user-page-tiles .views-field-title, .block--user-page-tiles .field-name-body {
    width: 60%; } }

@media (min-width: 760px) {
  .block--user-page-tiles .views-row {
    width: 48.5714285714%;
    float: left; }
    .block--user-page-tiles .views-row:nth-child(2n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .block--user-page-tiles .views-row:nth-child(2n + 2) {
      margin-left: 51.4285714286%;
      margin-right: -100%;
      clear: none; }
  .block--user-page-tiles .field-name-field-image {
    width: 40%;
    padding-left: 1em;
    padding-bottom: 0; }
  .block--user-page-tiles .views-field-title, .block--user-page-tiles .field-name-body {
    width: 60%; } }

.block--user-page-top-tiles {
  border-top: 0.5em solid black;
  margin-bottom: 3em;
  padding-top: .5em; }
  .block--user-page-top-tiles h2 {
    margin-bottom: 1em;
    color: #53575d;
    text-transform: uppercase; }
  .block--user-page-top-tiles img {
    width: 100%;
    float: left;
    display: block; }
  .block--user-page-top-tiles.block-display .block-content {
    float: left;
    padding-bottom: 5em;
    position: relative; }
    .block--user-page-top-tiles.block-display .block-content img {
      width: 100%; }
    .block--user-page-top-tiles.block-display .block-content p:first-child {
      display: block;
      float: left;
      width: 100%; }
  .block--user-page-top-tiles div.view-content {
    width: 100%;
    float: left;
    padding-left: 0;
    padding-right: 0; }
  .block--user-page-top-tiles.node-link div.views-row .views-field-view-node {
    position: absolute;
    bottom: 1em;
    right: 1em; }
    .block--user-page-top-tiles.node-link div.views-row .views-field-view-node a {
      position: initial;
      display: inline-block;
      left: 0;
      font-size: initial; }
  .block--user-page-top-tiles .view-header {
    width: 100%;
    margin-bottom: 1em; }
    .block--user-page-top-tiles .view-header::after {
      clear: both;
      content: "";
      display: table; }
  .block--user-page-top-tiles .block-content {
    position: relative;
    padding: 1em;
    padding-bottom: 5em; }
    .block--user-page-top-tiles .block-content::after {
      clear: both;
      content: "";
      display: table; }
  .block--user-page-top-tiles .field-type-image p {
    margin: 0; }
  .block--user-page-top-tiles .view-footer a, .block--user-page-top-tiles .views-field-path a, .block--user-page-top-tiles.block-display .block-content a {
    position: absolute;
    bottom: 1em;
    right: 1em; }
  .block--user-page-top-tiles .view-content div.views-row {
    border-bottom: none;
    padding-top: 0; }
  .block--user-page-top-tiles .views-field-title, .block--user-page-top-tiles .views-row .views-field-title p.title {
    font-weight: bold; }
    .block--user-page-top-tiles .views-field-title a, .block--user-page-top-tiles .views-row .views-field-title p.title a {
      color: #53575d; }
  .block--user-page-top-tiles .field-name-field-location-summary {
    padding-left: 2.5em;
    position: relative; }
    .block--user-page-top-tiles .field-name-field-location-summary:before {
      position: absolute;
      float: none;
      left: 0; }
  .block--user-page-top-tiles .views-field-created {
    margin-bottom: 0.5em; }
  .block--user-page-top-tiles .view-content > div:nth-child(2) .field-name-body {
    display: none; }
  .block--user-page-top-tiles .view-upcoming-events {
    position: initial;
    padding-bottom: 0; }

.block--user-page-top-tiles.white {
  border-top: 0.5em solid #ffffff; }
  .block--user-page-top-tiles.white .block-content {
    background-color: white; }
  .block--user-page-top-tiles.white .field-name-field-event-date::before, .block--user-page-top-tiles.white .field-name-field-location-summary::before {
    color: #ffffff; }

.block--user-page-top-tiles.black {
  border-top: 0.5em solid #000000; }
  .block--user-page-top-tiles.black .block-content {
    background-color: #333333; }
  .block--user-page-top-tiles.black .field-name-field-event-date::before, .block--user-page-top-tiles.black .field-name-field-location-summary::before {
    color: #000000; }

.block--user-page-top-tiles.dark-blue {
  border-top: 0.5em solid #084983; }
  .block--user-page-top-tiles.dark-blue .block-content {
    background-color: #0e7fe3; }
  .block--user-page-top-tiles.dark-blue .field-name-field-event-date::before, .block--user-page-top-tiles.dark-blue .field-name-field-location-summary::before {
    color: #084983; }

.block--user-page-top-tiles.blue {
  border-top: 0.5em solid #1e97ad; }
  .block--user-page-top-tiles.blue .block-content {
    background-color: #50cae1; }
  .block--user-page-top-tiles.blue .field-name-field-event-date::before, .block--user-page-top-tiles.blue .field-name-field-location-summary::before {
    color: #1e97ad; }

.block--user-page-top-tiles.light-blue {
  border-top: 0.5em solid #66ccff; }
  .block--user-page-top-tiles.light-blue .block-content {
    background-color: #cceeff; }
  .block--user-page-top-tiles.light-blue .field-name-field-event-date::before, .block--user-page-top-tiles.light-blue .field-name-field-location-summary::before {
    color: #66ccff; }

.block--user-page-top-tiles.light-purple {
  border-top: 0.5em solid #5F65DF; }
  .block--user-page-top-tiles.light-purple .block-content {
    background-color: #b4b7f0; }
  .block--user-page-top-tiles.light-purple .field-name-field-event-date::before, .block--user-page-top-tiles.light-purple .field-name-field-location-summary::before {
    color: #5F65DF; }

.block--user-page-top-tiles.pastel-blue {
  border-top: 0.5em solid #b4c8da; }
  .block--user-page-top-tiles.pastel-blue .block-content {
    background-color: #f8fafc; }
  .block--user-page-top-tiles.pastel-blue .field-name-field-event-date::before, .block--user-page-top-tiles.pastel-blue .field-name-field-location-summary::before {
    color: #b4c8da; }

.block--user-page-top-tiles.light-pastel-blue {
  border-top: 0.5em solid #e6ecf2; }
  .block--user-page-top-tiles.light-pastel-blue .block-content {
    background-color: white; }
  .block--user-page-top-tiles.light-pastel-blue .field-name-field-event-date::before, .block--user-page-top-tiles.light-pastel-blue .field-name-field-location-summary::before {
    color: #e6ecf2; }

.block--user-page-top-tiles.darkest-gray {
  border-top: 0.5em solid #333333; }
  .block--user-page-top-tiles.darkest-gray .block-content {
    background-color: #666666; }
  .block--user-page-top-tiles.darkest-gray .field-name-field-event-date::before, .block--user-page-top-tiles.darkest-gray .field-name-field-location-summary::before {
    color: #333333; }

.block--user-page-top-tiles.dark-gray {
  border-top: 0.5em solid #53575d; }
  .block--user-page-top-tiles.dark-gray .block-content {
    background-color: #848a92; }
  .block--user-page-top-tiles.dark-gray .field-name-field-event-date::before, .block--user-page-top-tiles.dark-gray .field-name-field-location-summary::before {
    color: #53575d; }

.block--user-page-top-tiles.medium-gray {
  border-top: 0.5em solid #666666; }
  .block--user-page-top-tiles.medium-gray .block-content {
    background-color: #999999; }
  .block--user-page-top-tiles.medium-gray .field-name-field-event-date::before, .block--user-page-top-tiles.medium-gray .field-name-field-location-summary::before {
    color: #666666; }

.block--user-page-top-tiles.light-gray {
  border-top: 0.5em solid #cccccc; }
  .block--user-page-top-tiles.light-gray .block-content {
    background-color: white; }
  .block--user-page-top-tiles.light-gray .field-name-field-event-date::before, .block--user-page-top-tiles.light-gray .field-name-field-location-summary::before {
    color: #cccccc; }

.block--user-page-top-tiles.off-white {
  border-top: 0.5em solid #f2f2f2; }
  .block--user-page-top-tiles.off-white .block-content {
    background-color: white; }
  .block--user-page-top-tiles.off-white .field-name-field-event-date::before, .block--user-page-top-tiles.off-white .field-name-field-location-summary::before {
    color: #f2f2f2; }

.block--user-page-top-tiles.pink {
  border-top: 0.5em solid #ac1675; }
  .block--user-page-top-tiles.pink .block-content {
    background-color: #e741aa; }
  .block--user-page-top-tiles.pink .field-name-field-event-date::before, .block--user-page-top-tiles.pink .field-name-field-location-summary::before {
    color: #ac1675; }

.block--user-page-top-tiles.purple {
  border-top: 0.5em solid #630685; }
  .block--user-page-top-tiles.purple .block-content {
    background-color: #ac0ae7; }
  .block--user-page-top-tiles.purple .field-name-field-event-date::before, .block--user-page-top-tiles.purple .field-name-field-location-summary::before {
    color: #630685; }

.block--user-page-top-tiles.orange {
  border-top: 0.5em solid #f17f00; }
  .block--user-page-top-tiles.orange .block-content {
    background-color: #ffb058; }
  .block--user-page-top-tiles.orange .field-name-field-event-date::before, .block--user-page-top-tiles.orange .field-name-field-location-summary::before {
    color: #f17f00; }

.block--user-page-top-tiles.lightest-gray {
  border-top: 0.5em solid #f2f2f2; }
  .block--user-page-top-tiles.lightest-gray .block-content {
    background-color: white; }
  .block--user-page-top-tiles.lightest-gray .field-name-field-event-date::before, .block--user-page-top-tiles.lightest-gray .field-name-field-location-summary::before {
    color: #f2f2f2; }

.block--user-page-top-tiles.green {
  border-top: 0.5em solid #0a9178; }
  .block--user-page-top-tiles.green .block-content {
    background-color: #12efc6; }
  .block--user-page-top-tiles.green .field-name-field-event-date::before, .block--user-page-top-tiles.green .field-name-field-location-summary::before {
    color: #0a9178; }

@media (min-width: 450px) and (max-width: 979px) {
  .block--user-page-top-tiles img {
    width: 30%; }
  .block--user-page-top-tiles.block-display .block-content p:first-child {
    width: 30%; }
    .block--user-page-top-tiles.block-display .block-content p:first-child img {
      padding-right: 1em; }
  .block--user-page-top-tiles .view-header {
    width: 30%;
    float: left;
    padding-right: 1em; }
    .block--user-page-top-tiles .view-header img {
      width: 100%;
      display: block; }
    .block--user-page-top-tiles .view-header p {
      margin: 0; }
  .block--user-page-top-tiles div.view-content {
    width: 70%; } }

@media (min-width: 980px) {
  .block--user-page-top-tiles {
    width: 48.5714285714%;
    float: left; }
    .block--user-page-top-tiles:nth-child(2n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .block--user-page-top-tiles:nth-child(2n + 2) {
      margin-left: 51.4285714286%;
      margin-right: -100%;
      clear: none; }
    .block--user-page-top-tiles img {
      width: 40%; }
    .block--user-page-top-tiles.block-display .block-content p:first-child {
      width: 35%;
      padding-right: 1em; }
    .block--user-page-top-tiles.block-display .block-content p:nth-of-type(n+2) {
      width: 65%;
      margin-left: 35%; }
    .block--user-page-top-tiles .view-header {
      width: 35%;
      float: left;
      padding-right: 1em; }
      .block--user-page-top-tiles .view-header img {
        width: 100%; }
    .block--user-page-top-tiles div.view-content {
      width: 65%;
      left: 35%; }
    .block--user-page-top-tiles .block-content a, .block--user-page-top-tiles .views-field-view-node, .block--user-page-top-tiles.block-display .block-content a {
      right: 0.75em; }
    .block--user-page-top-tiles.block-display .block-content a {
      position: absolute; } }

.block--homepage-news-v2 {
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: #084983;
  background-image: url("../images/patterns/news-pattern.png");
  background-size: cover; }
  .block--homepage-news-v2 .block-content {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto; }
    .block--homepage-news-v2 .block-content:after {
      content: " ";
      display: block;
      clear: both; }
  .block--homepage-news-v2 .views-row {
    padding-top: .75em;
    border-top: 0.5em solid #66ccff;
    text-transform: uppercase;
    width: 91.6666666667%;
    float: left;
    margin-left: 4.1666666667%;
    margin-right: 4.1666666667%;
    margin-bottom: 2em; }
    .block--homepage-news-v2 .views-row h2 {
      min-height: 2em;
      line-height: 0.75em; }
    .block--homepage-news-v2 .views-row h2 a {
      font-size: 0.75em;
      font-weight: 400;
      color: #ffffff; }

@media (min-width: 760px) {
  .block--homepage-news-v2 .view-homepage-news {
    position: relative; }
  .block--homepage-news-v2 .views-row {
    width: 48.5714285714%;
    float: left; }
    .block--homepage-news-v2 .views-row:nth-child(2n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .block--homepage-news-v2 .views-row:nth-child(2n + 2) {
      margin-left: 51.4285714286%;
      margin-right: -100%;
      clear: none; } }

@media (min-width: 980px) {
  .block--homepage-news-v2 .view-homepage-news {
    position: relative; }
  .block--homepage-news-v2 .views-row {
    width: 31.4285714286%;
    float: left; }
    .block--homepage-news-v2 .views-row:nth-child(3n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .block--homepage-news-v2 .views-row:nth-child(3n + 2) {
      margin-left: 34.2857142857%;
      margin-right: -100%;
      clear: none; }
    .block--homepage-news-v2 .views-row:nth-child(3n + 3) {
      margin-left: 68.5714285714%;
      margin-right: -100%;
      clear: none; } }

.block--footer-listing {
  width: 91.6666666667%;
  float: left;
  margin-left: 4.1666666667%;
  margin-right: 4.1666666667%; }

@media (min-width: 760px) {
  .block--footer-listing {
    width: 30.5555555556%;
    float: left; }
    .block--footer-listing:nth-child(3n + 1) {
      margin-left: 1.3888888889%;
      margin-right: -100%;
      clear: both; }
    .block--footer-listing:nth-child(3n + 2) {
      margin-left: 34.7222222222%;
      margin-right: -100%;
      clear: none; }
    .block--footer-listing:nth-child(3n + 3) {
      margin-left: 68.0555555556%;
      margin-right: -100%;
      clear: none; } }

.block--immunology-in-60-days-top-block {
  margin-bottom: 3em; }
  .block--immunology-in-60-days-top-block img {
    width: 65%;
    margin: 0 auto;
    display: block; }

@media (min-width: 760px) {
  .block--immunology-in-60-days-top-block img {
    width: 20%;
    float: right; }
  .block--immunology-in-60-days-top-block .block-content > p:not(:first-child) {
    width: 60%; } }

.block--addthis-toolbox {
  margin-top: 1.5em; }

.block--rss-link {
  position: absolute;
  right: 0;
  top: 0; }

div#block-views-membership-number-block {
  color: #1e97ad;
  margin-bottom: 1em;
  font-weight: bold; }

.block--twitter-feed .views-row {
  margin-bottom: .5em;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-bottom: .5em; }
  .block--twitter-feed .views-row::after {
    clear: both;
    content: "";
    display: table; }
  .block--twitter-feed .views-row.last {
    border-bottom: 0; }

.block--twitter-feed .view-content {
  padding: 0; }

.block--twitter-feed ul {
  padding: 0;
  margin-top: 0;
  list-style: none; }

.block--twitter-feed .block-content a:not(.button) {
  position: initial;
  display: initial; }

.block--twitter-feed .block-content a:hover {
  background-color: transparent; }

.block--twitter-feed .views-field-view-node {
  font-size: 0.9em; }

.block--twitter-feed .views-field-profile-image-url {
  float: left;
  width: 3em; }

.block--twitter-feed .tweet {
  float: right;
  width: calc(100% - 4em);
  font-size: .9em; }
  .block--twitter-feed .tweet p.title {
    font-weight: 300; }
  .block--twitter-feed .tweet a::after {
    display: none; }
  .block--twitter-feed .tweet p:not(.title) {
    font-weight: 400; }

.block--twitter-feed .view-footer {
  padding-left: 0;
  padding-right: 0; }
  .block--twitter-feed .view-footer a:after {
    margin-left: .5em;
    content: "\f054";
    font-family: fontAwesome; }
  .block--twitter-feed .view-footer p {
    font-size: 0.8em;
    font-weight: 700; }

.region--pre-header {
  border-bottom: #cccccc 1px solid; }
  .region--pre-header .region-inner {
    font-size: .7em; }

@media (min-width: 760px) {
  .region--pre-header .region-inner {
    font-size: .5em; } }

@media (min-width: 980px) {
  .region--pre-header .region-inner {
    font-size: .7em; } }

.region--post-content-second {
  padding-top: 3em; }
  .region--post-content-second::after {
    clear: both;
    content: "";
    display: table; }

.region--post-content-third {
  padding-top: 3em;
  padding-bottom: 1em;
  background-color: #084983; }

.region--post-content-fourth {
  padding-top: 3em;
  background-color: #ffffff; }

.front .region--post-content-fourth {
  padding-top: 0; }

.region--footer-first {
  padding-top: 3em;
  padding-bottom: 3em;
  border-bottom: 1px solid #ffffff; }
  .region--footer-first .container > div:nth-child(4n+5) {
    clear: left; }

.region--footer-second {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  color: #ffffff; }
  .region--footer-second a.social-icon.social-icon-link.ext {
    position: relative;
    float: left;
    width: 50px;
    height: 50px;
    margin-right: 0.46875em;
    margin-bottom: 0.46875em;
    padding: 0.69em;
    background-color: #ffffff; }
  .region--footer-second #block-block-7 p:nth-of-type(2) {
    display: inline-flex; }

.region--breadcrumb,
.region--easy_breadcrumb {
  position: relative;
  padding-top: 1em;
  padding-bottom: 1em;
  font-weight: 300;
  text-transform: lowercase;
  color: #ffffff;
  background-color: #0a9178;
  background-size: cover; }
  .region--breadcrumb a,
  .region--easy_breadcrumb a {
    margin-bottom: 0;
    color: #ffffff; }
  .region--breadcrumb .breadcrumb,
  .region--easy_breadcrumb .breadcrumb {
    font-size: 1.2em; }
  body[class*="path-events"] .region--breadcrumb, body[class*="path-events"]
  .region--easy_breadcrumb {
    background-image: none; }
  body[class*="path-publications"] .region--breadcrumb, body[class*="path-publications"]
  .region--easy_breadcrumb {
    background-color: #1e97ad;
    background-image: none; }
  body[class*="path-news"] .region--breadcrumb, body[class*="path-news"]
  .region--easy_breadcrumb {
    background-color: #0a9178;
    background-image: none; }
  body[class*="path-careers"] .region--breadcrumb, body[class*="path-careers"]
  .region--easy_breadcrumb {
    background-color: #084983;
    background-image: none; }
  body[class*="path-about-us"] .region--breadcrumb, body[class*="path-about-us"]
  .region--easy_breadcrumb {
    background-color: #0a9178;
    background-image: none; }
  body[class*="path-public-information"] .region--breadcrumb, body[class*="path-public-information"]
  .region--easy_breadcrumb {
    background-color: #0a9178;
    background-image: none; }

@media (min-width: 760px) {
  .region--breadcrumb,
  .region--easy_breadcrumb {
    z-index: 51; } }

.region--post-content {
  clear: both; }

.view-upcoming-events {
  position: relative;
  padding-bottom: 3em; }
  .view-upcoming-events::after {
    clear: both;
    content: "";
    display: table; }
  .view-upcoming-events .view-content {
    padding-left: 1em;
    padding-right: 1em; }
    .view-upcoming-events .view-content p {
      font-weight: 300; }
    .view-upcoming-events .view-content h3.title {
      font-weight: 400; }
    .view-upcoming-events .view-content img {
      display: block; }
    .view-upcoming-events .view-content .field-name-field-date,
    .view-upcoming-events .view-content .field-name-field-event-date,
    .view-upcoming-events .view-content .field-name-field-location-summary,
    .view-upcoming-events .view-content .field-name-field-region {
      font-weight: 300; }
      .view-upcoming-events .view-content .field-name-field-date:before,
      .view-upcoming-events .view-content .field-name-field-event-date:before,
      .view-upcoming-events .view-content .field-name-field-location-summary:before,
      .view-upcoming-events .view-content .field-name-field-region:before {
        font-family: fontAwesome;
        display: block;
        float: left;
        width: 1.5em;
        margin-right: 1em;
        text-align: center; }
    .view-upcoming-events .view-content .comments {
      text-align: right; }
    .view-upcoming-events .view-content .field-name-field-event-date:before {
      content: "\f073"; }
    .view-upcoming-events .view-content .field-name-field-location-summary:before {
      content: "\f041"; }
    .view-upcoming-events .view-content .field-name-field-region:before {
      content: "\f041";
      font-family: fontAwesome; }
    .view-upcoming-events .view-content .views-row {
      margin-bottom: .5em;
      padding-top: 1em;
      padding-bottom: 1em;
      padding-bottom: .5em; }
      .view-upcoming-events .view-content .views-row::after {
        clear: both;
        content: "";
        display: table; }
      .view-upcoming-events .view-content .views-row.last {
        border-bottom: 0; }
    .view-upcoming-events .view-content .views-field-view-node {
      font-size: 0.9em; }
    .view-upcoming-events .view-content .views-field-profile-image-url {
      float: left;
      width: 3em; }
    .view-upcoming-events .view-content .tweet {
      float: right;
      width: calc(100% - 4em);
      font-size: .9em; }
      .view-upcoming-events .view-content .tweet a::after {
        display: none; }
      .view-upcoming-events .view-content .tweet p:not(.title) {
        font-weight: 400; }
  .view-upcoming-events .view-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: .5em;
    padding-bottom: 1em; }
    .view-upcoming-events .view-footer p {
      font-size: 0.8em;
      font-weight: 700; }
  .view-upcoming-events.view-display-id-homepage .field-type-text-with-summary {
    display: none; }
  .view-upcoming-events.view-display-id-homepage .views-row-1 .field-type-text-with-summary {
    display: block; }
  .view-upcoming-events.view-display-id-event_page .view-content, .view-upcoming-events.view-display-id-past_events .view-content, .view-upcoming-events.view-display-id-group_events .view-content {
    padding-left: 0;
    padding-right: 0; }
  .view-upcoming-events.view-display-id-event_page .views-field-title h3, .view-upcoming-events.view-display-id-past_events .views-field-title h3, .view-upcoming-events.view-display-id-group_events .views-field-title h3 {
    margin: 0;
    background-color: #0a9178; }
  .view-upcoming-events.view-display-id-event_page .views-field-title a, .view-upcoming-events.view-display-id-past_events .views-field-title a, .view-upcoming-events.view-display-id-group_events .views-field-title a {
    display: block;
    padding: 0.5em;
    color: #ffffff;
    font-weight: 700; }
  .view-upcoming-events.view-display-id-event_page .views-row, .view-upcoming-events.view-display-id-past_events .views-row, .view-upcoming-events.view-display-id-group_events .views-row {
    margin-bottom: 1em; }
  .view-upcoming-events.view-display-id-event_page .field-name-field-location-summary, .view-upcoming-events.view-display-id-past_events .field-name-field-location-summary, .view-upcoming-events.view-display-id-group_events .field-name-field-location-summary {
    margin-bottom: 1em; }
  .view-upcoming-events.view-display-id-event_page .view-footer, .view-upcoming-events.view-display-id-past_events .view-footer, .view-upcoming-events.view-display-id-group_events .view-footer {
    padding: 0; }
  .view-upcoming-events.view-display-id-event_page .public-engagement-event .views-field-title h3 a, .view-upcoming-events.view-display-id-past_events .public-engagement-event .views-field-title h3 a, .view-upcoming-events.view-display-id-group_events .public-engagement-event .views-field-title h3 a {
    background-color: #0a9178; }
  .view-upcoming-events.view-display-id-event_page .public-engagement-event :before, .view-upcoming-events.view-display-id-past_events .public-engagement-event :before, .view-upcoming-events.view-display-id-group_events .public-engagement-event :before {
    color: #0a9178; }
  .view-upcoming-events.view-display-id-event_page .bsi-event .views-field-title h3 a, .view-upcoming-events.view-display-id-past_events .bsi-event .views-field-title h3 a, .view-upcoming-events.view-display-id-group_events .bsi-event .views-field-title h3 a {
    background-color: #0a9178; }
  .view-upcoming-events.view-display-id-event_page .bsi-event :before, .view-upcoming-events.view-display-id-past_events .bsi-event :before, .view-upcoming-events.view-display-id-group_events .bsi-event :before {
    color: #0a9178; }
  .view-upcoming-events.view-display-id-event_page .training-course .views-field-title h3 a, .view-upcoming-events.view-display-id-past_events .training-course .views-field-title h3 a, .view-upcoming-events.view-display-id-group_events .training-course .views-field-title h3 a {
    background-color: #0a9178; }
  .view-upcoming-events.view-display-id-event_page .training-course :before, .view-upcoming-events.view-display-id-past_events .training-course :before, .view-upcoming-events.view-display-id-group_events .training-course :before {
    color: #0a9178; }
  .view-upcoming-events.view-display-id-event_page .external-event .views-field-title h3 a, .view-upcoming-events.view-display-id-past_events .external-event .views-field-title h3 a, .view-upcoming-events.view-display-id-group_events .external-event .views-field-title h3 a {
    background-color: #0a9178; }
  .view-upcoming-events.view-display-id-event_page .external-event :before, .view-upcoming-events.view-display-id-past_events .external-event :before, .view-upcoming-events.view-display-id-group_events .external-event :before {
    color: #0a9178; }
  .view-upcoming-events.view-display-id-event_page .regional-and-affinity-event .views-field-title h3 a, .view-upcoming-events.view-display-id-past_events .regional-and-affinity-event .views-field-title h3 a, .view-upcoming-events.view-display-id-group_events .regional-and-affinity-event .views-field-title h3 a {
    background-color: #0a9178; }
  .view-upcoming-events.view-display-id-event_page .regional-and-affinity-event :before, .view-upcoming-events.view-display-id-past_events .regional-and-affinity-event :before, .view-upcoming-events.view-display-id-group_events .regional-and-affinity-event :before {
    color: #0a9178; }
  .view-upcoming-events.view-display-id-featured_events a {
    padding-top: 0.75em;
    padding-bottom: 0.75em;
    position: relative;
    padding-bottom: 3em; }
  .view-upcoming-events.view-display-id-featured_events span, .view-upcoming-events.view-display-id-featured_events a {
    display: block;
    color: #ffffff; }
  .view-upcoming-events.view-display-id-featured_events .view-content {
    padding-left: 0;
    padding-right: 0; }
  .view-upcoming-events.view-display-id-featured_events .field-name-field-image {
    padding-bottom: 1em; }
  .view-upcoming-events.view-display-id-featured_events .title, .view-upcoming-events.view-display-id-featured_events .field-name-field-event-date {
    padding-left: 1em;
    padding-right: 1em; }
  .view-upcoming-events.view-display-id-featured_events .title {
    padding-bottom: 1em; }
  .view-upcoming-events.view-display-id-featured_events .field-name-field-event-date {
    position: absolute;
    bottom: 1em; }
  .view-upcoming-events.view-display-id-featured_events .views-row.public-engagement-event a {
    background-color: #0a9178; }
  .view-upcoming-events.view-display-id-featured_events .views-row.bsi-event a {
    background-color: #0a9178; }
  .view-upcoming-events.view-display-id-featured_events .views-row.training-course a {
    background-color: #0a9178; }
  .view-upcoming-events.view-display-id-featured_events .views-row.external-event a {
    background-color: #0a9178; }
  .view-upcoming-events.view-display-id-featured_events .views-row.regional-and-affinity-event a {
    background-color: #0a9178; }

#views-exposed-form-upcoming-events-event-page .views-exposed-form, #views-exposed-form-upcoming-events-past-events .views-exposed-form {
  background-color: #f2f2f2;
  text-align: center;
  padding: 1em;
  margin-bottom: 2em; }

#views-exposed-form-upcoming-events-event-page .views-exposed-widgets, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets {
  display: inline-block; }
  #views-exposed-form-upcoming-events-event-page .views-exposed-widgets small, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets small {
    display: none; }
  #views-exposed-form-upcoming-events-event-page .views-exposed-widgets input[type="submit"].form-submit, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets input[type="submit"].form-submit {
    background-color: #ac1675;
    width: 100%;
    margin-top: 0;
    height: 35px;
    padding-top: 0.6em; }
  #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .form-type-textfield input, #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .form-type-select select, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .form-type-textfield input, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .form-type-select select {
    width: 100%; }
  #views-exposed-form-upcoming-events-event-page .views-exposed-widgets label, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets label {
    text-align: left; }
  #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-submit-button, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-submit-button {
    margin-top: 0.5em;
    padding: 0;
    position: relative; }
    #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-submit-button:after, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-submit-button:after {
      content: "\f002";
      font-family: fontAwesome;
      color: #ffffff;
      position: absolute;
      right: 1em;
      top: 0.4em; }
  #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget {
    padding: 0;
    width: 100%; }
  #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .form-item, #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .date-padding, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .form-item, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .date-padding {
    width: 100%; }

@media (min-width: 450px) and (max-width: 759px) {
  .view-upcoming-events.view-display-id-featured_events a {
    padding-bottom: 1em; }
    .view-upcoming-events.view-display-id-featured_events a::after {
      clear: both;
      content: "";
      display: table; }
  .view-upcoming-events.view-display-id-featured_events .field-name-field-image {
    width: 45%;
    float: left;
    padding-right: 1em;
    padding-left: 1em;
    padding-bottom: 0; }
  .view-upcoming-events.view-display-id-featured_events .title {
    padding-bottom: 1em;
    width: 55%;
    float: left; }
  .view-upcoming-events.view-display-id-featured_events .field-name-field-event-date {
    position: initial;
    bottom: auto;
    float: left;
    width: 55%; } }

@media (min-width: 760px) {
  .view-upcoming-events.view-display-id-featured_events .views-row {
    width: 31.4285714286%;
    float: left; }
    .view-upcoming-events.view-display-id-featured_events .views-row:nth-child(3n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .view-upcoming-events.view-display-id-featured_events .views-row:nth-child(3n + 2) {
      margin-left: 34.2857142857%;
      margin-right: -100%;
      clear: none; }
    .view-upcoming-events.view-display-id-featured_events .views-row:nth-child(3n + 3) {
      margin-left: 68.5714285714%;
      margin-right: -100%;
      clear: none; } }

@media (min-width: 760px) and (max-width: 979px) {
  #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget {
    width: 31.4285714286%;
    float: left; }
    #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget:nth-child(3n + 1), #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget:nth-child(3n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget:nth-child(3n + 2), #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget:nth-child(3n + 2) {
      margin-left: 34.2857142857%;
      margin-right: -100%;
      clear: none; }
    #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget:nth-child(3n + 3), #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget:nth-child(3n + 3) {
      margin-left: 68.5714285714%;
      margin-right: -100%;
      clear: none; }
    #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget.views-submit-button, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget.views-submit-button {
      width: 100%; } }

@media (min-width: 980px) {
  #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget {
    width: 22.8571428571%;
    float: left; }
    #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget:nth-child(4n + 1), #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget:nth-child(4n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget:nth-child(4n + 2), #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget:nth-child(4n + 2) {
      margin-left: 25.7142857143%;
      margin-right: -100%;
      clear: none; }
    #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget:nth-child(4n + 3), #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget:nth-child(4n + 3) {
      margin-left: 51.4285714286%;
      margin-right: -100%;
      clear: none; }
    #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget:nth-child(4n + 4), #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget:nth-child(4n + 4) {
      margin-left: 77.1428571429%;
      margin-right: -100%;
      clear: none; }
    #views-exposed-form-upcoming-events-event-page .views-exposed-widgets .views-exposed-widget.views-submit-button, #views-exposed-form-upcoming-events-past-events .views-exposed-widgets .views-exposed-widget.views-submit-button {
      margin-top: 2em; } }

.block--bitesized-search-exposed {
  padding-top: 3em;
  padding-bottom: 3em;
  background-image: url("../images/headers/sections/bitesized-search.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .block--bitesized-search-exposed label {
    margin-bottom: 0;
    color: #ffffff; }
  .block--bitesized-search-exposed input[type="text"] {
    margin-bottom: 0;
    border: 0;
    background-color: rgba(255, 255, 255, 0.8); }
    .block--bitesized-search-exposed input[type="text"]::-webkit-input-placeholder {
      color: #052e53; }
    .block--bitesized-search-exposed input[type="text"]::-moz-placeholder {
      color: #052e53; }
    .block--bitesized-search-exposed input[type="text"]:-moz-placeholder {
      color: #052e53; }
    .block--bitesized-search-exposed input[type="text"]:-ms-input-placeholder {
      color: #052e53; }
  .block--bitesized-search-exposed input[type="submit"] {
    width: 100%; }
  .block--bitesized-search-exposed .views-exposed-form .views-exposed-widget input.form-submit[type="submit"] {
    margin-top: 0; }
  .block--bitesized-search-exposed .block-inner {
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto; }
    .block--bitesized-search-exposed .block-inner:after {
      content: " ";
      display: block;
      clear: both; }
  .block--bitesized-search-exposed .block-content {
    width: 97.2222222222%;
    float: left;
    margin-left: 1.3888888889%;
    margin-right: 1.3888888889%; }
  .block--bitesized-search-exposed .views-exposed-form {
    padding: 1.5em;
    background-color: rgba(7, 60, 107, 0.8); }
    .block--bitesized-search-exposed .views-exposed-form::after {
      clear: both;
      content: "";
      display: table; }
  .block--bitesized-search-exposed .search-icon {
    line-height: 2.5em;
    color: #ffffff;
    text-align: center; }

@media (min-width: 0) and (max-width: 759px) {
  .block--bitesized-search-exposed label {
    text-align: center;
    margin-bottom: 1em;
    font-size: 1.2em; }
  .block--bitesized-search-exposed input[type="text"] {
    width: 100%;
    margin-bottom: 1.5em; }
  .block--bitesized-search-exposed .views-widget-filter-combine {
    width: 100%;
    padding: 0; }
  .block--bitesized-search-exposed .views-submit-button {
    width: 100%;
    padding: 0; }
  .block--bitesized-search-exposed .content {
    width: 91.6666666667%;
    float: left;
    margin-left: 4.1666666667%;
    margin-right: 4.1666666667%; }
  .block--bitesized-search-exposed .search-icon {
    width: 10%;
    float: left;
    margin-right: 2.8571428571%; } }

@media (min-width: 760px) {
  .block--bitesized-search-exposed {
    padding-top: 8em;
    padding-bottom: 3em; }
    .block--bitesized-search-exposed i {
      display: none; }
    .block--bitesized-search-exposed .content {
      max-width: 60em;
      margin-left: auto;
      margin-right: auto; }
    .block--bitesized-search-exposed .views-exposed-widget {
      width: 65.7142857143%;
      float: left;
      margin-right: 2.8571428571%; }
      .block--bitesized-search-exposed .views-exposed-widget label {
        width: 15.2173913043%;
        float: left;
        margin-right: 4.347826087%;
        text-align: right;
        line-height: 2.5em; }
      .block--bitesized-search-exposed .views-exposed-widget input[type="text"] {
        width: 80.4347826087%;
        float: right;
        margin-right: 0; }
    .block--bitesized-search-exposed .views-submit-button {
      width: 31.4285714286%;
      float: right;
      margin-right: 0;
      text-align: center; }
      .block--bitesized-search-exposed .views-submit-button input {
        max-width: 10em; } }

.view-immunologist-regional-listing-block a {
  color: #084983; }

.view-immunologist-regional-listing-block img {
  display: block; }

.view-immunologist-regional-listing-block .view-header {
  padding: 0.375em;
  padding-left: 1.5em;
  color: #ffffff;
  background-color: #084983; }

.view-immunologist-regional-listing-block .view-content {
  padding: 0.75em;
  background-color: #f2f2f2; }

.view-immunologist-regional-listing-block .views-row {
  display: inline-block;
  margin-bottom: 1.5em; }

.view-immunologist-regional-listing-block .views-field-title {
  margin-bottom: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000; }

.view-immunologist-regional-listing-block .views-field-field-image {
  width: 100px;
  float: left; }

.view-immunologist-regional-listing-block .views-field-field-address,
.view-immunologist-regional-listing-block .views-field-field-tel-number,
.view-immunologist-regional-listing-block .views-field-path {
  float: left;
  width: 100%;
  margin-bottom: 0.375em; }

.view-immunologist-regional-listing-block .views-label {
  font-weight: 600; }

@media (min-width: 980px) {
  .view-immunologist-regional-listing-block {
    width: 48.5714285714%;
    float: right;
    margin-right: 0; } }

.view-article-listing-page img {
  display: block;
  width: 100%;
  height: auto; }

.view-article-listing-page .views-row {
  margin-bottom: 1.5em;
  padding-top: 1.5em;
  border-top: 1px solid #1e97ad; }
  .view-article-listing-page .views-row::after {
    clear: both;
    content: "";
    display: table; }

.view-article-listing-page .field-type-image, .view-article-listing-page .views-field-title {
  padding-bottom: 1em; }

.view-article-listing-page .views-field-view-node a:after {
  font-family: fontAwesome;
  content: "";
  margin-left: 10px; }

@media (min-width: 450px) and (max-width: 759px) {
  .view-article-listing-page .field-type-image {
    width: 48.5714285714%;
    float: right;
    margin-right: 0;
    padding-left: 2em; } }

@media (min-width: 760px) and (max-width: 979px) {
  .view-article-listing-page .field-type-image {
    width: 48.5714285714%;
    float: right;
    margin-right: 0;
    padding-left: 2em; } }

@media (min-width: 980px) {
  .view-article-listing-page .field-type-image {
    width: 31.4285714286%;
    float: right;
    margin-right: 0;
    padding-left: 2em; } }

.block--cta-block-view {
  margin-bottom: 3em;
  padding-top: .5em;
  border-top: 0.5em solid #000000; }
  .block--cta-block-view h2 {
    font-weight: 300; }
  .block--cta-block-view .view-header p,
  .block--cta-block-view .view-header a {
    display: block;
    width: 100%;
    text-transform: uppercase;
    color: #53575d; }
  .block--cta-block-view .view-header a:after {
    float: right;
    content: "\f054";
    font-family: fontAwesome; }
  .block--cta-block-view .view-content {
    padding: .75em; }
    .block--cta-block-view .view-content::after {
      clear: both;
      content: "";
      display: table; }
  .block--cta-block-view .views-field-title:before {
    margin-right: 3em;
    content: "\f054";
    font-family: "FontAwesome"; }
  .block--cta-block-view.white {
    border-top-color: #ffffff; }
    .block--cta-block-view.white .view-content {
      color: #ffffff;
      background-color: #ffffff; }
      .block--cta-block-view.white .view-content a {
        color: #ffffff; }
  .block--cta-block-view.black {
    border-top-color: #000000; }
    .block--cta-block-view.black .view-content {
      color: #ffffff;
      background-color: #000000; }
      .block--cta-block-view.black .view-content a {
        color: #ffffff; }
  .block--cta-block-view.dark-blue {
    border-top-color: #084983; }
    .block--cta-block-view.dark-blue .view-content {
      color: #ffffff;
      background-color: #084983; }
      .block--cta-block-view.dark-blue .view-content a {
        color: #ffffff; }
  .block--cta-block-view.blue {
    border-top-color: #1e97ad; }
    .block--cta-block-view.blue .view-content {
      color: #ffffff;
      background-color: #1e97ad; }
      .block--cta-block-view.blue .view-content a {
        color: #ffffff; }
  .block--cta-block-view.light-blue {
    border-top-color: #66ccff; }
    .block--cta-block-view.light-blue .view-content {
      color: #ffffff;
      background-color: #66ccff; }
      .block--cta-block-view.light-blue .view-content a {
        color: #ffffff; }
  .block--cta-block-view.light-purple {
    border-top-color: #5F65DF; }
    .block--cta-block-view.light-purple .view-content {
      color: #ffffff;
      background-color: #5F65DF; }
      .block--cta-block-view.light-purple .view-content a {
        color: #ffffff; }
  .block--cta-block-view.pastel-blue {
    border-top-color: #b4c8da; }
    .block--cta-block-view.pastel-blue .view-content {
      color: #ffffff;
      background-color: #b4c8da; }
      .block--cta-block-view.pastel-blue .view-content a {
        color: #ffffff; }
  .block--cta-block-view.light-pastel-blue {
    border-top-color: #e6ecf2; }
    .block--cta-block-view.light-pastel-blue .view-content {
      color: #ffffff;
      background-color: #e6ecf2; }
      .block--cta-block-view.light-pastel-blue .view-content a {
        color: #ffffff; }
  .block--cta-block-view.darkest-gray {
    border-top-color: #333333; }
    .block--cta-block-view.darkest-gray .view-content {
      color: #ffffff;
      background-color: #333333; }
      .block--cta-block-view.darkest-gray .view-content a {
        color: #ffffff; }
  .block--cta-block-view.dark-gray {
    border-top-color: #53575d; }
    .block--cta-block-view.dark-gray .view-content {
      color: #ffffff;
      background-color: #53575d; }
      .block--cta-block-view.dark-gray .view-content a {
        color: #ffffff; }
  .block--cta-block-view.medium-gray {
    border-top-color: #666666; }
    .block--cta-block-view.medium-gray .view-content {
      color: #ffffff;
      background-color: #666666; }
      .block--cta-block-view.medium-gray .view-content a {
        color: #ffffff; }
  .block--cta-block-view.light-gray {
    border-top-color: #cccccc; }
    .block--cta-block-view.light-gray .view-content {
      color: #ffffff;
      background-color: #cccccc; }
      .block--cta-block-view.light-gray .view-content a {
        color: #ffffff; }
  .block--cta-block-view.off-white {
    border-top-color: #f2f2f2; }
    .block--cta-block-view.off-white .view-content {
      color: #ffffff;
      background-color: #f2f2f2; }
      .block--cta-block-view.off-white .view-content a {
        color: #ffffff; }
  .block--cta-block-view.pink {
    border-top-color: #ac1675; }
    .block--cta-block-view.pink .view-content {
      color: #ffffff;
      background-color: #ac1675; }
      .block--cta-block-view.pink .view-content a {
        color: #ffffff; }
  .block--cta-block-view.purple {
    border-top-color: #630685; }
    .block--cta-block-view.purple .view-content {
      color: #ffffff;
      background-color: #630685; }
      .block--cta-block-view.purple .view-content a {
        color: #ffffff; }
  .block--cta-block-view.orange {
    border-top-color: #f17f00; }
    .block--cta-block-view.orange .view-content {
      color: #ffffff;
      background-color: #f17f00; }
      .block--cta-block-view.orange .view-content a {
        color: #ffffff; }
  .block--cta-block-view.lightest-gray {
    border-top-color: #f2f2f2; }
    .block--cta-block-view.lightest-gray .view-content {
      color: #ffffff;
      background-color: #f2f2f2; }
      .block--cta-block-view.lightest-gray .view-content a {
        color: #ffffff; }
  .block--cta-block-view.green {
    border-top-color: #0a9178; }
    .block--cta-block-view.green .view-content {
      color: #ffffff;
      background-color: #0a9178; }
      .block--cta-block-view.green .view-content a {
        color: #ffffff; }
  .block--cta-block-view.with-image img {
    float: right;
    width: 40%;
    margin-left: .5em; }
  .block--cta-block-view.title-white .view-header a,
  .block--cta-block-view.title-white .view-header p {
    color: #ffffff; }
  .block--cta-block-view.white-content .view-content {
    padding: 0;
    color: #53575d;
    background-color: #ffffff; }
    .block--cta-block-view.white-content .view-content a {
      color: #53575d; }

@media (min-width: 0) and (max-width: 759px) {
  .block--cta-block.with-image img {
    display: none; } }

.view-bitesize-category-article-listing img {
  display: block;
  width: 100%;
  height: auto; }

.view-bitesize-category-article-listing .views-row {
  position: relative;
  margin-bottom: 7em; }

.view-bitesize-category-article-listing .views-field-title {
  position: absolute;
  top: 100%;
  left: 0;
  display: inline-block;
  width: 100%; }
  .view-bitesize-category-article-listing .views-field-title a {
    display: block;
    padding: 1em;
    color: #ffffff;
    font-weight: 400;
    background-color: #0a9178; }

@media (min-width: 450px) and (max-width: 759px) {
  .view-bitesize-category-article-listing .views-row {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%; }
  .view-bitesize-category-article-listing .view-content > div:nth-child(2n+2) {
    width: 48.5714285714%;
    float: right;
    margin-right: 0; } }

@media (min-width: 760px) and (max-width: 979px) {
  .view-bitesize-category-article-listing .views-row {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%; }
  .view-bitesize-category-article-listing .view-content > div:nth-child(2n+2) {
    width: 48.5714285714%;
    float: right;
    margin-right: 0; } }

@media (min-width: 980px) {
  .view-bitesize-category-article-listing .views-row {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%; }
  .view-bitesize-category-article-listing .view-content > div:nth-child(3n+3) {
    width: 31.4285714286%;
    float: right;
    margin-right: 0; } }

.view-latest-reviews {
  margin-bottom: 3em; }
  .view-latest-reviews span,
  .view-latest-reviews a,
  .view-latest-reviews img {
    display: block; }
  .view-latest-reviews img {
    width: 100%;
    height: auto; }
  .view-latest-reviews .view-reviews {
    position: relative; }
    .view-latest-reviews .view-reviews::after {
      clear: both;
      content: "";
      display: table; }
  .view-latest-reviews .view-content::after {
    clear: both;
    content: "";
    display: table; }
  .view-latest-reviews .view-content .views-row {
    margin-bottom: 1em; }
    .view-latest-reviews .view-content .views-row .image-wide {
      display: block; }
    .view-latest-reviews .view-content .views-row .image-narrow {
      display: none; }
    .view-latest-reviews .view-content .views-row .body-text {
      display: none; }
    .view-latest-reviews .view-content .views-row.views-row-first .image-wide {
      display: none; }
    .view-latest-reviews .view-content .views-row.views-row-first .image-narrow {
      display: block; }
    .view-latest-reviews .view-content .views-row.views-row-first .body-text {
      display: block; }
    .view-latest-reviews .view-content .views-row.views-row-first .image-text {
      position: relative; }
    .view-latest-reviews .view-content .views-row.views-row-first .views-field-path {
      border-bottom: none; }
      .view-latest-reviews .view-content .views-row.views-row-first .views-field-path a {
        color: #53575d; }
  .view-latest-reviews .attachment .view-content .views-row-first .image-wide {
    display: block; }
  .view-latest-reviews .attachment .view-content .views-row-first .image-narrow {
    display: none; }
  .view-latest-reviews .attachment .view-content .views-row-first .body-text {
    display: none; }
  .view-latest-reviews .views-row {
    position: relative; }
  .view-latest-reviews .title {
    position: absolute;
    bottom: 0;
    padding: 0.5em;
    box-sizing: border-box;
    color: #ffffff;
    font-weight: 700; }

@media (min-width: 0px) and (max-width: 500px) {
  .view-latest-reviews .views-row {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
    .view-latest-reviews .views-row.views-row-first .body-text {
      width: 100%;
      float: right;
      margin-right: 0;
      margin-top: 1em; } }

@media (min-width: 501px) and (max-width: 1200px) {
  .view-latest-reviews .views-row {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%; }
    .view-latest-reviews .views-row.views-row-first {
      width: 100%;
      float: right;
      margin-right: 0; }
      .view-latest-reviews .views-row.views-row-first .image-text {
        width: 48.5714285714%;
        float: left;
        margin-right: 2.8571428571%; }
      .view-latest-reviews .views-row.views-row-first .body-text {
        width: 48.5714285714%;
        float: right;
        margin-right: 0; }
  .view-latest-reviews .view-content > div:nth-child(2n+2) {
    width: 48.5714285714%;
    float: right;
    margin-right: 0; }
  .view-latest-reviews .attachment-after .views-row-first {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%; }
    .view-latest-reviews .attachment-after .views-row-first .image-text {
      width: 100%; } }

@media (min-width: 1200px) {
  .view-latest-reviews .view-content {
    width: 100%;
    float: right;
    margin-right: 0; }
    .view-latest-reviews .view-content .views-row {
      width: 36.6197183099%;
      float: left;
      margin-right: 1.4084507042%; }
      .view-latest-reviews .view-content .views-row.views-row-first {
        width: 23.9436619718%;
        float: left;
        margin-right: 1.4084507042%; }
        .view-latest-reviews .view-content .views-row.views-row-first .views-field-path {
          border-bottom: none; }
        .view-latest-reviews .view-content .views-row.views-row-first .body-text {
          position: absolute;
          padding: 1em;
          padding-bottom: 3em;
          border: 1px solid #1e97ad;
          border-top: none; }
        .view-latest-reviews .view-content .views-row.views-row-first .image-text {
          position: relative;
          border: 1px solid #1e97ad;
          border-bottom: none; }
      .view-latest-reviews .view-content .views-row.views-row-last {
        width: 36.6197183099%;
        float: right;
        margin-right: 0; }
  .view-latest-reviews .attachment-after {
    width: 74.6478873239%;
    float: right;
    margin-right: 0; }
    .view-latest-reviews .attachment-after .views-row {
      width: 49.2957746479%;
      float: left;
      margin-right: 1.4084507042%; }
      .view-latest-reviews .attachment-after .views-row.views-row-first {
        width: 49.2957746479%;
        float: left;
        margin-right: 1.4084507042%; }
        .view-latest-reviews .attachment-after .views-row.views-row-first .image-text {
          border: none; }
    .view-latest-reviews .attachment-after .view-content > div:nth-child(3), .view-latest-reviews .attachment-after .view-content > div:nth-child(4) {
      margin-bottom: 0; }
    .view-latest-reviews .attachment-after .view-content > div:nth-child(2n+2) {
      width: 49.2957746479%;
      float: right;
      margin-right: 0; } }

.view-search .views-row {
  padding-bottom: 0.5em;
  padding-top: 0.5em;
  border-bottom: 1px solid #53575d; }

.view-search .views-row:last-child {
  border-bottom: none;
  padding-bottom: 0; }

.view-people-gird {
  display: block;
  margin-top: 1em;
  text-align: center; }
  .view-people-gird .views-exposed-form {
    margin: 0 auto;
    margin-bottom: 1em;
    background-color: rgba(30, 151, 173, 0.25);
    padding: 1rem; }
    .view-people-gird .views-exposed-form .views-exposed-widget .form-submit {
      margin-top: 1.85em; }
  .view-people-gird .views-row {
    margin-bottom: 1.5em; }
  .view-people-gird .field-name-field-image {
    width: 70%;
    margin: 0 auto; }
    .view-people-gird .field-name-field-image img {
      width: 100%;
      height: auto; }
  .view-people-gird .views-field-title {
    font-weight: 700; }
  .view-people-gird .field-name-field-email {
    font-size: 0.8em; }

@media (min-width: 760px) {
  .view-people-gird .views-row {
    width: 31.4285714286%;
    float: left;
    margin-right: 2.8571428571%;
    min-height: 300px; }
  .view-people-gird .view-content > div:nth-child(3n+3) {
    width: 31.4285714286%;
    float: right;
    margin-right: 0; } }

@media (min-width: 450px) and (max-width: 759px) {
  .view-people-gird .views-row {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%; }
  .view-people-gird .view-content > div:nth-child(2n+2) {
    width: 48.5714285714%;
    float: right;
    margin-right: 0; } }

.view-bsi-jobs .block-title {
  font-weight: 700; }

.view-bsi-jobs .view-content > div:last-child {
  border-bottom: none; }

.view-bsi-jobs .views-row {
  border-bottom: 1px solid #53575d;
  padding-bottom: 1em;
  padding-top: 1em; }

.view-bsi-jobs .institution,
.view-bsi-jobs .external-link,
.view-bsi-jobs .deadline {
  font-weight: 600; }
  .view-bsi-jobs .institution > div,
  .view-bsi-jobs .external-link > div,
  .view-bsi-jobs .deadline > div {
    display: inline;
    font-weight: normal; }

.view-bsi-jobs .views-field-title {
  font-weight: 700;
  font-size: 1.2em; }

.view-immunologist-details span {
  font-weight: 700; }

.view-immunologist-details .field-name-field-address p {
  margin: 0; }

.view-immunologist-details .field {
  margin-bottom: 1em; }

.view-immunologist-details .field-name-field-image {
  border: 1px solid #53575d; }
  .view-immunologist-details .field-name-field-image img {
    display: block;
    width: 100%;
    height: auto; }

.view-bitesize-category-site-map-page h3 {
  margin: 0 0 0.25em; }

.view-bitesize-category-site-map-page .group-container {
  margin-bottom: 2em;
  text-align: center; }
  .view-bitesize-category-site-map-page .group-container::after {
    clear: both;
    content: "";
    display: table; }

@media (min-width: 760px) {
  .view-bitesize-category-site-map-page .group-container {
    text-align: left; }
    .view-bitesize-category-site-map-page .group-container > div:nth-child(2n+1) {
      padding-right: 0;
      padding-left: 1em;
      clear: none; }
  .view-bitesize-category-site-map-page .scoop {
    clear: both;
    padding-right: 1em;
    float: left;
    width: 50%; } }

.view-display-id-previous_immunology_news img {
  width: 100%;
  height: auto; }

.view-display-id-previous_immunology_news .views-row {
  position: relative;
  margin-bottom: 3em; }
  .view-display-id-previous_immunology_news .views-row::after {
    clear: both;
    content: "";
    display: table; }
  .view-display-id-previous_immunology_news .views-row > div:not(.views-field-title):not(.field-name-body) {
    padding: 0.5em;
    color: white; }
    .view-display-id-previous_immunology_news .views-row > div:not(.views-field-title):not(.field-name-body) a {
      color: white; }

.view-display-id-previous_immunology_news .views-field-title {
  padding-top: 0.1em;
  padding-bottom: 1em;
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 300;
  background-color: #ffffff;
  color: #53575d; }

.view-display-id-previous_immunology_news .views-field-field-image {
  float: right;
  width: 100%; }

.view-display-id-previous_immunology_news .field-name-body {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 2.25em;
  color: white; }
  .view-display-id-previous_immunology_news .field-name-body a {
    color: white; }

.view-display-id-previous_immunology_news .views-field-view-node {
  position: absolute;
  bottom: 0;
  padding: 0.5em; }
  .view-display-id-previous_immunology_news .views-field-view-node a:after {
    font-family: fontAwesome;
    content: "";
    margin-left: 10px; }

.view-display-id-previous_immunology_news .views-row.white {
  background-color: #ffffff;
  border-top: 0.5em solid #ffffff; }

.view-display-id-previous_immunology_news .views-row.black {
  background-color: #000000;
  border-top: 0.5em solid #000000; }

.view-display-id-previous_immunology_news .views-row.dark-blue {
  background-color: #084983;
  border-top: 0.5em solid #084983; }

.view-display-id-previous_immunology_news .views-row.blue {
  background-color: #1e97ad;
  border-top: 0.5em solid #1e97ad; }

.view-display-id-previous_immunology_news .views-row.light-blue {
  background-color: #66ccff;
  border-top: 0.5em solid #66ccff; }

.view-display-id-previous_immunology_news .views-row.light-purple {
  background-color: #5F65DF;
  border-top: 0.5em solid #5F65DF; }

.view-display-id-previous_immunology_news .views-row.pastel-blue {
  background-color: #b4c8da;
  border-top: 0.5em solid #b4c8da; }

.view-display-id-previous_immunology_news .views-row.light-pastel-blue {
  background-color: #e6ecf2;
  border-top: 0.5em solid #e6ecf2; }

.view-display-id-previous_immunology_news .views-row.darkest-gray {
  background-color: #333333;
  border-top: 0.5em solid #333333; }

.view-display-id-previous_immunology_news .views-row.dark-gray {
  background-color: #53575d;
  border-top: 0.5em solid #53575d; }

.view-display-id-previous_immunology_news .views-row.medium-gray {
  background-color: #666666;
  border-top: 0.5em solid #666666; }

.view-display-id-previous_immunology_news .views-row.light-gray {
  background-color: #cccccc;
  border-top: 0.5em solid #cccccc; }

.view-display-id-previous_immunology_news .views-row.off-white {
  background-color: #f2f2f2;
  border-top: 0.5em solid #f2f2f2; }

.view-display-id-previous_immunology_news .views-row.pink {
  background-color: #ac1675;
  border-top: 0.5em solid #ac1675; }

.view-display-id-previous_immunology_news .views-row.purple {
  background-color: #630685;
  border-top: 0.5em solid #630685; }

.view-display-id-previous_immunology_news .views-row.orange {
  background-color: #f17f00;
  border-top: 0.5em solid #f17f00; }

.view-display-id-previous_immunology_news .views-row.lightest-gray {
  background-color: #f2f2f2;
  border-top: 0.5em solid #f2f2f2; }

.view-display-id-previous_immunology_news .views-row.green {
  background-color: #0a9178;
  border-top: 0.5em solid #0a9178; }

@media (min-width: 450px) and (max-width: 759px) {
  .view-display-id-previous_immunology_news .views-field-field-image {
    width: 30%; } }

@media (min-width: 760px) {
  .view-display-id-previous_immunology_news .views-field-field-image {
    width: 30%; } }

@media (min-width: 980px) {
  .view-display-id-previous_immunology_news .views-field-field-image {
    width: 20%; } }

.view-immunology-in-60-days .views-row {
  width: 100%;
  margin-bottom: 0.5em;
  position: relative; }
  .view-immunology-in-60-days .views-row img {
    display: block;
    height: auto;
    padding-right: 0.5em; }

.view-immunology-in-60-days .not-active > div:first-child {
  width: 30%; }

.view-immunology-in-60-days .not-active .tint {
  width: calc(30% - 0.5em); }

.view-immunology-in-60-days a {
  display: block; }
  .view-immunology-in-60-days a::after {
    clear: both;
    content: "";
    display: table; }
  .view-immunology-in-60-days a > div:first-child {
    width: 30%;
    float: left; }

.view-immunology-in-60-days .tint {
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.view-immunology-in-60-days .views-field-title {
  display: block;
  padding: 0.5em;
  color: #53575d; }

.view-immunology-in-60-days .count {
  width: 2em;
  height: 2em;
  background: #084983;
  -moz-border-radius: 1.5em;
  -webkit-border-radius: 1.5em;
  border-radius: 1.5em;
  display: block;
  color: #ffffff;
  text-align: center;
  padding-top: 0.5em;
  font-weight: 700;
  line-height: 1em;
  position: absolute;
  top: 0.75em;
  left: 0.75em;
  z-index: 2; }

@media (min-width: 450px) {
  .view-immunology-in-60-days a > div:first-child {
    width: 100%; }
  .view-immunology-in-60-days .views-row img {
    padding-right: 0;
    width: 100%; }
  .view-immunology-in-60-days .not-active > div:first-child {
    width: 100%; }
  .view-immunology-in-60-days .not-active .tint {
    width: auto; }
  .view-immunology-in-60-days .views-field-title {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #ffffff; } }

@media (min-width: 450px) and (max-width: 759px) {
  .view-immunology-in-60-days .views-row {
    width: 49.1525423729%;
    float: left; }
    .view-immunology-in-60-days .views-row:nth-child(2n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .view-immunology-in-60-days .views-row:nth-child(2n + 2) {
      margin-left: 50.8474576271%;
      margin-right: -100%;
      clear: none; } }

@media (min-width: 760px) {
  .view-immunology-in-60-days .views-row {
    width: 32.0754716981%;
    float: left; }
    .view-immunology-in-60-days .views-row:nth-child(3n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .view-immunology-in-60-days .views-row:nth-child(3n + 2) {
      margin-left: 33.9622641509%;
      margin-right: -100%;
      clear: none; }
    .view-immunology-in-60-days .views-row:nth-child(3n + 3) {
      margin-left: 67.9245283019%;
      margin-right: -100%;
      clear: none; } }

@media (min-width: 980px) {
  .view-immunology-in-60-days .views-row {
    width: 18.6440677966%;
    float: left; }
    .view-immunology-in-60-days .views-row:nth-child(5n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .view-immunology-in-60-days .views-row:nth-child(5n + 2) {
      margin-left: 20.3389830508%;
      margin-right: -100%;
      clear: none; }
    .view-immunology-in-60-days .views-row:nth-child(5n + 3) {
      margin-left: 40.6779661017%;
      margin-right: -100%;
      clear: none; }
    .view-immunology-in-60-days .views-row:nth-child(5n + 4) {
      margin-left: 61.0169491525%;
      margin-right: -100%;
      clear: none; }
    .view-immunology-in-60-days .views-row:nth-child(5n + 5) {
      margin-left: 81.3559322034%;
      margin-right: -100%;
      clear: none; } }

.view-our-people .view-filters {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between; }

.view-our-people .views-exposed-form label {
  text-align: left; }

@media (min-width: 0) and (max-width: 449px) {
  .view-our-people .views-exposed-widget {
    width: 100%; }
    .view-our-people .views-exposed-widget select {
      width: 100%; }
    .view-our-people .views-exposed-widget .form-submit {
      width: 100%; } }

.paragraphs-item-image-and-text {
  position: relative;
  margin-bottom: 3em;
  padding-top: 0.75em;
  border-top: 0.25em solid #f17f00; }
  .paragraphs-item-image-and-text a {
    color: #084983; }
  .paragraphs-item-image-and-text img {
    display: block;
    height: auto; }
  .paragraphs-item-image-and-text .fieldset-wrapper {
    position: relative; }
  .paragraphs-item-image-and-text .field-name-field-image {
    float: left;
    margin-right: 1.5em;
    margin-bottom: 1em; }
  .paragraphs-item-image-and-text .field-name-field-paragraph-title {
    text-transform: uppercase;
    color: #000000; }
  .paragraphs-item-image-and-text .field-name-field-paragraph-body {
    padding-bottom: 1.5em; }
  .paragraphs-item-image-and-text .field-name-field-paragraph-link {
    position: absolute;
    bottom: 0; }
    .paragraphs-item-image-and-text .field-name-field-paragraph-link a:after {
      content: "\f054";
      font-family: fontAwesome;
      margin-left: 0.5em; }

@media (min-width: 0) and (max-width: 759px) {
  .paragraphs-item-image-and-text .field-name-field-image {
    width: 30%; } }

@media (min-width: 980px) {
  .one-sidebar .field-type-paragraphs > .field-items > .paragraphs-item-image-and-text {
    width: 48.0769230769%;
    float: left; }
    .one-sidebar .field-type-paragraphs > .field-items > .paragraphs-item-image-and-text:nth-child(2n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .one-sidebar .field-type-paragraphs > .field-items > .paragraphs-item-image-and-text:nth-child(2n + 2) {
      margin-left: 51.9230769231%;
      margin-right: -100%;
      clear: none; }
  .two-sidebars .field-type-paragraphs > .field-items > .paragraphs-item-image-and-text {
    width: 47.0588235294%;
    float: left; }
    .two-sidebars .field-type-paragraphs > .field-items > .paragraphs-item-image-and-text:nth-child(2n + 1) {
      margin-left: 0;
      margin-right: -100%;
      clear: both;
      margin-left: 0; }
    .two-sidebars .field-type-paragraphs > .field-items > .paragraphs-item-image-and-text:nth-child(2n + 2) {
      margin-left: 52.9411764706%;
      margin-right: -100%;
      clear: none; } }

.paragraphs-item-image-and-text-second {
  position: relative;
  margin-bottom: 1.5em;
  color: #ffffff; }
  .paragraphs-item-image-and-text-second img {
    display: block;
    width: 100%;
    height: auto; }
  .paragraphs-item-image-and-text-second .field-name-field-paragraph-title {
    padding-top: 0.1em;
    padding-bottom: 1em;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: 300;
    background-color: #ffffff;
    color: #53575d; }
  .paragraphs-item-image-and-text-second .field-type-image {
    width: 27.5862068966%;
    float: right;
    margin-right: 0;
    padding: 0.5em; }
  .paragraphs-item-image-and-text-second .field-name-field-paragraph-body {
    margin: 0.5em;
    padding-bottom: 2em; }
  .paragraphs-item-image-and-text-second .field-name-field-paragraph-link {
    position: absolute;
    bottom: 0;
    padding: 0.5em; }
    .paragraphs-item-image-and-text-second .field-name-field-paragraph-link a {
      color: #ffffff; }
      .paragraphs-item-image-and-text-second .field-name-field-paragraph-link a:after {
        content: "\f054";
        margin-left: 0.5em;
        font-family: "FontAwesome"; }
  .paragraphs-item-image-and-text-second.bsi-colours-off-white, .paragraphs-item-image-and-text-second.bsi-colours-white, .paragraphs-item-image-and-text-second.bsi-colours-lightest-gray, .paragraphs-item-image-and-text-second.bsi-colours-light-pastel-blue {
    color: #000000; }
  .paragraphs-item-image-and-text-second.without-text .content {
    padding-bottom: 2.5em; }
  .paragraphs-item-image-and-text-second.half-width.odd {
    width: 48.5714285714%;
    float: left;
    margin-right: 2.8571428571%; }
  .paragraphs-item-image-and-text-second.half-width.even {
    width: 48.5714285714%;
    float: right;
    margin-right: 0; }
  .paragraphs-item-image-and-text-second:not(.half-width) {
    width: 100%;
    float: left;
    margin-left: 0;
    margin-right: 0; }
  .paragraphs-item-image-and-text-second.bsi-colours-white {
    background-color: #ffffff;
    border-top: 0.5em solid #ffffff; }
  .paragraphs-item-image-and-text-second.bsi-colours-black {
    background-color: #000000;
    border-top: 0.5em solid #000000; }
  .paragraphs-item-image-and-text-second.bsi-colours-dark-blue {
    background-color: #084983;
    border-top: 0.5em solid #084983; }
  .paragraphs-item-image-and-text-second.bsi-colours-blue {
    background-color: #1e97ad;
    border-top: 0.5em solid #1e97ad; }
  .paragraphs-item-image-and-text-second.bsi-colours-light-blue {
    background-color: #66ccff;
    border-top: 0.5em solid #66ccff; }
  .paragraphs-item-image-and-text-second.bsi-colours-light-purple {
    background-color: #5F65DF;
    border-top: 0.5em solid #5F65DF; }
  .paragraphs-item-image-and-text-second.bsi-colours-pastel-blue {
    background-color: #b4c8da;
    border-top: 0.5em solid #b4c8da; }
  .paragraphs-item-image-and-text-second.bsi-colours-light-pastel-blue {
    background-color: #e6ecf2;
    border-top: 0.5em solid #e6ecf2; }
  .paragraphs-item-image-and-text-second.bsi-colours-darkest-gray {
    background-color: #333333;
    border-top: 0.5em solid #333333; }
  .paragraphs-item-image-and-text-second.bsi-colours-dark-gray {
    background-color: #53575d;
    border-top: 0.5em solid #53575d; }
  .paragraphs-item-image-and-text-second.bsi-colours-medium-gray {
    background-color: #666666;
    border-top: 0.5em solid #666666; }
  .paragraphs-item-image-and-text-second.bsi-colours-light-gray {
    background-color: #cccccc;
    border-top: 0.5em solid #cccccc; }
  .paragraphs-item-image-and-text-second.bsi-colours-off-white {
    background-color: #f2f2f2;
    border-top: 0.5em solid #f2f2f2; }
  .paragraphs-item-image-and-text-second.bsi-colours-pink {
    background-color: #ac1675;
    border-top: 0.5em solid #ac1675; }
  .paragraphs-item-image-and-text-second.bsi-colours-purple {
    background-color: #630685;
    border-top: 0.5em solid #630685; }
  .paragraphs-item-image-and-text-second.bsi-colours-orange {
    background-color: #f17f00;
    border-top: 0.5em solid #f17f00; }
  .paragraphs-item-image-and-text-second.bsi-colours-lightest-gray {
    background-color: #f2f2f2;
    border-top: 0.5em solid #f2f2f2; }
  .paragraphs-item-image-and-text-second.bsi-colours-green {
    background-color: #0a9178;
    border-top: 0.5em solid #0a9178; }

@media (min-width: 0) and (max-width: 449px) {
  .paragraphs-item-image-and-text-second {
    width: 100%;
    float: right;
    margin-right: 0; }
    .paragraphs-item-image-and-text-second.half-width.odd {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    .paragraphs-item-image-and-text-second.half-width.even {
      width: 100%;
      float: left;
      margin-left: 0;
      margin-right: 0; }
    .paragraphs-item-image-and-text-second .field-type-image {
      width: 100%;
      float: right;
      margin-right: 0; } }

@media (min-width: 450px) and (max-width: 759px) {
  .paragraphs-item-image-and-text-second.half-width .field-type-image {
    width: 100%;
    float: right;
    margin-right: 0; }
  .paragraphs-item-image-and-text-second:not(.half-width) .field-type-image {
    width: 30%; } }

@media (min-width: 760px) and (max-width: 979px) {
  .paragraphs-item-image-and-text-second.half-width .field-type-image {
    width: 40%; }
  .paragraphs-item-image-and-text-second:not(.half-width) .field-type-image {
    width: 30%; } }

@media (min-width: 980px) {
  .paragraphs-item-image-and-text-second.half-width .field-type-image {
    width: 40%; }
  .paragraphs-item-image-and-text-second:not(.half-width) .field-type-image {
    width: 20%; } }

.paragraphs-item-text-and-title {
  clear: both;
  margin-bottom: 1.5em;
  width: 100%;
  margin-top: 0.75em; }
  .paragraphs-item-text-and-title .field-name-field-paragraph-title {
    font-size: 1.8020324707em;
    font-weight: 600; }

.node-type-page .paragraphs-items {
  margin-top: 1.5em; }

.content-container {
  margin-bottom: 1.5em; }

.node-bitesize-article .field-name-field-file-upload {
  margin-bottom: 1em; }

.node-bitesize-article .field-name-field-author {
  margin-bottom: 1em;
  font-weight: 700; }

.webform-client-form fieldset legend {
  margin: 0.75em 0; }

.webform-client-form fieldset legend span {
  font-size: 1.423828125em; }

.webform-client-form .webform-component-select {
  width: 100%; }

.webform-client-form .fieldset-wrapper > .form-item {
  padding-bottom: 0.5em;
  margin-bottom: 1.25em; }
  .webform-client-form .fieldset-wrapper > .form-item .webform-container-inline::after {
    clear: both;
    content: "";
    display: table; }
  .webform-client-form .fieldset-wrapper > .form-item .webform-container-inline div {
    float: left; }
  .webform-client-form .fieldset-wrapper > .form-item select, .webform-client-form .fieldset-wrapper > .form-item input {
    margin-bottom: 0; }
  .webform-client-form .fieldset-wrapper > .form-item select {
    min-width: 100%; }
  .webform-client-form .fieldset-wrapper > .form-item .form-type-select {
    margin-right: .5em; }
  .webform-client-form .fieldset-wrapper > .form-item .webform-calendar {
    margin-top: 10px; }

.webform-client-form .form-type-radio input, .webform-client-form .form-checkboxes input {
  float: left;
  margin-top: 0.3em; }

@media (min-width: 760px) {
  .path-public-information-bitesized-immunology .fluid-width-video-wrapper {
    width: 50%;
    float: right;
    padding-top: 0 !important; }
    .path-public-information-bitesized-immunology .fluid-width-video-wrapper iframe {
      padding-left: 1em;
      height: 350px; }
    .path-public-information-bitesized-immunology .fluid-width-video-wrapper iframe {
      position: relative; } }

@media (min-width: 980px) {
  .path-public-information-bitesized-immunology .fluid-width-video-wrapper {
    width: 40%; } }

.page-user.logged-in h1.main-page-title:before {
  content: "Welcome "; }

.page-user.logged-in h1.main-page-title:after {
  content: "!"; }

.page-events .region--pre-title h2.block-title {
  font-size: 1.8020324707em;
  font-weight: 600; }

.node-event img {
  display: block; }

.node-event iframe {
  width: 100%;
  margin-bottom: 1.5em; }

.node-event .field-name-field-image {
  padding-top: 0.5em;
  margin-bottom: 2em; }

.node-event .field-name-field-location-summary:before, .node-event .field-name-field-event-date:before {
  font-family: fontAwesome;
  display: block;
  float: left;
  margin-right: 1em;
  width: 15px;
  text-align: center; }

.node-event .field-name-field-event-date {
  margin-bottom: 1em; }
  .node-event .field-name-field-event-date:before {
    content: "\f073"; }

.node-event .field-name-field-location-summary:before {
  content: "\f041"; }

.node-event .field-name-body {
  margin-bottom: 1.5em; }

.node-event[class*="public-engagement-event"] .field-name-field-image {
  background-color: #66ccff; }

.node-event[class*="public-engagement-event"] :before {
  color: #66ccff; }

.node-event[class*="bsi-event"] .field-name-field-image {
  background-color: #ac1675; }

.node-event[class*="bsi-event"] :before {
  color: #ac1675; }

.node-event[class*="training-course"] .field-name-field-image {
  background-color: #630685; }

.node-event[class*="training-course"] :before {
  color: #630685; }

.node-event[class*="external-event"] .field-name-field-image {
  background-color: #f17f00; }

.node-event[class*="external-event"] :before {
  color: #f17f00; }

.node-event[class*="regional-and-affinity-event"] .field-name-field-image {
  background-color: #0a9178; }

.node-event[class*="regional-and-affinity-event"] :before {
  color: #0a9178; }

.node-staff-profile .left-col {
  margin-bottom: 1rem; }
  .node-staff-profile .left-col .field-name-field-job-title {
    margin-bottom: 0.75em; }
  .node-staff-profile .left-col .member-profile-email {
    padding: 0.25em 0.5em;
    background-color: #0a9178;
    color: #ffffff;
    display: block;
    max-width: 240px;
    text-align: center; }

@media (min-width: 760px) {
  .node-staff-profile .left-col {
    width: 22.8571428571%;
    float: left;
    margin-right: 2.8571428571%; }
  .node-staff-profile .right-col {
    width: 74.2857142857%;
    float: right;
    margin-right: 0; } }

.node-type-60-day-tile .field-name-field-image {
  margin-bottom: 1.5em; }
  .node-type-60-day-tile .field-name-field-image img {
    width: 65%;
    margin: 0 auto;
    display: block; }

@media (min-width: 760px) {
  .node-type-60-day-tile .field-name-field-image {
    width: 22.8571428571%;
    float: left;
    margin-right: 2.8571428571%; }
    .node-type-60-day-tile .field-name-field-image img {
      width: 100%; }
  .node-type-60-day-tile .field-name-body {
    width: 74.2857142857%;
    float: right;
    margin-right: 0; } }

.node-immunology-group {
  padding-bottom: 3em; }

.path-news-bsi-blog #main-content {
  position: relative; }

.webform-progressbar {
  display: none; }

.form-item.webform-component.webform-component-textfield.webform-component--civicrm-1-contact-1-cg9-custom-107 {
  padding: 2em 0 2em 0;
  border-bottom: 5px solid #2ca48b;
  margin-bottom: 2em; }

.block--direct-debit {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 150px; }

.path-direct-debit-membership-form #main-content {
  position: relative; }

.path-node-1054-done #main-content {
  position: relative; }

.direct-debit-logo {
  position: absolute;
  right: 0;
  top: 0;
  max-width: 200px; }

.direct-debit-details {
  position: relative;
  margin: 4em 0 4em 0em;
  padding: 0 1em 0 1em; }
  .direct-debit-details a {
    text-decoration: underline; }

.direct-debit-guarantee {
  background-color: #daf5ef;
  padding: 0 1em 1.5em 1em; }

.direct-debit-checks-container {
  background-color: #daf5ef;
  font-weight: 400;
  border-top: 5px solid #2ca48b;
  border-bottom: 5px solid #2ca48b; }

.direct-debit-checks {
  padding: 1em 4em 1em 4em; }

.direct-debit-checks input[type="checkbox"] {
  margin-left: -1.5em; }

.multiple-person {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  font-weight: 600; }

.direct-debit-next {
  padding-top: 1.5em;
  padding-bottom: 1.5em; }
  .direct-debit-next input.webform-previous.form-submit {
    display: none; }

.account-holder-confirm {
  padding: 1.5em 0 1.5em 0;
  font-weight: 600; }

.direct-debit-next input[type="submit"] {
  border-radius: 5px;
  background-color: #2ca48b; }

.direct-debit-confirm input[type="submit"] {
  border-radius: 5px;
  background-color: #999999;
  margin-bottom: 1em; }

.direct-debit-confirm input {
  margin-right: 1em; }

.direct-debit-confirm input:after {
  content: '>'; }

.direct-debit-confirm input.webform-next.button-primary.form-submit {
  background-color: #2ca48b; }

.direct-debit-confirm input.webform-submit.button-primary.form-submit {
  background-color: #2ca48b; }

.direct-debit-check-success {
  display: none;
  background-color: #9AE570;
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 6px; }

.direct-debit-check-failed {
  display: none;
  background-color: #FF9494;
  text-align: center;
  color: white;
  font-weight: bold;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 6px; }

.form-item.webform-component.webform-component-markup.webform-component--check-your-details {
  border-bottom: 5px solid #2ca48b;
  margin-bottom: 2em; }

.form-item.webform-component.webform-component-markup.webform-component--thank-you {
  padding: 2em 0 1em 0;
  border-bottom: 5px solid #2ca48b;
  margin-bottom: 2em; }

.check-details-title {
  font-weight: 600;
  margin-bottom: 0.8em; }

.check-details-value {
  color: grey;
  padding-left: 1em;
  margin-bottom: 1em;
  font-weight: 600;
  letter-spacing: 2px; }

.check-details-container {
  margin-bottom: 3em; }

p.bank-statement {
  margin-top: 2em; }

.form-item.webform-component.webform-component-markup.webform-component--thank-you-message {
  margin-bottom: 3em; }

.direct-debit-complete {
  margin-bottom: 2em; }
  .direct-debit-complete h2 {
    padding: 2em 0 1em 0;
    border-bottom: 5px solid #2ca48b;
    margin-bottom: 2em; }

.page-search404 figure {
  margin: auto; }

.page-search404 hgroup {
  text-align: center; }

.page-search404 article {
  width: 97.2222222222%;
  float: left;
  margin-left: 1.3888888889%;
  margin-right: 1.3888888889%;
  text-align: center; }
  .page-search404 article ul {
    list-style-type: none;
    padding-left: 0; }
