// _body.scss

.main-content {
  padding-top: 3em;
  padding-bottom: 3em;
}

.front .main-content {
	padding-bottom: 0;
}

body.hide-page-title h1.main-page-title {
  display: none;
}
