// _lists.scss

.clean-list {
  @include clean-list;
}

.double-spaced-list {

  li {
    margin-bottom: $small-spacing;
  }
}