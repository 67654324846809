.view-bitesize-category-site-map-page {
	h3 {
		margin: 0 0 0.25em;
	}
	.group-container {
		@include clearfix;
		margin-bottom: 2em;
		text-align: center;
	}
}
@include susy-breakpoint($tablet) {
	.view-bitesize-category-site-map-page {
		.group-container {
			text-align: left;
			>div:nth-child(2n+1) {
			    padding-right: 0;
			    padding-left: 1em;
			    clear:none;
			}
		}
		.scoop {
			clear: both;
			padding-right: 1em;
			float: left;
		    width: 50%;
		}
	}
}