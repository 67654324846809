// _block--footer-menu.scss

.block--footer-menu {
  margin-bottom: 2em;
  @include span(4 of 4);
  text-align: center;
  h2.block-title {
    color: $medium-gray;
    font-weight: $fw--bold;
  }
  .menu {
    @include clean-list;
    
    li {
      margin-bottom: $small-spacing / 2;
      font-size: .9em;
    }

    a {
      color: $white;
    }
  }
}


@include susy-breakpoint($med-mobile) {
  .block--footer-menu {

  }
}
@include susy-breakpoint($tablet) {
  .block--footer-menu {
    text-align: left;
    .menu li {
      font-size: 1em;
    }
  }
}
@include susy-breakpoint($tablet-only) {
  .block--footer-menu {
    @include gallery(6 of 12);
  }
}
@include susy-breakpoint($desktop) {
  .block--footer-menu {
    @include gallery(3 of 12);
  }
}