.block--post-navigation {
	display: block;
	ul.menu {
		position: relative;
	}
	ul.menu li.first {
		margin-top: 1rem;
	}
}

@include susy-breakpoint($tablet) {
	.block--post-navigation {
		display: none;
	}
}