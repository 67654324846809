$lighten: 50%;
$img-width: 30%;

.block--latest-news {
	position: relative;
	margin-bottom: 3em;
	@include clearfix;
	img {
		width:100%;
		height:auto;
		display: block;
	}
	.block-title {
		margin-bottom: 2em;
		text-transform: uppercase;
	}
	.views-field-title {
		a {
			color:$black;
		}
	}
	.field-name-field-date {
		margin-bottom: 1em;
	}
	//colours
	&.group { //Three grouped views with rows going from top to bottom (society, int, blog)
		padding-top: .7em;
		.view-content {
			position: relative;
			display: inline-block;
			padding-bottom: 4em;

			> div:first-child {

				.field-type-image {
					display: block;
				}
				.field-name-body {
					display: block;
				}
			}
		}
		.view-footer {
			p {
				margin: 0;
			}
			position: absolute;
			bottom: 1em;
		}
		.views-row-first {
			padding-top: .75em;
			.field-type-image {
				display: block;
			}

		}
		.views-row, .view-footer {
			width: calc(100% - #{$img-width} );
		}
		.field-type-image {
			width: $img-width;
			position: absolute;
			top: .75em;
			height: calc(100% - 1.5em);
			overflow: hidden;
			display: none;
		}
		.field-name-body {
			display: none;
		}
		&.left { // positioning of the image, only aplicable to grouped views.

			.field-type-image {
				left: .75em;
			}
			.views-row,.view-footer {
				float:right;
				padding-left:.75em;
				padding-right: .75em;
				padding-left: 1.5em;
			}
			.view-footer {
				right:0;
			}
		}

		&.right {  // positioning of the image, only aplicable to grouped views.

			.field-type-image {
				right: .75em;
			}
			.views-row, .view-footer {
				float: left;
				padding-left:.75em;
				padding-right: .75em;
				padding-right: 1.5em;
			}
			.view-footer {
				left: 0;
			}

		}
	}
	&.separate { //Single news view with rows from left to right (on the top of the page)
		.views-row {
			padding-top: .7em;
			@include span(2 of 6 after);
		}
		.views-row-first{
			@include span(2 of 6 first after);
		}
		.views-row-last{
			@include span(2 of 6 last after);
		}		

		.view-content {
			background-color: transparent;
		}

	}
}

@each $colour, $hex in $colours { // looping through colours in grouped views
  .block--latest-news.group.#{$colour} {
    border-top: .5em solid $hex;
    &.background {    
      .block-content {
        background-color: lighten($hex, $lighten);
      }
    }
  }
}

@each $colour, $hex in $colours { // looping through colours in separate views
  .block--latest-news.separate.#{$colour} {
  	.views-row {
  		border-top: .5em solid $hex;
  	}	
  }
}

@include susy-breakpoint($mobile-only) {
	.block--latest-news {
		&.separate {
			.views-row, 
			.views-row-last, 
			.views-row-last  {
				width:100%;
				margin-bottom: 3em;
				.field-name-field-image {
					@include span (12 of 12 after);
					background-color: red;
				}
			}
		}
		&.group {
			.view-content >div:first-child {
				.field-name-field-image {
					display: none;
				}
			}
			.views-row {
				width:100%;
				padding-left: .75em;
				padding-right: .75em;
			}
			.field-name-field-image {
				display: none;
			}
		}
	}
}