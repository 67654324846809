figure {
  margin: 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
  height: auto;
}

@include susy-breakpoint($tablet) {
  img {

    &.img--left {
      float: left;
      max-width: 500px;
      margin-right: 1em;
      margin-bottom: 1em;
    }

    &.img--right {
      float: right;
      max-width: 500px;
      margin-left: 1em;
      margin-bottom: 1em;
    }
  }
}

.intro-image {
  margin-bottom: 1em;
}


// Spacing around images and captions.
.content p img {
  margin-bottom: 1em;
  margin-top: 1em;
}

.content p img[style*="float:left"] {
  margin-right: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
}

.content p img[style*="float:right"] {
  margin-left: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
}

.content figure[style*="float:left"] {
  margin-right: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
}

.content figure[style*="float:right"] {
  margin-left: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
}

.content figure[style*="display:inline-block"] {
  margin-bottom: 1em;
  margin-top: 1em;

  figcaption {
    display: inherit;
    margin-top: 0;
  }

}

// Center and limit caption width to containing image.
//
figcaption {
  display: table-caption;
  caption-side: bottom;
  text-align: center;
  font-size: small;
  font-style: italic;
  margin-top: -2em;
}

figure {
  display: table;
}

