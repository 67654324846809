table {
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: auto;
  width: 100%;
}

th {
  border-bottom: 1px solid shade($base-border-color, 25%);
  font-weight: 600;
  padding: $small-spacing 0;
  text-align: left;
}

tr,
td,
th {
  vertical-align: middle;
  padding: 0.5em;
  border: 1px solid $base-border-color;
}


td {
  border-bottom: $base-border;
  vertical-align: top;
}