.view-latest-reviews {
  margin-bottom: 3em;

  span,
  a,
  img {
    display: block;
  }

  img {
    width: 100%;
    height: auto;
  }

  .view-reviews {
    @include clearfix;
    position: relative;
  }

  .view-content {
    @include clearfix;

    .views-row {
      margin-bottom: 1em;

      .image-wide {
        display: block;
      }

      .image-narrow {
        display: none;
      }

      .body-text {
        display: none;
      }
      &.views-row-first {

        .image-wide {
          display: none;
        }

        .image-narrow {
          display: block;
        }

        .body-text {
          display: block;
        }

        .image-text {
          position: relative;
        }

        .views-field-path {
          border-bottom: none;

          a {
            color: $base-font-color;
          }
        }
      }
    }
  }
  .attachment {
    .view-content {
      .views-row-first {
        .image-wide {
          display: block;
        }
        .image-narrow {
          display: none;
        }
        .body-text {
          display: none;
        }
      }
    }
  }

  .views-row {
    position: relative;
  }

  .title {
    position: absolute;
    bottom: 0;
    padding: 0.5em;
    box-sizing:border-box;
    color: $white;
    font-weight: $fw--bold;
  }
}

@include susy-breakpoint(0px 500px) {
  .view-latest-reviews {
    .views-row {
      @include span(12 of 12 after);
      &.views-row-first {
        .body-text {
          @include span(12 of 12 last after);
          margin-top: 1em;
        }
      }
    }
  }
}
@include susy-breakpoint(501px 1200px) {
  .view-latest-reviews {
    .views-row {
      @include span(6 of 12 after);
      &.views-row-first {
        @include span(12 of 12 last after);
        .image-text {
          @include span(6 of 12 after);
        }
        .body-text {
          @include span(6 of 12 last after);
        }
      }
    }
    .view-content >div:nth-child(2n+2) {
      @include span(6 of 12 last after);
    }
    .attachment-after {

      .views-row-first {
        @include span(6 of 12 after);
        .image-text {
          width:100%;

        }
      }
    }

  }
}

@include susy-breakpoint(1200px) {
  .view-latest-reviews {
    .view-content {
      @include span(12 of 12 last after);

      .views-row {
        @include span(9 of 24 after);

        &.views-row-first {

         @include span(6 of 24 first after);
         .views-field-path {
          border-bottom: none;
         }
         .body-text {
          position: absolute;
          padding:1em;
          padding-bottom: 3em;

          border:1px solid $blue;
          border-top: none;
         }
         .image-text {
          position: relative;
          border:1px solid $blue;
          border-bottom: none;
         }
        }
        &.views-row-last {
          @include span(9 of 24 last after);
        }
      }
    }

    .attachment-after {
      @include span(18 of 24 last after);

      .views-row {
        @include span(12 of 24 after);
        &.views-row-first {
          @include span(12 of 24 after);
          .image-text {
            border:none;
          }
        }
      }
      .view-content {
        >div:nth-child(3), >div:nth-child(4) {
          margin-bottom: 0;
        }
      }

      .view-content>div:nth-child(2n+2) {
        @include span(12 of 24 last after);
      }
    }
  }
}
