// _region--footer-first.scss

.region--footer-first {
  padding-top: 3em;
  padding-bottom: 3em;
  border-bottom: 1px solid $white;
  .container {
  	>div:nth-child(4n+5) {
  		clear: left;
  	}
  }
}