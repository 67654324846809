.view-our-people {
	
	.view-filters {
		display: flex;
	    flex-wrap: wrap;
	    flex-direction: row;
	    justify-content: space-between;
	}

	.views-exposed-form {
		label {
			text-align: left;
		}
	}
}

@include susy-breakpoint($small-mobile) { 
	.view-our-people {
		.views-exposed-widget  {
			width: 100%;

			select {
				width: 100%;
			}

			.form-submit {
				width: 100%;
			}
		}
	}
}