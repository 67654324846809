//_image-and-text-second.scss
.paragraphs-item-image-and-text-second {
	position: relative;
  	margin-bottom: $base-spacing;
  	color: $white;

	img {
		display: block;
    	width: 100%;
    	height: auto;
	}

	.field-name-field-paragraph-title {
		padding-top: 0.1em;
		padding-bottom: 1em;
		text-transform: uppercase;
		font-size: 1.5em;
		font-weight: $fw--light;
		background-color: $white;
		color: $dark-gray;
	}

	.field-type-image {
		@include span(3 of 10 last after);
		padding: 0.5em;
	}

	.field-name-field-paragraph-body {
		margin: 0.5em;
		padding-bottom: 2em;
	}

	.field-name-field-paragraph-link {
    	position: absolute;
   		bottom: 0;
		padding: 0.5em;

		a {
			color: $white;
	  		&:after {
	  			content: "\f054";
	  			margin-left: 0.5em;
	        	font-family: "FontAwesome";
  			}
		}
	}

	&.bsi-colours-off-white,
	&.bsi-colours-white,
	&.bsi-colours-lightest-gray,
	&.bsi-colours-light-pastel-blue {
		color: $black;
	}
	&.without-text {
		.content {
			padding-bottom: 2.5em;
		}
	}
	&.half-width {

		&.odd {
      @include span(6 of 12 after);
    }

		&.even{
      @include span(6 of 12 last after);
    }
	}

	&:not(.half-width) {
		@include span(12 of 12 after);
	}

	//Background colours
	@each $colour, $hex in $colours { // looping through colours
	  &.bsi-colours-#{$colour} {
	    background-color: $hex;
	    border-top: 0.5em solid $hex;
	  }
	}
}

@include susy-breakpoint($small-mobile) {
	.paragraphs-item-image-and-text-second {
		@include span(5 of 5 last after);

    &.half-width {

      &.odd {
        @include span(12 of 12 after);
      }
      &.even {
        @include span(12 of 12 after);
      }
    }

		.field-type-image {
			@include span(5 of 5 last after);
		}
	}
}

@include susy-breakpoint($med-mobile) {

	.paragraphs-item-image-and-text-second {

		&.half-width .field-type-image {
			@include span(5 of 5 last after);
		}

		&:not(.half-width) .field-type-image {
			width: 30%;
		}
	}
}
@include susy-breakpoint($tablet-only) {
	.paragraphs-item-image-and-text-second {

		&.half-width .field-type-image {
			width: 40%;
		}

		&:not(.half-width) .field-type-image {
			width: 30%;
		}
	}
}

@include susy-breakpoint($desktop) {
	.paragraphs-item-image-and-text-second {

		&.half-width .field-type-image {
			width:40%;
		}

		&:not(.half-width) .field-type-image {
			width:20%;
		}
	}
}
