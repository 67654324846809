// _region--breadcrumb.scss

.region--breadcrumb,
.region--easy_breadcrumb {
  position: relative;
  padding-top: 1em;
  padding-bottom: 1em;
  font-weight: $fw--light;
  text-transform: lowercase; // just 'cos
  color: $white;
  background-color: $green;
  //background-image: url('../images/patterns/news-pattern.png');
  background-size: cover;
  

  a {
    margin-bottom: 0;
    color: $white;
  }

  .breadcrumb {
    font-size: 1.2em;
  }
////background colours ////
  body[class*="path-events"] & { 
    //background-color: $pink;
    background-image: none;
  }          
  body[class*="path-publications"] & { 
    background-color: $blue;
    background-image: none;
  }          
  body[class*="path-news"] & { 
    background-color: $green;
    background-image: none;
  }          
  body[class*="path-careers"] & { 
    background-color: $dark-blue;
    background-image: none;
  }          
  body[class*="path-about-us"] & { 
    background-color: $green;
    background-image: none;
  }          
  body[class*="path-public-information"] & { 
    background-color: $green;
    background-image: none;
  }
}
@include susy-breakpoint ($tablet) {
  .region--breadcrumb,
  .region--easy_breadcrumb {
    z-index: 51;
  }
}
