.block--homepage-news-v2 {
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: $dark-blue;
  background-image: url('../images/patterns/news-pattern.png');
  background-size: cover;

  .block-content {
    @include container;
  }

  .views-row {
    padding-top: .75em;
    border-top: .5em solid $light-blue;
    text-transform: uppercase;
    @include span(4 of 4 (1/2));
    margin-bottom: 2em;
    
    h2 {
      min-height: 2em;
      line-height: 0.75em;  
    }
    h2 a {
      font-size: 0.75em;
      font-weight: $fw--normal;
      color: $white;
    } 
  }
}

@include susy-breakpoint($tablet) {
  .block--homepage-news-v2 {

    .view-homepage-news {
      position: relative;
    }

    .views-row {
      @include gallery(6 of 12 after);
    }
  }
}

@include susy-breakpoint($desktop) {
  .block--homepage-news-v2 {

    .view-homepage-news {
      position: relative;
    }

    .views-row {
      @include gallery(4 of 12 after);
    }
  }
}