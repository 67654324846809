#{$all-buttons}, .button { //Anchors and buttons
  appearance: none;
  background-color: $action-color;
  border: 0;
  border-radius: $base-border-radius;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: $small-spacing $base-spacing;
  text-decoration: none;
  transition: background-color $base-duration $base-timing;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  &:hover,
  &:focus {
    background-color: shade($action-color, 20%);
    color: #fff;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      background-color: $action-color;
    }
  }
}

@each $colour, $hex in $colours { //For the anchors 
  .button.#{$colour}{
    background-color: $hex;

    &:hover,
    &:focus {
      background-color: shade($hex, 20%);
    }
  }
}
@each $icon, $value in $icons {
  .button.#{$icon}{
    @include icon("\f054");
  }
}
@each $colour, $hex in $colours { //For the buttons
  button.#{$colour}{
    background-color: $hex;

    &:hover,
    &:focus {
      background-color: shade($hex, 20%);
    }
  }
}
@each $icon, $value in $icons {
  button.#{$icon}{
    @include icon("\f054");
  }
}