//_view-immunologist-regional-listing.scss

.view-immunologist-regional-listing-block {

	a {
		color: $dark-blue;
	}

	img {
		display: block;
	}

	.view-header {
		padding: $base-spacing / 4;
		padding-left: $base-spacing;
		color: $white;
		background-color: $dark-blue;
	}

	.view-content {
		padding: $base-spacing / 2;
		background-color: $lightest-gray;
	}

	.views-row {
		display: inline-block;
    	margin-bottom: $base-spacing;
	}

	.views-field-title {
		margin-bottom: $base-spacing;
		font-weight: $fw--semi-bold;
		text-transform: uppercase;
		color: $black;
	}

	.views-field-field-image {
		width: 100px;
		float: left;
	}

	.views-field-field-address,
  	.views-field-field-tel-number,
  	.views-field-path {
		float: left;
		width:100%;
    	margin-bottom: $base-spacing / 4;
	}

	.views-label {
		font-weight: $fw--semi-bold;
	}
}
@include susy-breakpoint($desktop) {
	.view-immunologist-regional-listing-block {
		@include span(6 of 12 last after);
	}
}
