.webform-client-form {
	fieldset {
		legend {
			margin:0.75em 0;
		}

		legend span {
			font-size: modular-scale(3);
		}
	}
	.webform-component-select {
		width: 100%;
	}
	.fieldset-wrapper >	.form-item {
		padding-bottom: 0.5em;
		margin-bottom: 1.25em;

		.webform-container-inline {
			@include clearfix;
			div {
				float: left;
			}
		}

		select, input{
			margin-bottom:0;
		}
		select {
			min-width:100%;

		}

		.form-type-select {
			margin-right: .5em;
		}
		.webform-calendar {
			margin-top: 10px;
		}
	}

	.form-type-radio, .form-checkboxes {
		input {
			float: left;
			margin-top: 0.3em;
		}
	}
}


