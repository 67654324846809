html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  @include container;
}

.clearfix {
  @include clearfix;
}

// Any desktop spanned objects go full width
// unless overriden with the m-span-* classes below
@include susy-breakpoint($mobile-only) {
  [class^="span-"] {
    @include span(4 of 4 (1/2));
  }

  // Mobile grid
  @for $i from 1 through 4 {
    .m-span-#{$i} {
      @include span($i of 4 (1/2));
    }
  }
}


// Tablet and Desktop grid
@include susy-breakpoint($tablet) {
  @for $i from 1 through 12 {
    .d-span-#{$i} {
      @include span($i of 12);
    }
  }
  @for $i from 1 through 12 {
    .span-#{$i} {
      @include span($i of 12);
    }
  }
  @for $i from 1 through 12 {
    .d-span-n-#{$i} {
      @include span($i of 12 after);
    }
  }
  @for $i from 1 through 12 {
    .n-span-#{$i} {
      @include span($i of 12 after);
    }
  }

  .span-last {
    margin-right: 0;
  }
  .span-clear {
    clear:left;
  }
}


@include susy-breakpoint($mobile-only) {
  .content-container,
  .region--sidebar-first,
  .region--sidebar-second {
    @include span(4 of 4 (1/2));
  }
}


@include susy-breakpoint($tablet) {
  .content-container {
    @include span(12 of 12);
  }

  .region--sidebar-first,
  .region--sidebar-second {
    @include span(2.75 of 12);
  }

  .one-sidebar .content-container {
    @include span(9 of 12);
  }

  .one-sidebar .region--sidebar-second {
    @include push(.25 of 12);
  }
}