// _view-upcoming-events.scss

.view-upcoming-events {
  position: relative;
  padding-bottom: 3em;
  @include clearfix;
  .view-content {
    padding-left: 1em;
    padding-right: 1em;

    p {
      font-weight: $fw--light;
    }
    h3.title {
      font-weight: $fw--normal;
    }
    img {
      display: block;
    }

    .field-name-field-date,
    .field-name-field-event-date,
    .field-name-field-location-summary,
    .field-name-field-region {
      font-weight: $fw--light;

      &:before {
        font-family:fontAwesome;
        display: block;
        float: left;
        width:1.5em;
        margin-right: 1em;
        text-align: center;
      }
    }

    .comments {
      text-align: right;
    }

    .field-name-field-event-date {
      &:before {
        content:"\f073";
      }
    }    
    .field-name-field-location-summary {
      &:before {
        content:"\f041";
        
      }
    }

    .field-name-field-region {
      &:before {
        content:"\f041";
        font-family:fontAwesome;
      }
    }

    .views-row {
      @include clearfix;
      margin-bottom: .5em;
      padding-top: 1em;
      padding-bottom: 1em;
      padding-bottom: .5em;
      &.last {
        border-bottom: 0;
      }
    }
    .views-field-view-node {
      font-size: 0.9em;
    }
    .views-field-profile-image-url {
      float: left;
      width: 3em;
    }

    .tweet {
      float: right;
      width: calc(100% - 4em);
      font-size: .9em;
      a::after {
          display: none;
      }
      p:not(.title) {
        font-weight: $fw--normal;
      }
    }
  }

  .view-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: .5em;
    padding-bottom: 1em;

    p {
      font-size: 0.8em;
      font-weight: $fw--bold;
    }
  }

    //display type specific

  &.view-display-id-homepage {
      .field-type-text-with-summary {
        display: none;
      }
      .views-row-1 {
        .field-type-text-with-summary {
          display: block;
        }
      }
   }
  &.view-display-id-event_page, &.view-display-id-past_events, &.view-display-id-group_events {
    .view-content {
      padding-left: 0;
      padding-right: 0;
    }
    .views-field-title {
      h3 {
        margin: 0;
        background-color: $green;
      }
      a {
        display: block;
        padding:0.5em;
        color: $white;
        font-weight: $fw--bold;
      }
    }
    .views-row {
      margin-bottom: 1em;
    }
    .field-name-field-location-summary {
      margin-bottom: 1em;
    }
    .view-footer { 
      padding:0;
    }
    @each $event-colour, $hex in $event-colours { // looping through colours
      .#{$event-colour}  {
        .views-field-title h3 a {
          background-color: $green;
        }
        :before {
          color: $green;
        }
      }
    }
  }
  &.view-display-id-featured_events {

    a {
      padding-top: 0.75em;
      padding-bottom: 0.75em;
      position: relative;
      padding-bottom: 3em;
    }
    span, a {
      display: block;
      color: $white;
    }

    .view-content {
      padding-left: 0;
      padding-right: 0;
    }
    .field-name-field-image {
      padding-bottom: 1em;
    }
    .title, .field-name-field-event-date {
      padding-left: 1em;
      padding-right: 1em;
    }
    .title {
      padding-bottom: 1em;
    }
    .field-name-field-event-date { 
      position: absolute;
      bottom: 1em;

    }

    @each $event-colour, $hex in $event-colours { // looping through colours
      .views-row.#{$event-colour}  {
        a {
          background-color: $green;
        }
      }
    }
  }
}

//exposed filters
#views-exposed-form-upcoming-events-event-page, #views-exposed-form-upcoming-events-past-events {
  .views-exposed-form {
    background-color: $lightest-gray;
    text-align: center;
    padding:1em;
    margin-bottom: 2em;
  }
  .views-exposed-widgets {
    display: inline-block;

    small {
      display: none;
    }
    input[type="submit"].form-submit {
      background-color: $pink;
      width:100%;
      margin-top: 0;
      height:35px;
      padding-top: 0.6em;

    }
    .form-type-textfield input, .form-type-select select {
      width:100%;
    }
    label {
      text-align: left;
    }
    .views-submit-button {
      margin-top: 0.5em;
      padding:0;
      position: relative;
      &:after {
        content: "\f002";
        font-family: fontAwesome;
        color: $white;
        position: absolute;
        right: 1em;
        top: 0.4em;
      }
    }
    .views-exposed-widget {
      padding:0;
      width:100%;
    }
    .form-item, .date-padding {
      width:100%;
    }
  }
}

//breakpoints 

@include susy-breakpoint($med-mobile) {
  .view-upcoming-events {
    &.view-display-id-featured_events {
      a {
        @include clearfix;
        padding-bottom: 1em;
      }
      .field-name-field-image {
        width: 45%;
        float: left;
        padding-right: 1em;
        padding-left: 1em;
        padding-bottom: 0;
      }
      .title {
        padding-bottom: 1em;
        width: 55%;
        float: left;
      }
      .field-name-field-event-date {
        position: initial;
        bottom: auto;
        float: left;
        width: 55%;
      }
    }
  }
}

@include susy-breakpoint($tablet) {
  .view-upcoming-events {
    &.view-display-id-featured_events {
      .views-row {
      @include gallery(4 of 12 after);
      }
    }
  }
}
@include susy-breakpoint($tablet-only) {
  #views-exposed-form-upcoming-events-event-page, #views-exposed-form-upcoming-events-past-events { 
    .views-exposed-widgets .views-exposed-widget {
      @include gallery(4 of 12 after);
      &.views-submit-button {
        width:100%;
      }
    }
  }
}
@include susy-breakpoint($desktop) {
  #views-exposed-form-upcoming-events-event-page, #views-exposed-form-upcoming-events-past-events {

    .views-exposed-widgets .views-exposed-widget {
      @include gallery(3 of 12 after);
      &.views-submit-button {
        margin-top: 2em;
      }
    }

  }
}
