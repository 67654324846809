.node-event {
	img {
		display: block;
	}
	iframe {
		width:100%;
		margin-bottom: $base-spacing;
	}
	.field-name-field-image {
		padding-top: 0.5em;
		margin-bottom: 2em;
	}
	.field-name-field-location-summary, .field-name-field-event-date {

		&:before {
			font-family: fontAwesome;
			display: block;
			float: left;
			margin-right: 1em;
			width: 15px;
			text-align: center;
		}
	}

	.field-name-field-event-date {
		margin-bottom: 1em;
		&:before {
			content: "\f073";	
		}
	}
	.field-name-field-location-summary {
		&:before {
			content: "\f041";
		}
	}
	.field-name-body {
		margin-bottom: $base-spacing;
	}
    @each $event-colour, $hex in $event-colours { // looping through colours
      &[class*="#{$event-colour}"]  {
		.field-name-field-image {
			background-color: $hex;
		}
          
        
        :before {
          color: $hex;
        }
      }
    }
}