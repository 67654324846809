// _block--user--logged-out.scss

.block--user--logged-out {
  float: right;

  .menu {
    @include clean-list;
    @include floated-list;

    line-height: 4em;
    font-weight: $fw--semi-bold;
    text-transform: uppercase;

    a {
      display: inline-block;
      width: 100%;
      padding-left: 1em;
      padding-right: 1em;
      text-align: center;
      color: $darkest-gray;

      &:hover {
        color: lighten($darkest-gray, 30%);
      }
    }

    li {
      width: 8em;
      vertical-align: top;

      a {
        background-color: $pastel-blue;
      }

      &.first {
        border-right: 1px solid darken($light-gray, 10%);
      }

      &.first a {
        background-color: $light-pastel-blue;
      }
    }
  }
}