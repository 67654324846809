// _block--homepage-news.scss

// TODO: Add optimisations for landscape viewing

.block--homepage-news {
  padding-top: 3em;
  padding-bottom: 3em;
  background-color: $dark-blue;
  background-image: url('../images/patterns/news-pattern.png');
  background-size: cover;

  .block-content {
    @include container;
  }

  .views-row {
    padding-top: .75em;
    border-top: .5em solid $light-blue;
    text-transform: uppercase;
    
    h2 {
      min-height: 2em;
      line-height: 0.75em;  
    }
    h2 a {
      font-size: 0.75em;
      font-weight: $fw--normal;
      color: $white;
    } 
  }
}


@include susy-breakpoint($mobile-only) {
  .block--homepage-news {

    .views-row {
      @include span(4 of 4 (1/2));
      max-height: 18em;
      margin-bottom: 2em;
      overflow: hidden;
      
      &.views-row-4,
      &.views-row-5,
      &.views-row-6,
      &.views-row-7 {
        @include span(2 of 4 (1/2));
      }
    }
  }
}


// This is where it gets interesting
@include susy-breakpoint($tablet) {
  .block--homepage-news {

    .view-homepage-news {
      position: relative;
    }

    .views-row-1 {
      @include span(4 of 12 after);
    }

    .views-row-2 {
      @include span(3 of 12 after);
    }

    .views-row-3 {
      @include span(5 of 12 after last);
    }

    .views-row-4,
    .views-row-5,
    .views-row-6,
    .views-row-7 {
      @include span(2 of 12 after);
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .views-row-4 {
      right: span(6 after);
    }

    .views-row-5 {
      right: span(4 after);
    }

    .views-row-6 {
      right: span(2 after);
    }

    .views-row-7 {
      margin-right: 0;
    }

  }
}