.block--immunology-news-landing {
	img{
		width:100%;
		height: auto;
	}
	.views-row {
		@include clearfix;
		position: relative;
		margin-bottom: $base-spacing * 2;
		>div:not(.views-field-title):not(.field-name-body) {
			padding: 0.5em;
			color: white;
			a {
				color: white;
			}
		}
	}
	.views-field-title {
		padding-top: 0.1em;
		padding-bottom: 1em;
		text-transform: uppercase;
		font-size: 1.5em;
		font-weight: 300;
		background-color: #ffffff;
		color: #53575d;
	}
	.field-name-field-image {
		float: right;
		width:100%;
	}
	.field-name-body {
		padding-left: 0.5em;
		padding-right: 0.5em;
		padding-top: 0.5em;
		padding-bottom: $base-spacing * 1.5;
		color: white;
		a {
			color: white;
		}
	}
	.views-field-view-node {
		position: absolute;
		bottom: 0;
		padding: 0.5em;
		a {
			@include icon("\f054");
		}
	}
	.attachment-after {
		.view-header {
			font-size: modular-scale(5);
			font-weight: $fw--semi-bold;
			margin-bottom: $base-spacing /2;
		}
	}
}	

//background
@each $colour, $hex in $colours {
	 .block--immunology-news-landing .views-row.#{$colour} {
		background-color:  $hex;
		border-top: 0.5em solid $hex;
	}
}
//breakpoints 

@include susy-breakpoint($med-mobile) {
	.block--immunology-news-landing {
		.field-name-field-image {
			width:30%;
		}
		.attachment-after {
			.field-name-field-image {
				width:100%;
			}
			.views-row-odd {
				@include span(6 of 12 first after);
			}		
			.views-row-even {
				@include span(6 of 12 last after);
			}
		}
	}
}



@include susy-breakpoint($tablet) {
	.block--immunology-news-landing {
		.field-name-field-image {
			width:30%;
		}
		.attachment-after {
			.views-row {
			.field-name-field-image {
					width:40%;
				}
			}
			.views-row-odd {
				@include span(6 of 12 first after);
			}		
			.views-row-even {
				@include span(6 of 12 last after);
			}
		}
	}
}
@include susy-breakpoint($desktop) {
	.block--immunology-news-landing {
		.field-name-field-image {
			width:20%;
		}
	}
}


