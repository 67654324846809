//_view-top-20-articles.scss
.block--cta-block-view {
  margin-bottom: 3em;
  padding-top: .5em;
  border-top: .5em solid $black;
  h2 {
    font-weight: $fw--light; 
  }
  .view-header {
  	p,
    a {
  		display: block;
		  width: 100%;
		  text-transform: uppercase;
  		color:$base-font-color;
  	}

  	a:after {
  		float: right;
  		content:"\f054";
  		font-family: fontAwesome;
  	}
  }

  .view-content {
    @include clearfix;
    padding: .75em;
  }

  .views-field-title:before {
    margin-right: 3em;
    content:"\f054";
    font-family: "FontAwesome";
  }

  @each $colour, $hex in $colours {
    &.#{$colour} {
      border-top-color: $hex;

      .view-content {
        color: $white;
        background-color: $hex;

        a {
          color: $white;
        }
      }
    }
  }

  &.with-image img {
    float: right;
    width: 40%;
    margin-left: .5em;
  }

  &.title-white .view-header {

  	a,
    p {
	    color: $white;
	  }
  }

  &.white-content .view-content {
    padding: 0;
    color: $dark-gray;
    background-color: $white;

    a {
      color: $dark-gray;
    }
  }
}


@include susy-breakpoint($mobile-only) {
  .block--cta-block.with-image img {
    display: none;
  }
}
