// _colours.scss
$fx--red: #ff0000;
$fx--yellow: #fcf500;
$fx--green: #8dc63f;

$white: #ffffff;
$black: #000000;

// The blues
$dark-blue: #084983;
$blue: #1e97ad;
$light-blue: #66ccff;
$pastel-blue: #b4c8da;
$light-pastel-blue: #e6ecf2;

// The greys
$darkest-gray: #333333;
$dark-gray: #53575d;
$medium-gray: #666666;
$light-gray: #cccccc;
$lightest-gray: #f2f2f2;

// The rest
$off-white: #f2f2f2;
$pink: #ac1675;
$purple: #630685;
$light-purple:#5F65DF;
$orange: #f17f00;
$green: #0a9178;


// Map for looping
$colours: (
  'white': $white,
  'black': $black,
  'dark-blue': $dark-blue,
  'blue': $blue,
  'light-blue': $light-blue,
  'light-purple': $light-purple,
  'pastel-blue': $pastel-blue,
  'light-pastel-blue': $light-pastel-blue,
  'darkest-gray': $darkest-gray,
  'dark-gray': $dark-gray,
  'medium-gray': $medium-gray,
  'light-gray': $light-gray,
  'off-white': $off-white,
  'pink': $pink,
  'purple': $purple,
  'orange': $orange,
  'lightest-gray': $lightest-gray,
  'green': $green,
);
$event-colours: (
  'public-engagement-event': $light-blue,
  'bsi-event': $pink,
  'training-course': $purple,
  'external-event': $orange,
  'regional-and-affinity-event': $green,
);

$icons: (
  'chevron-right': "\f054"
);
