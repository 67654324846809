.block--user-page-tiles {
  clear: both;
  	color: white;
  a {
    display: block;

  }
  img {
    display: block;
    width:100%;
    height: auto;
  }
  .views-row {
    @include clearfix;
    padding:0.75em;
    padding-bottom: 4em;
    position: relative;
    margin-bottom: 2em;
  }  
  .field-name-field-image {
  	float: right;
    width:100%;
    padding-bottom: 1em;
  }

  .views-field-title {
    text-transform: uppercase;
    padding-bottom: 1em;
    display: block;
    font-size: 1.4em;
  }
  .field-name-body {
    padding-right:1em;
    
  }
  .views-field-field-external-link-1 {
    position: absolute;
    bottom: 0.75em;
    right:0.75em;
  }

}
///////colours
.block--user-page-tiles {
  clear: both;
  .views-row {
    @each $colour, $hex in $colours { // looping through colours
      &.#{$colour} {
        background-color: $hex;
      }
    }
  }
}

@include susy-breakpoint($med-mobile) {
  .block--user-page-tiles {
    .field-name-field-image {
      width:40%;
      padding-left: 1em;
      padding-bottom: 0;
    }
    .views-field-title, .field-name-body {
      width:60%;
    }
  }

}
@include susy-breakpoint($tablet) {
  .block--user-page-tiles {
    .views-row {
      @include gallery(6 of 12 after);
    } 
    .field-name-field-image {
      width: 40%;
      padding-left: 1em;
      padding-bottom: 0;
    }
    .views-field-title, .field-name-body {
      width:60%;
    }
  }
}

