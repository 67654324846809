// _block--cta-block.scss

.block--cta-block {
  margin-bottom: 3em;
  padding-top: .5em;
  border-top: .5em solid $black;
  .block-title {
    margin-bottom: 2em;
    color: $dark-gray;
    text-transform: uppercase;
    font-weight: $fw--light;
  }

  .block-content {
    @include clearfix;
    padding: .75em;
    
    a {
      &:after {
        margin-left: 0.5em;
        content:"\f054";
        font-family: fontAwesome;
      }
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }
    li {
      margin-bottom: .75em;
    }
  }

  @each $colour, $hex in $colours {
    &.#{$colour}:not(.ligh-backgorund) {
      border-top-color: $hex;
      &:not(.white-content) {
        .block-content {
          color: $white;
          background-color: $hex;

          a {
            color: $white;
          }
        }
      }

    }
    &.#{$colour}.light-background {
      .block-content {
        color: $black;
        background-color: lighten($hex, 50%);

        a {
          color: $black;
        }
      }
    }
  }
  &.with-image img {
    float: right;
    width: 40%;
    margin-right: .5em;
  }

  &.title-white .block-title {
    color: $white;
  }

  &.white-content .block-content {
    padding: 0;
    color: $dark-gray;
    background-color: $white;

    a {
      color: $dark-gray;
    }
  }
}


@include susy-breakpoint($mobile-only) {
  .block--cta-block.with-image img {
    display: none;
  }
}