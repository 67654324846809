// _block--side-block.scss

.block--side-block {
  margin-bottom: 3em;
  padding-top: .5em;
  border-top: .5em solid $green;

  .block-title {
    margin-bottom: 1em;
    color: $dark-gray;
    text-transform: uppercase;
  }
  
  .block-content {

    .menu {
      padding-left: 0;    
      list-style: none;
    } 

    .views-row, li {
      margin-bottom: $small-spacing / 2;
    }

    li {
      ul.menu {
        padding-left: 1em;
      }
    }

    a:not(.button) {
      position: relative;
      display: block;
      color: $dark-gray;
    }

    a:hover,
    a.active-trail {
      background-color: lighten($green, 50%);
    }

  }
  body[class*="path-events"] & { 
    //border-top: .5em solid $pink;
    background-image: none;
  }          
  body[class*="path-publications"] & { 
    border-top: .5em solid $blue;
    background-image: none;
  }          
  body[class*="path-news"] & { 
    border-top: .5em solid $green;
    background-image: none;
  }          
  body[class*="path-careers"] & { 
    border-top: .5em solid $dark-blue;
    background-image: none;
  }          
  body[class*="path-about-us"] & { 
    border-top: .5em solid $green;
    background-image: none;
  }          
  body[class*="path-public-information"] & { 
    border-top: .5em solid $green;
    background-image: none;
  }


}

//Aparently not really wanted anymore :( ///

// @each $colour, $hex in $colours {
//   .block--side-block.#{$colour} {
//     border-top: .5em solid $hex;
//   }
// }