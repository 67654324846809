.view-people-gird {
	display: block;
	margin-top: 1em;
	text-align: center;

	.views-exposed-form {
		margin: 0 auto;
		margin-bottom: 1em;
		background-color: rgba($blue, 0.25);
		padding: 1rem;

		.views-exposed-widget .form-submit {
    		margin-top: 1.85em;
		}
	}

	.views-row {
		margin-bottom: $base-spacing;
	}
	.field-name-field-image {
		width:70%;
		margin: 0 auto;
		img {
			width:100%;
			height: auto;
		}
	}
	.views-field-title {
		font-weight: $fw--bold;
	}
	.field-name-field-email {
		font-size: 0.8em;
	}

}
@include susy-breakpoint($tablet) {
	.view-people-gird {
		.views-row {
			@include span(4 of 12 after);
      min-height: 300px;
		}
		.view-content {
			>div:nth-child(3n+3) {
				@include span(4 of 12 last after);
			}
		}
	}
}
@include susy-breakpoint($med-mobile) {
	.view-people-gird {
		.views-row {
			@include span(6 of 12 after);
		}
		.view-content {
			>div:nth-child(2n+2) {
				@include span(6 of 12 last after);
			}
		}
	}
}
