.node-staff-profile .left-col {
	margin-bottom: 1rem;

.field-name-field-job-title {
 margin-bottom: 0.75em;
  }

  .member-profile-email {
    padding: 0.25em 0.5em;
    background-color: $green;
    color: $white;
    display: block;
    max-width: 240px;
    text-align: center;
  }
}

@include susy-breakpoint($tablet) {
	.node-staff-profile {
		.left-col {
			@include span(3 of 12 after);
		}
		.right-col {
			@include span(9 of 12 after last);
		}
	}
}
