.block--twitter-feed {
  .views-row {
    @include clearfix;
    margin-bottom: .5em;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-bottom: .5em;

    &.last {
      border-bottom: 0;
    }
  }

  .view-content {
    padding: 0;
  }

  ul {
    padding: 0;
    margin-top: 0;
    list-style: none;
  }

  .block-content {
    a:not(.button) {
      position: initial;
      display: initial;
    }

    a:hover {
      background-color: transparent;
    }
  }

  .views-field-view-node {
    font-size: 0.9em;
  }

  .views-field-profile-image-url {
    float: left;
    width: 3em;
  }

  .tweet {
    float: right;
    width: calc(100% - 4em);
    font-size: .9em;

    p.title {
      font-weight: 300;
    }

    a::after {
        display: none;
    }
    p:not(.title) {
      font-weight: $fw--normal;
    }
  }

  .view-footer {
    padding-left: 0;
    padding-right: 0;

    a {
      &:after {
        margin-left: .5em;
        content:"\f054";
        font-family: fontAwesome;
      }
    }

    p {
      font-size: 0.8em;
      font-weight: 700;
    }
  }
}
