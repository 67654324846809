.view-bitesize-category-article-listing {

	img {
		display: block;
    width: 100%;
    height: auto;
	}

  .views-row {
    position: relative;
    margin-bottom: 7em;
  }

  .views-field-title {
    position: absolute;
    top: 100%;
    left: 0;
    display: inline-block;
    width: 100%;

    a {
      display: block;
      padding: 1em;
      color: $white;
      font-weight: $fw--normal;
      background-color: $green;
    }
  }
}
@include susy-breakpoint($med-mobile) {
	.view-bitesize-category-article-listing {
		.views-row {
			@include span(6 of 12 after);
		}
		.view-content > div:nth-child(2n+2) {
			@include span(6 of 12 last after);
		}
	}
}
@include susy-breakpoint($tablet-only) {
	.view-bitesize-category-article-listing {
		.views-row {
			@include span(6 of 12 after);
		}
		.view-content > div:nth-child(2n+2) {
			@include span(6 of 12 last after);
		}
	}
}
@include susy-breakpoint($desktop) {
	.view-bitesize-category-article-listing {
		.views-row {
			@include span(4 of 12 after);

		}
		.view-content > div:nth-child(3n+3) {
			@include span(4 of 12 last after);
		}
	}
}
