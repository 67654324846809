// _region--footer-second.scss

.region--footer-second {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  color: $white;

  a.social-icon.social-icon-link.ext {
	  position: relative;
	  float: left;
	  width: 50px;
	  height: 50px;
	  margin-right: 0.46875em;
	  margin-bottom: 0.46875em;
	  padding: 0.69em;
	  background-color: $white;
	}

	#block-block-7 {
		p:nth-of-type(2) {
		  display: inline-flex;
		}
	} 
}