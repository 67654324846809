@include susy-breakpoint($tablet) {
	.path-public-information-bitesized-immunology {
		.fluid-width-video-wrapper {
			width:50%;
			float: right;
			iframe {
				padding-left: 1em;
				height:350px;
			}
			padding-top: 0 !important; //Sorry, overrides js from a module.
			iframe {
				position: relative;
			}
		}
	}
}
@include susy-breakpoint($desktop) {
	.path-public-information-bitesized-immunology {
		.fluid-width-video-wrapper {
			width:40%;
		}
	}
}
