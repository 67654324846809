.item-list-pager {
	width: 100%;
	text-align: center;

	ul {
		padding-left: 0;
		display: inline-block;
	}

	li {
		float: left;
		display: block;
		margin:0.8em;
		color: $green;

		a {
			color: $black;
		}
	}

	.next,
	.last,
	.previous,
	.first {
		a {
			margin: 0.3em;
			padding: 0.9em;
			padding-top: 0.3em;
			padding-bottom: 0.5em;
			background-color: $green;
			color: $white;
		}
	}
}
@include susy-breakpoint($small-mobile) {
	.item-list-pager {
		li {
			display: none;
    }

		li.previous,
    li.next,
    li.current {
			display: block;

			a {
				margin: 0.6em;
				padding: 1.8em;
				padding-top: 0.6em;
				padding-bottom: 1em;
			}
		}
	}
}
