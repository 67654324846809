//_view-article-listing-page.scss

.view-article-listing-page {

	img {
		display: block;
		width: 100%;
		height: auto;
	}

  .views-row {
    @include clearfix;
    margin-bottom: $base-spacing;
    padding-top: $base-spacing;
    border-top: 1px solid $blue;
  }

  .field-type-image, .views-field-title {
    padding-bottom: 1em;
  }

  .views-field-view-node a{
    @include icon("\f054");
  }
}
@include susy-breakpoint($med-mobile) {
	.view-article-listing-page {
		.field-type-image {
			@include span(6 of 12 last after);
			padding-left: 2em;
		}
	}
}
@include susy-breakpoint($tablet-only) {
	.view-article-listing-page {
		.field-type-image {
			@include span(6 of 12 last after);
			padding-left: 2em;
		}
	}
}
@include susy-breakpoint($desktop) {
	.view-article-listing-page {

		.field-type-image {
			@include span(4 of 12 last after);
			padding-left: 2em;
		}
	}
}
