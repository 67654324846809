// _block--pre-navigation.scss

.block--pre-navigation {
  float: left;
  
  .menu {
    @include clean-list;
    @include floated-list;

    font-weight: $fw--semi-bold;
    text-transform: uppercase;
    line-height: 4em;

    li span {
      display: none;
    }

    a {
      display: inline-block;
      padding-left: 1em;
      padding-right: 1em;
      color: $darkest-gray;

      &:hover {
        color: lighten($darkest-gray, 30%);
      }

      &.active-trail {
        position: relative;
        background-color: lighten($green, 50%);

        &:after {
          position: absolute;
          bottom: 0;
          left: calc(50% - 6px);
          content: "";
          @include triangle(12px, $dark-gray, up);
        }
      }
    }

    li {
      vertical-align: top;
    }

    .first {
      border-left: 1px solid $light-gray;
      border-right: 1px solid $light-gray;
      font-size: 1.75em;
    }

    .first a {
      padding-left: .75em;
      padding-right: .75em;
      background-color: $light-pastel-blue;
      transition: background-color $base-timing $base-duration
                  color $base-duration $base-timing;

      &:hover {
        background-color: $pastel-blue;
        color: $white;
      }
    }
    
  }
}


@include susy-breakpoint($mobile-only) {
  .block--pre-navigation {
    
    .menu li:not(.first) {
      display: none;
    }
  }
}