body {
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;

}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font-family;
  font-size: modular-scale(1);
  font-weight: $fw--semi-bold;
  line-height: $heading-line-height;
  margin: 0 0 $small-spacing;
}


h1 {
  font-size: modular-scale(5);
}
h2 {
  font-size: modular-scale(3);
  padding-top: 1.5em;
  &.block-title {
    font-weight: $fw--light;
  }
  .views-row & {
    padding-top: 0;
  }
  
}
h4 {
  font-size: $base-font-size;
}

p {
  margin: 0 0 $small-spacing;
}

a {
  color: $action-color;
  text-decoration: none;
  transition: color $base-duration $base-timing;

  &:active,
  &:focus,
  &:hover {
    color: shade($action-color, 25%);
  }
}

hr {
  border-bottom: $base-border;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: $base-spacing 0;
}

blockquote {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 1em;
  padding-top: 3em;
  padding-left: 5em;

  &:before,
  &:after {
    display: block;
    font-family: "FontAwesome";
    font-size: 3em;
  }

  &:before {
    content: "\f10d";
    position: absolute;
    top: 0;
    left: 0;
  }

  p:first-of-type:after {
    content: "\f10e";
    font-family: "FontAwesome";
    float: right;
    font-size: 3em;
    margin-top: 0.2em;
  }
}

.drupal-messages {
  margin-top: 1em;
  margin-bottom: 2em;
}

.messages {
  padding: 1em;
  border-left: .5em solid;

  &.status {
    background-color: rgba($fx--green, 0.2);
    border-color: darken($fx--green, 10%);
    color: darken($fx--green, 20%);
  }

  &.warning {
    background-color: rgba($fx--yellow, 0.2);
    border-color: darken($fx--yellow, 10%);
    color: darken($fx--yellow, 20%);
  }

  &.error {
    background-color: rgba($fx--red, 0.2);
    border-color: darken($fx--red, 10%);
    color: darken($fx--red, 20%);
  }
}
