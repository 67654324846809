.view-immunology-in-60-days {
	.view-cotnent {

	}
	.views-row {
		width:100%;
		margin-bottom: 0.5em;
		position: relative;

		img {
			display: block;
			height: auto;
			padding-right: 0.5em;
		}
	}
	.not-active {
		>div:first-child {
			width: 30%;
		}
		.tint {
			width: calc(30% - 0.5em);
		}
	}
	a {
		@include clearfix;
		display: block;
		>div:first-child {
			width:30%;
			float: left;
		}

	}
	.tint {
		background-color: rgba(0,0,0, 0.7);
		position: absolute;
		top:0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	.views-field-title {
		display: block;
		padding:0.5em;
		color: $dark-gray;

	}
	.count {
		width: 2em; 
		height: 2em; 
		background: $dark-blue; 
		-moz-border-radius: 1.5em; 
		-webkit-border-radius: 1.5em; 
		border-radius: 1.5em;
		display: block;
		color: $white;
		text-align: center;
		padding-top: 0.5em;
		font-weight: $fw--bold;
		line-height: 1em;
		position: absolute;
		top: 0.75em;
		left: 0.75em;
		z-index: 2;
	}
}

@include susy-breakpoint(450px) {
	.view-immunology-in-60-days {
		a {
			>div:first-child {
				width:100%;
			}

		}
		.views-row {
			img {
				padding-right: 0;
				width:100%;
			}
		}
		.not-active {
			>div:first-child {
				width: 100%;
			}
			.tint {
				width: auto;
			}
		}

		.views-field-title {
			background-color: rgba(0,0,0, 0.5);
			position: absolute;
			bottom: 0;
					width:100%;
		color: $white;
		}
	}
}
@include susy-breakpoint($med-mobile) {
	.view-immunology-in-60-days {
		.views-row {
			@include gallery(10 of 20 after);

		}
	}
}
@include susy-breakpoint($tablet) {
	.view-immunology-in-60-days {
		.views-row {
			@include gallery(6 of 18 after);

		}
	}
}
@include susy-breakpoint($desktop) {
	.view-immunology-in-60-days {
		.views-row {
			@include gallery(4 of 20 after);

		}
	}
}