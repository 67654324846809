
.block--user-page-top-tiles {

  border-top: 0.5em solid black;
  margin-bottom: 3em;
  padding-top: .5em;
  
  h2 {
    margin-bottom: 1em;
    color: #53575d;
    text-transform: uppercase;

  }
  img {
    width:100%;
    float: left;
    display: block;
  }


  &.block-display .block-content {
      float: left;
      padding-bottom:5em;
      position: relative;
      img {
        width:100%;
      }
      p:first-child {
        display: block;
        float:left;
        width:100%;

      }
      // p:nth-of-type(1n + 2) {
      //   width: 80%;
      //   margin-left: 20%;
      // }
      
  }
  div.view-content {
    width: 100%;
    float: left;
    padding-left: 0;
    padding-right: 0;
  }
  &.node-link div.views-row {
    .views-field-view-node {
      position: absolute;
      bottom: 1em;
      right:1em;
      a {
        position: initial;
        display: inline-block;
        left:0;
        font-size: initial;
      }
    }
  }
  .view-header {
    @include clearfix;
    width:100%;
    margin-bottom: 1em;
  }
  .block-content {
    position: relative;
    padding:1em;
    padding-bottom: 5em;
    @include clearfix;
  }
  .field-type-image {
    p {
      margin:0;    
    }
  }
  .view-footer, .views-field-path, &.block-display .block-content {
    a {
      position: absolute;
      bottom: 1em;
      right:1em;
    }   
  }

  .view-content div.views-row {
    border-bottom: none;
    padding-top: 0;
  }
  .views-field-title, .views-row .views-field-title p.title {
    font-weight: bold; 
    a {
      color:$dark-gray;
    }
  }
  .field-name-field-location-summary {
    padding-left: 2.5em;
    position: relative;
    &:before {
      position: absolute;
      float: none;
      left: 0;
    }
  }
  .views-field-created {
    margin-bottom: 0.5em;
  }
  .view-content {
    >div:nth-child(2) {
      .field-name-body {
        display: none;
      }
    }
  }
  .view-upcoming-events {
    position: initial;
    padding-bottom: 0;
  }
}
.block--user-page-top-tiles {
  @each $colour, $hex in $colours { // looping through colours
    &.#{$colour} {
      border-top: 0.5em solid $hex;
      .block-content {
        background-color: lighten($hex, 20%);
      }
      .field-name-field-event-date::before, .field-name-field-location-summary::before {
        color:$hex;
      }
    }
  }
}
@include susy-breakpoint($large-touch) {
  .block--user-page-top-tiles {
    img {
      width:30%;
    }
    &.block-display .block-content {
      p:first-child {
        width:30%;
        img {
          padding-right: 1em;
        }
      }
    }
    .view-header {
      width:30%;
      float: left;
      padding-right: 1em;
      img {
        width:100%;
        display: block;
      }
      p {
        margin:0;
      }
    }
    div.view-content {
      width:70%;
    }
  }
}
@include susy-breakpoint($desktop) {
  .block--user-page-top-tiles {
    @include gallery(6 of 12 after);
    img {
      width:40%;
    }
    &.block-display .block-content {
      p:first-child {
        width:35%;
        padding-right: 1em;
      }

      p:nth-of-type(n+2) {
          width:65%;
          margin-left: 35%;
      }
    }
    .view-header {
      width:35%;
      float: left;
      padding-right: 1em;
      img {
        width:100%;
      }
    }
    div.view-content {
      width:65%;
      left:35%;
    }
    .block-content a, .views-field-view-node, &.block-display .block-content a {
      right:0.75em;
    }
    &.block-display {
      .block-content {
        a {
          position: absolute;
        }
      }
    }
  }

}