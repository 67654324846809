.view-bsi-jobs {

  .block-title {
		font-weight: $fw--bold;
	}

  .view-content > div:last-child {
		border-bottom: none;
	}

	.views-row {
		border-bottom: 1px solid $dark-gray;
		padding-bottom: 1em;
		padding-top: 1em;
	}

	.institution,
  .external-link,
  .deadline {
		font-weight: $fw--semi-bold;

		> div {
			display: inline;
      font-weight: normal;
		}
	}

	.views-field-title {
		font-weight: $fw--bold;
		font-size: 1.2em;
	}
}
