// _susy.scss

/// Global container size
$site-width: 1200px;

/// Susy
/// 12 Columns
/// 1/2 column gutters
/// Fluid
/// Built with floats
/// Classic split gutters
$susy: (
  container: 1180px,
  columns: 12,
  gutters: 1/2,
  math: fluid,
  output: float,
  gutter-position: split,
);
