// _header.scss

header {
  position: relative;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: rgba($white, 0.95);
  z-index: $z--header;
  transition: all $base-duration $base-timing;

  .container {
    position: relative;
  }

  .header--logo img {
    height: auto;
    transition: height $base-duration $base-timing;
  }

  .is-sticky & {
    padding-top: .5em;
    padding-bottom: .5em;

    .header--logo img {
      height: 3em;
    }
  }
}

// HACK
.sticky-wrapper {
  //height: 146px !important;
}