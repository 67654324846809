.page-search404 {

 figure {
  margin: auto;
 }
  hgroup {
    text-align: center;

  }

  article {
    @include span(12 of 12);
    text-align: center;

    ul {
      list-style-type: none;
      padding-left: 0;
    }
  }
}
