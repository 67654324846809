/* stylelint-disable */
@import "bower_components/susy/sass/_susy.scss";
@import "bower_components/bourbon/app/assets/stylesheets/_bourbon.scss";
@import "bower_components/sass-burger/_burger.scss";
/* stylelint-enable */

// Fonts, weights and imports
@import 'vars/fonts';

// Breakpoint vars
@import 'vars/medias';

// Colours and colour maps
@import 'vars/colours';

// Icon maps
@import 'vars/icons';

// Z-Index vars
@import 'vars/z-index';

// Susy Config
@import 'vars/susy';

// Mixins and class'd mixins
@import 'vars/mixins';

// Bitters
// http://bitters.bourbon.io/
@import "base/base";


// Drupal overrides
// - Tabs
@import "vars/drupal-overrides";

// Generic body styles
@import "vars/body";

// Components
// Small building blocks of the site
@import "components/header";
@import "components/footer";
@import "components/node";
@import "components/pager";
@import "components/misc";
// @import "components/";

// Modules
// More: https://smacss.com/book/type-module
@import "modules/navigation";
// @import "modules/";

// Blocks
// Drupal block styles and one-use blocks
@import "blocks/block";
@import "blocks/block--pre-navigation";
@import "blocks/block--post-navigation";
@import "blocks/block--user--logged-out";
@import "blocks/block--user--logged-in";
@import "blocks/block--search-link";
@import "blocks/block--hero-banner";
@import "blocks/block--homepage-intro";
@import "blocks/block--cta-block";
@import "blocks/block--homepage-news";
@import "blocks/block--bsi-member";
@import "blocks/block--footer-menu";
@import "blocks/block--side-block";
@import "blocks/block--bitesized-quick-links";
@import "blocks/block--bitesize-categories";
@import "blocks/block--bitesize-categories-with-info";
@import "blocks/block--bsi-region-map";
@import "blocks/block--latest-news";
@import "blocks/block--page-banner";
@import "blocks/block--page-text";
@import "blocks/block--latest-articles";
@import "blocks/block--immunology-news-landing";
@import "blocks/block--immunology-news-node-page";
@import "blocks/block--user-page-tiles";
@import "blocks/block--user-page-top-tiles";
@import "blocks/block--homepage-news-v2";
@import "blocks/block--footer-listing";
@import "blocks/block--immunology-in-60-days-top-block";
@import "blocks/block--addthis-toolbox";
@import "blocks/block--rss-link";
@import "blocks/block--views-membership-number.scss";
@import "blocks/block--twitter-feed.scss";
// @import "blocks/";

// Regions
// Drupal regions
@import "regions/region--pre-header";
@import "regions/region--post-content-second";
@import "regions/region--post-content-third";
@import "regions/region--post-content-fourth";
@import "regions/region--footer-first";
@import "regions/region--footer-second";
@import "regions/region--breadcrumb";
@import "regions/region--post-content";

// Pages
// Styles for individual pages

// Views
// Drupal views
@import "views/view-upcoming-events";
@import "views/view-bitesized-search";
@import "views/view-immunologist-regional-listing";
@import "views/view-article-listing-page";
@import "views/view-top-20-articles";
@import "views/view-bitesize-category-article-listing";
@import "views/view-latest-reviews";
@import "views/view-search";
@import "views/view-people-gird";
@import "views/view-bsi-jobs";
@import "views/view-immunologist-details";
@import "views/view-bitesize-category-site-map-page";
@import "views/view-display-id-previous_immunology_news";
@import "views/view-immunology-in-60-days";
@import "views/view-our-people";
// @import "views/";

//Drupal paragraphs
// @import "paragraphs/";
@import "paragraphs/image-and-text";
@import "paragraphs/image-and-text-second";
@import "paragraphs/text-and-title";

//Drupal pages
// @import "pages/";
@import "pages/page";
@import "pages/node-bitesize-article";
@import "pages/webform-client-form";
@import "pages/path-bitesize-immunology";
@import "pages/page-user";
@import "pages/page-events";
@import "pages/node-event";
@import "pages/node-staff-profile";
@import "pages/node-type-60-day-tile";
@import "pages/node-immunology-group";
@import "pages/node-bsi-listing-page";
@import "pages/page-membership-form.scss";
@import "pages/node--search404";


