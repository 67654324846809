// _view-bitesized-search.scss

// Exposed filter block
.block--bitesized-search-exposed {
  padding-top: 3em;
  padding-bottom: 3em;
  background-image: url('../images/headers/sections/bitesized-search.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  label {
    margin-bottom: 0;
    color: $white;
  }

  input[type="text"] {
    margin-bottom: 0;
    border: 0;
    background-color: rgba($white, .8);

    @include placeholder {
      color: darken($dark-blue, 10%);
    }
  }

  input[type="submit"] {
    width: 100%;
  }

  .views-exposed-form .views-exposed-widget input.form-submit[type="submit"] { // in yo' face form styles!
    margin-top: 0;
  }

  .block-inner {
    @include container;
  }

  .block-content {
    @include span(12 of 12);
  }

  .views-exposed-form {
    @include clearfix;
    padding: 1.5em;
    background-color: rgba(darken($dark-blue, 5%), .8);
  }

  .search-icon {
    line-height: 2.5em;
    color: $white;
    text-align: center;
  }
}

@include susy-breakpoint($mobile-only) {
  .block--bitesized-search-exposed {

    label {
      text-align: center;
      margin-bottom: 1em;
      font-size: 1.2em;
    }

    input[type="text"] {
      width:100%;

      margin-bottom: 1.5em;
    }
    .views-widget-filter-combine {
      width: 100%;
      padding:0;
    }
    .views-submit-button {
      width:100%;
      padding:0;
    }
    .content {
      @include span(4 of 4 (1/2));
    }

    .search-icon {
      @include span(.5 of 4 after (1/8));
    }
  }
}

@include susy-breakpoint($tablet) {
  .block--bitesized-search-exposed {
    padding-top: 8em;
    padding-bottom: 3em;

    i {
      display: none;
    }

    .content {
      max-width: 60em;
      margin-left: auto;
      margin-right: auto;
    }

    .views-exposed-widget {
      @include span(8 of 12 after);

      label {
        @include span(1.5 of 8 after);
        text-align: right;
        line-height: 2.5em;
      }

      input[type="text"] {
        @include span(6.5 of 8 after last);
      }
    }

    .views-submit-button {
      @include span(4 of 12 after last);
      text-align: center;

      input {
        max-width: 10em;
      }
    }
  }
}
