//_text-and-title.scss
.paragraphs-item-text-and-title {
	clear: both;
  	margin-bottom: $base-spacing;
	width: 100%;
	margin-top: $base-spacing / 2;

	.field-name-field-paragraph-title {
		font-size: modular-scale(5);
		font-weight: $fw--semi-bold;
	}
}
