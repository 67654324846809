.node-type-60-day-tile {

	.field-name-field-image {
		margin-bottom: $base-spacing;
		img {
			width:65%;
			margin: 0 auto;
			display: block;
		}
	}
}

@include susy-breakpoint($tablet) {
	.node-type-60-day-tile {
		.field-name-field-image {
			@include span(3 of 12 after);
			img {
				width:100%;
			}
		}
		.field-name-body {
			@include span(9 of 12 last after);
		}
	}
}

@include susy-breakpoint($desktop) {

}