// _block--bitesized-quick-links.scss

.block--bitesized-quick-links {
  margin-bottom: $base-spacing * 2;
  background-color: $green;

  h2.block-title {
    padding-top: 1em;
    text-align: center;
    font-size: 1em;
    color: $white;
  }

  .menu {
    padding-left: 0;
    display: inline-block;
    width:100%;
    text-align: center;
    list-style: none;
  }

  .block-inner {
    background-image: url('../images/patterns/news-pattern.png');
    background-size: 95%;
  }

  .block-content {
    padding: 1em;
  }

  .menu  {
    @include clean-list;
    @include clearfix;
  }

  .menu li {
    text-align: center;
    a::after {
      content: "\f054";
      font-family: fontAwesome;
      margin-left:10px;
    }
  }



  a {
    display: block;
    padding: .75em 0;
    color: $white;
    background-color: rgba($white, .2);
    transition: background-color $base-timing $base-duration;

    &:hover {
      background-color: rgba($black, .2);
    }
  }
}


@include susy-breakpoint($mobile-only) {
  .block--bitesized-quick-links {
    
    .menu li {
      margin-bottom: 1em;
    }
  }
}


@include susy-breakpoint($tablet) {
  .block--bitesized-quick-links {
    
    .menu li { // .menu needs to be there so the style doesn't leak to the contextual filters
      @include span(1 of 3 (1/8));
      font-size: .8em;
    }
  }
}


@include susy-breakpoint($desktop) {
  .block--bitesized-quick-links {

    .menu li {
      font-size: 1em;
    }
  }
}