// _block--hero-banner.scss

.block--hero-banner {
  @include clearfix;
  position: relative;
  img {
    display: block;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }

  .detail {
    font-size: 1.2em;
    color: $white;
    text-align: center;

    p {
      padding: .75em;
      background-color: $black;
      margin-bottom: 0;
    }
  }

  .flex-direction-nav {
    display: none;
  }
}


@include susy-breakpoint($tablet) {
  .block--hero-banner {
    position: relative;

    .container {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }

    .detail {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      display: table;
      width: 15em;
      height: 100%;

      >p {
        background-color: rgba($black, .8);
      }
    }

    .flex-direction-nav {
      display: block;
      background-color: red;

      li {
        position: absolute;
        z-index: 100;
        width:30px;
        height: 30px;
        background-size: cover;
        top: 50%;
        margin-top: -15px;

        a {
          display: block;
          text-indent: -100px;
          overflow: hidden;
        }
      }

      .flex-nav-next {
        right: 100px;
        background-image: url('../images/icons/banner_controlls/chevron-right.svg');
      }

      .flex-nav-prev {
        left: 100px;
        background-image: url('../images/icons/banner_controlls/chevron-left.svg');
      }
    }

    p {
      display: table-cell;
      vertical-align: middle;
      margin-bottom: 0;
      font-size: 1em;
    }

    strong {
      display: block;
      font-size: 6em;
      font-weight: $fw--normal;
      line-height: 1em;
    }

    span {
      display: block;
    }
  }
}

@include susy-breakpoint($desktop) {
  .block--hero-banner {

    p {
      font-size: 1.3em;
    }
  }
}
